import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { PureComponent } from 'react';
import { Row, Col, Image, Button } from 'react-bootstrap';
import {t} from 'i18next';

import { Dialog } from './Dialog';
import { WalletType } from '../model/utils/connector';
import sessionStore from '../model/Session';
import { Loading } from './Loading';

export const WalletLogo = {
  InfinityWallet: new URL('../image/Infinity.png', import.meta.url) + '',
  StoicWallet: new URL('../image/Stoic.png', import.meta.url) + '',
  PlugWallet: new URL('../image/Plug.png', import.meta.url) + '',
  AstroX: new URL('../image/AstroX.png', import.meta.url) + '',
  ICPBox: new URL('../image/ICPBOX.png', import.meta.url) + ''
};
@observer
export class SessionBox extends PureComponent<{ className?: string }> {
  @observable
  connectorOpen = false;

  @observable
  dialogTitle =  t("sessionBox.Select");

  @observable
  connecting = false;

  componentDidMount() {
    this.resetState();
  }

  listen = (node: HTMLDivElement | null) =>
    node?.addEventListener(
      'click',
      event => {
        if (sessionStore.walletAuth) return;

        event.preventDefault();
        event.stopPropagation();

        this.connectorOpen = true;
      },
      true
    );

  resetState = () => {
    this.connectorOpen = false;
    this.connecting = false;
    this.dialogTitle = t("sessionBox.Select");
  };

  connect = (wallet: WalletType) => async () => {
    this.connecting = true;
    this.dialogTitle = 'Connecting…';
    await sessionStore.connectWallet(wallet);

    this.connectorOpen = false;
  };

  tryAgain = () => {
    this.connecting = false;
    this.dialogTitle = t("sessionBox.Select");
  };

  renderDialog() {
    return (
      <Dialog
        title={this.dialogTitle}
        show={this.connectorOpen}
        onHide={this.resetState}
      >
        {this.connecting ? (
          <div>
            <div className="py-4 text-center">
              <Loading width={110} height={110} />
            </div>
            <div className="w-100 text-end">
              <Button
                className="border-0 bg-transparent text-primary"
                onClick={this.tryAgain}
              >
                {t('sessionBox.TryAgain')}
              </Button>
            </div>
          </div>
        ) : (
          <>
            <p className="text-muted">
              {t('sessionBox.title')}
            </p>
            <Row>
              <Col xs={12} sm={6} className="my-3">
                <Button
                  className="border-0 bg-white text-dark shadow rounded-pill w-100 py-2"
                  size="sm"
                  variant="outline-dark"
                  onClick={this.connect(WalletType.PlugWallet)}
                >
                  <Image
                    style={{ width: '1.5rem', marginRight: '10px' }}
                    src={WalletLogo['PlugWallet']}
                  />
                  Plug
                </Button>
              </Col>
              <Col xs={12} sm={6} className="my-3">
                <Button
                  className="border-0 bg-white text-dark shadow rounded-pill w-100 py-2"
                  size="sm"
                  variant="outline-dark"
                  onClick={this.connect(WalletType.StoicWallet)}
                >
                  <Image
                    style={{ width: '1.5rem', marginRight: '10px' }}
                    src={WalletLogo['StoicWallet']}
                  />
                  Stoic
                </Button>
              </Col>
              <Col xs={12} sm={6} className="my-3">
                <Button
                  className="border-0 bg-white text-dark shadow rounded-pill w-100 py-2"
                  size="sm"
                  variant="outline-dark"
                  onClick={this.connect(WalletType.AstroX)}
                >
                  <Image
                    style={{ width: '1.5rem', marginRight: '10px' }}
                    src={WalletLogo['AstroX']}
                  />
                  AstroX
                </Button>
              </Col>
              <Col xs={12} sm={6} className="my-3">
                <Button
                  className="border-0 bg-white text-dark shadow rounded-pill w-100 py-2"
                  size="sm"
                  variant="outline-dark"
                  onClick={this.connect(WalletType.InfinityWallet)}
                >
                  <Image
                    style={{ width: '1.5rem', marginRight: '10px' }}
                    src={WalletLogo['InfinityWallet']}
                  />
                  Infinity
                </Button>
              </Col>
              <Col xs={12} sm={6} className="my-3">
                <Button
                  className="border-0 bg-transparent w-100"
                  variant="link"
                  href="#/migration"
                >
                  {t("sessionBox.usedIIBefore")}
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Dialog>
    );
  }

  render() {
    const { className = 'd-inline-block', children } = this.props;

    return (
      <>
        <div className={className} ref={this.listen}>
          {children}
        </div>
        {this.renderDialog()}
      </>
    );
  }
}
