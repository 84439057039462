import { FC } from 'react';
import { Image } from 'react-bootstrap';

import { QuotaIcons } from '../AssetBadge';
import { Asset } from '../../model/Asset';

export type OrderCardProps = Pick<Asset, 'name' | 'quota' | 'availableAmount'>;

export const OrderCard: FC<OrderCardProps> = ({
  name,
  quota,
  availableAmount,
  children
}) => {
  const [host, domain] = name?.split('.') || [];

  return (
    <div className="round-card">
      <div className="rounded-4 bg-light w-100 ratio ratio-1x1">
        <div className="position-absolute top-0 start-0 w-100 h-100 p-2 d-flex justify-content-center align-items-center">
          {!name && (
            <header className="position-absolute top-0 start-0 end-0 p-2 d-flex justify-content-between align-items-center">
              <Image src={QuotaIcons[quota - 1]} style={{width:'2.5rem'}} />
              <b className="text-primary fw-500">{availableAmount}</b>
            </header>
          )}
          <h1 className="h3 text-center text-break">
            {name ? host : `${quota}-chars`}
            <div className="small text-muted">
              {name ? `.${domain}` : 'Quota'}
            </div>
          </h1>
        </div>
      </div>

      {children}
    </div>
  );
};
