{
    "appName":"icNaming",
    "base":{
        "names":"những cái tên",
        "favorites":"yêu thích",
        "assets":"tài sản",
        "asset":"tài sản",
        "Logout":"Đăng xuất",
        "Name":"Tên,",
        "registrant":"người đăng ký",
        "controller":"người điều khiển",
        "Register":"Đăng ký",
        "Details":"Thông tin chi tiết",
        "Expiration":"Ngày hết hạn",
        "Resolver":"Người phân giải",
        "Quota":"Hạn ngạch",
        "Buy":"Mua",
        "Sell":"Bán",
        "Price":"Giá bán",
        "Amount":"Số lượng",
        "Item":"Mục",
        "Cost":"Phí tổn",
        "balance":"THĂNG BẰNG",
        "wrap":"bọc ICP",
        "unwrap":"mở ra DICP",
        "Cancel":"Hủy bỏ",
        "Confirm":"Xác nhận",
        "Submit":"Nộp",
        "connect": "Kết nối Ví",
        "BuyName":"Tên mua",
        "BuyQuota":"Mua hạn ngạch",
        "SellName":"Tên bán",
        "SellQuota":"Bán hạn ngạch",
        "Setting":"Cài đặt",
        "Set":"Bộ",
        "transfer":"chuyển khoản",
        "chars":"ký tự",
        "type":"Loại hình",
        "Fixed":"đã sửa",
        "Renew":"Thay mới",
        "EnterAmount":"Nhập số tiền",
        "EnterPrice":"Nhập giá",
        "Save":"Tiết kiệm",
        "Delete":"Xóa bỏ",
        "NotSet":"Không được thiết lập",
        "fee":"Phí",
        "Tips":"Lời khuyên",
        "Address":"Địa chỉ nhà",
        "to":"đến",
        "from":"từ"
    },
    "header": {
        "docs":"Docs",
        "blindBox":"Hộp mù",
        "market":"Thị trường"
    },
    "sessionBox":{
        "Select":"Chọn ví",
        "title":"Vui lòng chọn một ví để kết nối với dapp này:",
        "TryAgain":"Thử lại",
        "usedIIBefore":"Tôi đã sử dụng II trước đây?"
    },
    "home": {
        "slogan" : "Nhận dạng kỹ thuật số phi tập trung"
    },
    "domainSearch":{
        "search":"Tìm kiếm",
        "placeholder":"Tìm kiếm tên hoặc địa chỉ"
    },
    "domain" : {
        "card" : {
            "Buy":"Mua",
            "View":"Lượt xem",
            "Sell":"Bán",
            "Claim":"Yêu cầu",
            "HowToClaim":"Làm thế nào để yêu cầu",
            "registered":"Tên này đã được đăng ký."
        },
        "CustomBadge":{
            "Available": "Có sẵn",
            "Unavailable": "Không có sẵn",
            "Reserved": "Kín đáo",
            "OnSale": "Bán bởi chủ sở hữu",
            "ForSaleByOwner":"Bán bởi chủ sở hữu"
        },
        "sell":{
            "Confirmation":"Xác nhận",
            "ThinkAgain":"Nghĩ lại",
            "ConfirmCancel":"Xác nhận hủy bỏ",
            "AreYouSure":"Bạn có chắc chắn hủy bán mặt hàng sau không?"
        }
    },
    "registerForm":{
        "RegistrationPeriod":"Thời gian đăng ký",
        "years":"năm",
        "year":"Năm",
        "RegistrationPrice":"Giá đăng ký",
        "Best":"Tốt nhất",
        "TotalPrice":"Tổng giá",
        "What":"Cái gì",
        "question":"3-5 ký tự tên chỉ có thể đăng ký thông qua hạn ngạch mua hàng",
        "getQuota":"Nhận hạn ngạch",
        "RenewPeriod":"Gia hạn thời gian",
        "questionTitle1":"Hạn ngạch là gì?",
        "questionTitle2":"Làm thế nào để nhận được hạn ngạch?",
        "questionTitle3":"Hộp mù",
        "questionDesc1":"Bạn cần sử dụng hạn ngạch khi đăng ký tên 3-5 ký tự. Tên có các ký tự khác nhau có thể được đăng ký bằng hạn ngạch và có các loại hạn ngạch khác nhau. Ví dụ: hạn ngạch 4 ký tự không thể đăng ký tên 3 ký tự.",
        "questionDesc2":"Mua một hộp mù sẽ chứa một hạn ngạch và một số mã thông báo DNS, và xác suất nhận được các hạn ngạch ký tự khác nhau sẽ khác nhau. Biểu đồ xác suất cụ thể được hiển thị trên giao diện mua hộp mù. Bạn có thể nhấp vào liên kết sau để mua <0>Hộp mù</0>. Hạn ngạch cũng có thể được mua từ <1>Thị trường</1>. Hạn ngạch trên thị trường được bán bởi những người dùng khác.",
        "questionDesc3":"Hộp mù là một cách tốt hơn để phân phối tên. Liên kết sau là trang mua hàng và giới thiệu chi tiết về hộp mù:",
        "whatBlindBox":"hộp mù là gì?",
        "whyBlindBox":"tại sao hộp mù?"
    },
    "market":{
        "Filter":"Lọc",
        "Items":"vật phẩm",
        "All":"Tất cả các",
        "Length":"Chiều dài",
        "SeeMySale":"Xem giảm giá của tôi",
        "Down":"Khép kín",
        "placeholder":"Tìm kiếm tên",
        "OwnedBy":"Được sở hữu bởi",
        "PurchaseConfirmation":"Xác nhận mua hàng",
        "PurchaseTips":"Bạn có chắc chắn mua mặt hàng sau đây không?"
    },
    "account":{
        "Receive":"Nhận",
        "nnsTips":"Nếu bạn đã mua tên bằng cách liên kết ví II (NNS) trước đó,<0>nhấp vào tôi để hoàn tất quá trình di chuyển.</0>",
        "Expires":"Hết hạn",
        "ViewSelling":"Xem bán hàng",
        "MySale" : "Bán hàng của tôi",
        "placeholder":"Tìm kiếm tên",
        "names":"Tên",
        "Frozen":"Đông cứng",
        "Available":"Có sẵn",
        "Action":"Hoạt động",
        "feeTips":"Phí gói DICP (0,0001 ICP) được tính theo sổ cái khi người dùng chuyển ICP. IC Naming không tính bất kỳ khoản phí nào khi quấn / mở DICP.",
        "SelectAssets":"Chọn nội dung",
        "DestinationAddress":"Địa chỉ đích",
        "InsufficientBalance":"Thiếu cân bằng",
        "EnterAddressOrName":"Nhập địa chỉ / tên",
        "EnterAmount":"Nhập số tiền",
        "quotaDialogTitle":"Hạn ngạch là gì?",
        "quotaDialogDesc":"Bạn cần sử dụng hạn ngạch khi đăng ký tên 3-5 ký tự. Tên có các ký tự khác nhau có thể được đăng ký bằng hạn ngạch và có các loại hạn ngạch khác nhau. Ví dụ: Hạn ngạch 3 ký tự có thể được sử dụng để đăng ký tên lớn hơn hoặc bằng 3 chữ số, nhưng hạn ngạch 4 ký tự không thể đăng ký tên 3 ký tự."
    },
    "nameSet":{
        "menu":{
            "Profile":"Hồ sơ",
            "SocialMedia":"Truyền thông xã hội",
            "Crypto":"Tiền điện tử",
            "Canister":"Hộp đựng",
            "Link":"Liên kết",
            "TextRecord":"Bản ghi văn bản",
            "Transfer":"Chuyển khoản",
            "Renew":"Thay mới",
            "Resolver":"Người phân giải",
            "ReverseRecord":"Đảo ngược bản ghi"
        },
        "tryItNow":"thử ngay bây giờ",
        "EditProfile":"Chỉnh sửa hồ sơ",
        "SetAvatar":"Đặt NFT / Gravatar làm hình đại diện của tôi",
        "DisplayName":"Tên hiển thị",
        "Email":"E-mail",
        "Location":"Địa điểm",
        "Description":"Sự mô tả",
        "reclaim":"Tái chế",
        "noOwnController":"Người kiểm soát tên đó đã được chuyển đi. Để thiết lập, xin vui lòng rút lại",
        "SetYourReverseRecord":"Đặt bản ghi Đảo ngược của bạn",
        "reverseRecordTips":"Bạn có muốn người khác nhận được tên của bạn thông qua ID chủ của bạn? Sau khi đặt tên chính, những người khác có thể kiểm tra tên của bạn với ID chủ.",
        "yourDecentralized":"Truy cập vào trang web của bạn",
        "canisterTips1":"Bạn có một trang web phân tán được triển khai trên IC? Nếu có, hãy đặt id container của trang web ở đây để truy cập qua địa chỉ url",
        "canisterTips2":"Là một ví dụ về một trang web bị phân tán được triển khai trên IC",
        "hasUrlNotice1Title":"Xác nhận cài đặt",
        "hasUrlNotice":"Bạn đã thiết lập một URL, nếu bạn thiết lập được bình đựng vậy nên, vậy thì truy cập <bikeUrl>{{bikeUrl}}</bikeUrl> sẽ truy cập ở trên con chíp bạn triển khai phân tán của trạm, chứ không phải là <url>{{url}}</url> của ngài thiết lập.",
        "yourWebsite":"Liên kết đến trang web2/ 3 của bạn qua một trang web:",
        "urlTips1":"Giờ anh có trang web chưa? Nếu có, hãy đặt địa chỉ của trang web ở đây, và bạn có thể truy cập nó qua địa chỉ đó.",
        "urlNotice":"(Lưu ý: bạn đã đặt ID của container và nó sẽ truy cập trang web của container thay vì trang web của bạn.)",
        "hasBeenTransferred":"Tên đăng ký đã được chuyển đi." 
    },
    "dangerConfirm":{
        "tips":"Anh có chắc là muốn chuyển tới địa chỉ sau đây không?",
        "transferRegistrant":{
            "title":"Chuyển người đăng ký",
            "note":"Lưu ý: chuyển người đăng ký có nghĩa là bạn không còn là chủ sở hữu của cái tên đó nữa và không thể hủy bỏ một khi đã hoàn thành việc chuyển nhượng."
        },
        "transferController":{
            "title":"Chuyển người điều khiển",
            "note":"Lưu ý: chuyển người kiểm soát chỉ có nghĩa là chuyển quyền kiểm soát, bạn vẫn là chủ nhân của tên, có thể lấy lại quyền kiểm soát bất cứ lúc nào qua [tái chế]"
        },
        "reclaim":{
            "title":"Tái chế quyền kiểm soát"
        },
        "transferToken":{
            "title":"Chuyển tiền",
            "note":"Lưu ý: một khi việc chuyển nhượng đã hoàn thành, không thể bị hủy bỏ."
        }
    },
    "migration":{
        "desc":"Chương trình đặc biệt đã hủy bỏ sự ủng hộ cho chương II. Nếu bạn đã đăng nhập với II và mua tên trước đó, bạn có thể hoàn thành việc di cư ở trang này.",
        "MigrationSteps":"Bước di cư",
        "step1":"Kết nối với II và ví đích mà bạn muốn di cư đến",
        "step2":"Chọn dự án cần di cư",
        "step3":"Hoàn thành bước di cư",
        "Connect":"Kết nối ví",
        "ConnectII":"Kết nối ví II",
        "ConnectTargetWallet":"Kết nối ví mục tiêu",
        "connectTips":"Hãy kết nối ví II với ví mục tiêu trước",
        "SelectItems":"Chọn dự án cần di cư",
        "Migrate":"Di cư"
    },
    "blindBox":{
        "ConnectWallet":"Kết nối ví",
        "YourBalance":"Số dư của bạn",
        "RewardTips":"Mua một hộp mù sẽ có cơ hội tiếp cận những thứ sau đây",
        "Reward":"Phần thưởng",
        "Chance":"Xác suất",
        "Remark":"Từng dùng",
        "Reasons":"Lý do",
        "ReasonsTips":"Để giới thiệu một cách mới để khám phá khả năng của dịch vụ tên tuổi, và điều này phù hợp với niềm tin cốt lõi của chúng tôi: sự công bằng. Không chỉ vậy, cơ chế hoạt động của chiếc hộp mù là không chắc chắn, và khi người dùng tham gia, nó tạo ra rất nhiều niềm vui. Hơn nữa, thiết kế xác suất ban đầu của chúng tôi tạo ra nhiều cơ hội hơn cho người dùng, cho phép họ có nhiều hạn ngạch hơn và hạn ngạch hiếm hơn với mức tiêu thụ thấp nhất, và giá cả hợp lý trên thị trường."
    },
    "errorInfo":{
        "inputError":"Đầu vào của bạn là sai",
        "notFoundAddress":"Không tìm thấy địa chỉ mật mã này"
    },
    "toast":{
        "SaleSuccessfully":"Bán thành công",
        "CancelledSuccessfully":"Hủy bỏ thành công",
        "exceedPendingOrders":"Không thể vượt quá số mệnh lệnh cần xử lý",
        "WrapSuccessful":"Trao đổi thành công",
        "UnWrapSuccessful":"Trao đổi thành công",
        "SaveSuccessful":"Bảo quản thành công!",
        "SetSuccessfully":"Thiết lập thành công",
        "MigrateSuccess":"Di cư thành công"
    },
    "cheap":{
        "FindCheap":"Tìm một hạn ngạch giá rẻ trên thị trường <0>tiết kiệm {{cheapPrice}} DICP để đăng ký</0>",
        "FindOne":"Đã tìm thấy số lượng {{quota}}-chars trên thị trường <0>{{rẻPrice}} mua và đăng ký DICP</0>",
        "BuyQuotaToRegTitle":"Mua hạn ngạch & đăng ký",
        "RegTitle":"Đăng ký",
        "you":"chính bạn",
        "savings":"Tiết kiệm {{cheap}} DICP"
    }
}