import { Day, Second } from 'web-utility';
import { Principal } from '@dfinity/principal';
import { deleteCache } from '@deland-labs/local-cache';
import {
  idlFactory as registryIDL,
  _SERVICE as RegistryActor
} from '@icnaming/registry_client';

import { withCache, withLogging } from '../utils/errorLogger';
import { CanisterError } from '../utils/exception';
import { AuthBase } from '../AuthBase';
import {
  REGISTRY_DETAIL_CACHE_KEY,
  REGISTRY_RESOLVER_CACHE_KEY
} from '../utils/config';
export class Registry extends AuthBase {
  constructor(registryCanisterId: string) {
    super();
    this.registryCanisterId = registryCanisterId;
  }
  private registryCanisterId: string;

  /**
   * get controlled names of address
   *
   * @param address address of the user wallet
   * @param offset offset
   * @param limit limit
   */
  @withLogging
  public async getControlledNamesByPager(
    address: string,
    offset: number,
    limit: number
  ) {
    const registry = await this.createRegistryActor(this.registryCanisterId);
    const pid = Principal.fromText(address);
    const res = await registry.get_controlled_names(pid, {
      offset: BigInt(offset),
      limit: BigInt(limit)
    });
    if ('Ok' in res) return res.Ok.items;

    throw new CanisterError(res.Err);
  }

  /**
   * get name's details
   *
   * @param name name
   * @returns name's details
   */
  @withLogging
  @withCache(
    (_, name: string) => `${REGISTRY_DETAIL_CACHE_KEY}_${name}`,
    Day / Second
  )
  public async getDetails(name: string) {
    const registry = await this.createRegistryActor(this.registryCanisterId);

    const res = await registry.get_details(name);

    if ('Ok' in res) return res.Ok;

    throw new CanisterError(res.Err);
  }

  /**
   * get name's resolver
   */
  @withLogging
  @withCache(
    (_, name: string) => `${REGISTRY_RESOLVER_CACHE_KEY}_${name}`,
    Day / Second
  )
  public async getResolver(name: string) {
    const registry = await this.createRegistryActor(this.registryCanisterId);

    const res = await registry.get_resolver(name);

    if ('Ok' in res) return res.Ok;

    throw new CanisterError(res.Err);
  }

  /**
   * get name's ttl
   */
  @withLogging
  public async getTTL(name: string) {
    const registry = await this.createRegistryActor(this.registryCanisterId);
    const res = await registry.get_ttl(name);

    if ('Ok' in res) return res.Ok;

    throw new CanisterError(res.Err);
  }

  /**
   * update name's ttl and resolver
   */
  @withLogging
  public async setRecord(name: string, ttl: number, resolver: string) {
    const registry = await this.createRegistryActor(
      this.registryCanisterId,
      false
    );
    const pid = Principal.fromText(resolver);
    const res = await registry.set_record(name, BigInt(ttl), pid);

    if ('Ok' in res) {
      deleteCache(`${REGISTRY_RESOLVER_CACHE_KEY}_${name}`);
      return res.Ok;
    }
    throw new CanisterError(res.Err);
  }

  /**
   * transfer control of name to another address
   */
  @withLogging
  public async transferController(
    name: string,
    newController: string,
    newResolver: string
  ) {
    const registry = await this.createRegistryActor(
      this.registryCanisterId,
      false
    );
    const newControllerPid = Principal.fromText(newController);
    const newResolverPid = Principal.fromText(newResolver);
    const res = await registry.set_owner(name, newControllerPid);

    if ('Ok' in res) {
      deleteCache(`${REGISTRY_DETAIL_CACHE_KEY}_${name}`);
      return res.Ok;
    }

    throw new CanisterError(res.Err);
  }

  /**
   * create registrar actor
   * @param registryCanisterId registrar canister id
   * @param anonymous is anonymous access
   * @returns registrar actor
   */
  private createRegistryActor(registryCanisterId: string, anonymous = true) {
    return this.createActor<RegistryActor>(
      registryCanisterId,
      registryIDL,
      anonymous
    );
  }
}
