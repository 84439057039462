import { observer } from 'mobx-react';
import { PureComponent } from 'react';
import { computed, observable } from 'mobx';
import { Button, Col, Container, Modal, Row, Image } from 'react-bootstrap';
import { Encoder, ErrorCorrectionLevel } from '@nuintun/qrcode';
import classNames from 'classnames';
import { t } from "i18next";

import { EmptyCard } from '../../../component/EmptyCard';
import { AssetBadge } from '../../../component/AssetBadge';
import sessionStore from '../../../model/Session';
import assetStore, { Asset } from '../../../model/Asset';

import { IconButton } from '../../../component/IconButton';
import { AddressBox } from '../../../component/AddressBox';
import { DialogSell } from '../../../component/DialogSell';
import { LoadingFullCon } from '../../../component/LoadingFullPage';
import { Icon } from 'idea-react';
import { Dialog } from '../../../component/Dialog';

type CurrentAsset = Pick<Asset, 'name' | 'quota' | 'type'>;

interface AssetListProps {
  loading: boolean;
}
@observer
export class AssetList extends PureComponent<AssetListProps> {
  @observable
  currentAsset?: CurrentAsset;
  @observable
  qrcode = new Encoder();
  @observable
  showDialogSell = false;
  @observable
  saleQuota: number;

  @observable
  accountList: Asset[] = [];

  @observable
  quotaInstructions = false;

  async componentDidMount() {
    await assetStore.getList(sessionStore.walletAuth.principal);
    this.accountList = assetStore.list;
  }

  componentDidUpdate() {
    if (!!this.currentAsset) return;
    this.qrcode.clear();
  }
  componentWillUnmount() {
    assetStore.clear();
    this.qrcode.clear();
    this.saleQuota = 0;
  }

  @computed get list() {
    return this.accountList
  }

  reLoadQuotas(sealQuota: number, quotaAmount: number) {
    this.accountList.forEach((item) => {
      if (item.quota === sealQuota) {
        item.availableAmount = Number(item.availableAmount) - quotaAmount;
      }
    });
    this.showDialogSell = false;
  }

  onSell = (quota: number) => {
    this.saleQuota = quota;
    this.showDialogSell = true;
    assetStore.getOneQuota(sessionStore.walletAuth.principal, +quota);
  }

  renderReceiveDialog({ type, name, quota }: CurrentAsset) {
    const { principal, accountId } = sessionStore.walletAuth || {},
      { isDesktop } = sessionStore,
      { qrcode } = this;

    qrcode.setEncodingHint(true);
    qrcode.setErrorCorrectionLevel(ErrorCorrectionLevel.H);
    qrcode.write(name === 'ICP' ? accountId : principal);
    qrcode.make();
    const qrcodeImg = qrcode.toDataURL();
    return (
      <Modal show={!!this.currentAsset} centered>
        <Modal.Header className={!isDesktop && 'p-0 my-3'}>
          <Modal.Title>{t("account.Receive")}</Modal.Title>
          <IconButton
            name="x-lg"
            onClick={() => (this.currentAsset = undefined)}
          />
        </Modal.Header>
        <Modal.Body className={!isDesktop && 'p-0'}>
          <div className="d-flex justify-content-between align-items-center my-3">
            <span className="text-info">{t('base.asset')}</span>
            <span className="d-flex align-items-center">
              <AssetBadge {...{ type, name, quota }} />
            </span>
          </div>
          <div className="d-flex justify-content-between my-3">
            <span className="text-info">{t("base.Address")}</span>
            <span className="d-flex align-items-center align-items-center">
              <AddressBox>{name === 'ICP' ? accountId : principal}</AddressBox>
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer className="text-center border-0">
          <Image
            src={qrcodeImg}
            className="mx-auto outer-shadow p-2 bg-white rounded-4"
            height={isDesktop ? '200px' : '160px'}
          />
        </Modal.Footer>
      </Modal>
    );
  }

  renderRow = ({ id, type, name, quota, availableAmount, frozenAmount }) => {
    const { isDesktop } = sessionStore;
    return (
      <Row key={id} className="mb-3">
        <Col xs={3} sm={5}>
          <Row className={isDesktop && 'h-100'}>
            <Col xs={12} sm={6} className="d-flex align-items-center mb-2">
              <AssetBadge {...{ type, name, quota }} />
              {
                type !== 'coin' && (
                  <Button
                    className={'border-0 bg-transparent p-0 shadow-none'}
                    onClick={() => (this.quotaInstructions = true)}
                  >
                    <Icon
                      name="question-circle"
                      className="ms-1 fw-500 text-muted"
                    />
                  </Button>
                )
              }
            </Col>
            <Col
              xs={12}
              sm={6}
              className={classNames(
                'fw-400 py-1',
                isDesktop
                  ? 'd-flex justify-content-center align-items-center'
                  : 'd-flex align-items-center'
              )}
            >
              {frozenAmount + ''}
            </Col>
          </Row>
        </Col>
        <Col xs={9} sm={7}>
          <Row>
            <Col
              xs={12}
              sm={3}
              md={4}
              className={classNames(
                'fw-400',
                isDesktop
                  ? 'd-flex justify-content-center align-items-center'
                  : 'text-end mb-2 pe-4'
              )}
            >
              {availableAmount + ''}
            </Col>
            <Col
              xs={12}
              sm={9}
              md={8}
              className={classNames(
                'text-nowrap text-end',
                isDesktop ? ' fs-14' : 'fs-12'
              )}
            >
              {name === 'ICP' && (
                <Button
                  className={classNames(
                    isDesktop ? 'fs-14 me-4' : 'fs-12 me-3',
                    'btn-orange'
                  )}
                  variant="outline-warning"
                  size={isDesktop ? undefined : 'sm'}
                  href={`#/account/assets/wrap/ICP`}
                >
                  {t("base.wrap")}
                </Button>
              )}
              {name === 'DICP' && (
                <Button
                  className={classNames(
                    isDesktop ? 'fs-14 me-4' : 'fs-12 me-3',
                    'btn-orange'
                  )}
                  variant="outline-warning"
                  size={isDesktop ? undefined : 'sm'}
                  href={`#/account/assets/wrap/DICP`}
                >
                  {t("base.unwrap")}
                </Button>
              )}
              {type !== 'coin' && !!availableAmount && (
                <Button
                  className={classNames(
                    isDesktop ? 'fs-14 me-4' : 'fs-12 me-3',
                    'btn-orange shadow-none'
                  )}
                  variant="outline-warning"
                  size={isDesktop ? undefined : 'sm'}
                  onClick={() => this.onSell(quota)}
                >
                  {t("base.Sell")}
                </Button>
              )}
              <Button
                className={classNames(
                  isDesktop ? 'fs-14 me-4' : 'fs-12 me-3',
                  'btn-transfer'
                )}
                variant="outline-secondary"
                size={isDesktop ? undefined : 'sm'}
                href={`#/account/assets/${id}/transfer`}
                disabled={!availableAmount}
              >
                {t("base.transfer")}
              </Button>
              <Button
                className={classNames(
                  isDesktop ? 'fs-14 me-4' : 'fs-12',
                  'btn-custom-blue'
                )}
                variant="outline-primary"
                size={isDesktop ? undefined : 'sm'}
                onClick={() => (this.currentAsset = { type, name, quota })}
              >
                {t("account.Receive")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  renderTitle = () => (
    <Row className="text-secondary text-center my-3 px-4">
      <Col sm={5}>
        <Row>
          <Col sm={6}>{t("account.names")}</Col>
          <Col sm={6}>{t("account.Frozen")}</Col>
        </Row>
      </Col>
      <Col sm={7}>
        <Row>
          <Col sm={3} md={5}>
          {t("account.Available")}
          </Col>
          <Col sm={9} md={7}>
          {t("account.Action")}
          </Col>
        </Row>
      </Col>
    </Row>
  );

  render() {
    const { isDesktop } = sessionStore,
      { currentAsset, showDialogSell, saleQuota, list, quotaInstructions } = this;
    const { loading } = this.props;
    return !list[0] ? (
      <div className="position-relative">
        {loading && <div className="h-100 w-100 position-absolute" style={{ background: "rgba(255,255,255,0.5)" }}><LoadingFullCon /></div>}
        <EmptyCard variant="box" />
      </div>
    ) : (
      <Container className="p-0">
        {isDesktop && this.renderTitle()}
        <div className="round-card">{list.map(this.renderRow)}</div>
        
        {currentAsset && this.renderReceiveDialog(currentAsset)}
        {saleQuota &&
          <DialogSell
            show={showDialogSell}
            title={t("base.SellQuota")}
            quota={saleQuota}
            reLoadQuotas={(quotaAmount) => this.reLoadQuotas(saleQuota, quotaAmount)}
            onHide={() => (this.showDialogSell = false)}
          />
        }
        <Dialog
          show={quotaInstructions}
          backdrop={true}
          closeButton={true}
          centered={true}
          title="Tips"
          onHide={() => (this.quotaInstructions = false)}
        >
          <strong>{t("account.quotaDialogTitle")}</strong>
          <p className="fs-14 pt-2" style={{ wordBreak: 'break-word' }}>
            {t("account.quotaDialogDesc")}
          </p>
        </Dialog>
      </Container>
    );
  }
}
