import classNames from 'classnames';
import { Nav, NavProps } from 'react-bootstrap';
import { t } from 'i18next';

export interface PillNavProps<T extends string = string> extends NavProps {
  list: T[];
  value?: T;
}

export const PillNav = <T extends string>({
  className,
  list,
  value = list[0],
  ...props
}: PillNavProps<T>) => (
  <Nav
    {...props}
    variant="pills"
    className={classNames(
      'd-inline-flex',
      'my-4',
      'rounded-pill',
      'bg-white',
      className
    )}
    activeKey={value}
  >
    {list.map(title => (
      <Nav.Item key={title}>
        <Nav.Link eventKey={title} className="rounded-pill px-4">
          {title.indexOf(".") === -1 ? t(`base.${title}`) : title}
        </Nav.Link>
      </Nav.Item>
    ))}
  </Nav>
);
