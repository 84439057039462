import { FC } from 'react';

import * as styles from './index.module.less';

export const LoadMore: FC<{ noMore?: boolean }> = ({ noMore }) => (
  <div className="clearfix text-center">
    {noMore ? (
      <span className={styles['no-more']}>No more</span>
    ) : (
      <span className={`${styles['load-more']} bg-white"`}>Load more</span>
    )}
  </div>
);
