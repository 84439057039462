import classNames from 'classnames';
import { FC, HTMLAttributes, InputHTMLAttributes } from 'react';
import { Form, FormControlProps, InputGroup } from 'react-bootstrap';
import { Icon } from 'idea-react';

import * as styles from './index.module.less';

export interface IconInputProps
  extends Omit<
      InputHTMLAttributes<HTMLInputElement>,
      keyof HTMLAttributes<HTMLInputElement> | 'type' | 'value' | 'size'
    >,
    FormControlProps {
  icon: string;
}

export const IconInput: FC<IconInputProps> = ({
  className,
  icon,
  children,
  ...props
}) => {
  const small = props.size === 'sm';

  return (
    <InputGroup
      className={classNames(
        'rounded-pill',
        'shadow',
        styles.wrapper,
        className
      )}
      tabIndex={-1}
    >
      <InputGroup.Text
        className={classNames(
          'bg-white',
          'border-0',
          !small && 'ps-4',
          !small && 'py-3',
          styles.icon,
          styles[`custom-${icon}-icon`]
        )}
      >
        <Icon name={icon} />
      </InputGroup.Text>

      <Form.Control
        className={classNames(
          'border-0 bg-white',
          !small && 'pe-4',
          !small && 'py-3',
          styles.input,
          styles[`custom-${icon}-control`]
        )}
        {...props}
      />
      {children && (
        <InputGroup.Text
          className={classNames(
            'bg-white',
            'border-0',
            !small && 'pe-4',
            !small && 'py-3',
            styles.children
          )}
        >
          {children}
        </InputGroup.Text>
      )}
    </InputGroup>
  );
};
