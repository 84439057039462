{
    "appName":"icNaming",
    "base":{
        "names":"Nama",
        "favorites":"Kegemaran",
        "assets":"Aset",
        "asset":"Aset",
        "Logout":"Log keluar",
        "Name":"Nama",
        "registrant":"pendaftar",
        "controller":"pengawal",
        "Register":"Daftar",
        "Details":"Butiran",
        "Expiration":"Tarikh tamat tempoh",
        "Resolver":"Selesaikan",
        "Quota":"Kuota",
        "Buy":"Beli",
        "Sell":"Jual",
        "Price":"harga",
        "Amount":"Jumlah",
        "Item":"item",
        "Cost":"kos",
        "balance":"seimbang",
        "wrap":"Balut ICP",
        "unwrap":"Buka bungkus DICP",
        "Cancel":"Batal",
        "Confirm":"Sahkan",
        "Submit":"Hantar",
        "connect": "sambung",
        "BuyName":"Beli Nama",
        "BuyQuota":"Beli Kuota",
        "SellName":"Jual Nama",
        "SellQuota":"Jual Kuota",
        "Setting":"Tetapan",
        "Set":"Tetapkan",
        "transfer":"pemindahan",
        "chars":"-aksara",
        "type":"Taip",
        "Fixed":"Tetap",
        "Renew":"memperbaharui",
        "EnterAmount":"Masukkan jumlah",
        "EnterPrice":"Masukkan harga",
        "Save":"Jimat",
        "Delete":"Padam",
        "NotSet":"Tidak Ditetapkan",
        "fee":"Yuran",
        "Tips":"Petua",
        "Address":"Alamat",
        "to":"kepada",
        "from":"daripada"
    },
    "header": {
        "docs":"Dokumen",
        "blindBox":"Kotak buta",
        "market":"Pasaran"
    },
    "sessionBox":{
        "Select":"Pilih dompet",
        "title":"Sila pilih dompet untuk disambungkan ke dapp ini:",
        "TryAgain":"Cuba lagi",
        "usedIIBefore":"Saya menggunakan II sebelum ini?"
    },
    "home": {
        "slogan" : "Identiti Digital Terdesentralisasi"
    },
    "domainSearch":{
        "search":"Cari",
        "placeholder":"Cari nama atau alamat"
    },
    "domain" : {
        "card" : {
            "Buy":"Beli",
            "View":"Lihat",
            "Sell":"Jual",
            "Claim":"Tuntutan",
            "HowToClaim":"Bagaimana untuk menuntut",
            "registered":"Nama ini telah pun didaftarkan."
        },
        "CustomBadge":{
            "Available": "Tersedia",
            "Unavailable": "Tidak tersedia",
            "Reserved": "Terpelihara",
            "OnSale": "Untuk dijual oleh pemilik",
            "ForSaleByOwner":"Untuk dijual oleh pemilik"
        },
        "sell":{
            "Confirmation":"Pengesahan",
            "ThinkAgain":"Fikir semula",
            "ConfirmCancel":"Sahkan batal",
            "AreYouSure":"Adakah anda pasti akan membatalkan penjualan item berikut?"
        }
    },
    "registerForm":{
        "RegistrationPeriod":"Tempoh Pendaftaran",
        "years":"tahun",
        "year":"Tahun",
        "RegistrationPrice":"Harga Pendaftaran",
        "Best":"Terbaik",
        "TotalPrice":"Harga keseluruhan",
        "What":"Apa yang",
        "question":"3-5 nama aksara hanya boleh mendaftar melalui kuota pembelian",
        "getQuota":"Dapatkan kuota",
        "RenewPeriod":"Tempoh Baharu",
        "questionTitle1":"Apakah kuota?",
        "questionTitle2":"Bagaimana untuk mendapatkan kuota?",
        "questionTitle3":"Kotak buta",
        "questionDesc1":"Anda perlu menggunakan kuota semasa mendaftarkan nama 3-5 aksara. Nama dengan aksara berbeza yang boleh didaftarkan menggunakan kuota, dan terdapat jenis kuota yang berbeza. Contohnya, kuota 4 aksara tidak boleh mendaftarkan nama 3 aksara.",
        "questionDesc2":"Membeli kotak buta akan mengandungi kuota dan beberapa token DNS, dan kebarangkalian untuk mendapatkan kuota aksara yang berbeza berbeza-beza. Carta kebarangkalian khusus dipaparkan pada antara muka pembelian kotak buta. Anda boleh klik pautan berikut untuk membeli <0>Kotak buta</0>. Kuota juga boleh dibeli dari <1>pasaran</1>. Kuota di pasaran dijual oleh pengguna lain.",
        "questionDesc3":"Kotak buta ialah cara yang lebih baik untuk mengedarkan nama. Pautan berikut ialah halaman pembelian dan pengenalan terperinci kotak buta:",
        "whatBlindBox":"apa itu kotak buta?",
        "whyBlindBox":"kenapa blind box?"
    },
    "market":{
        "Filter":"Penapis",
        "Items":"barang",
        "All":"Semua",
        "Length":"panjang",
        "SeeMySale":"Lihat Jualan Saya",
        "Down":"Bawah",
        "placeholder":"Cari nama",
        "OwnedBy":"Dimiliki oleh",
        "PurchaseConfirmation":"Pengesahan Pembelian",
        "PurchaseTips":"Adakah anda pasti untuk membeli item berikut?"
    },
    "account":{
        "Receive":"Terima",
        "nnsTips":"Jika anda telah membeli nama dengan memautkan dompet II(NNS) sebelum ini,<0>klik saya untuk menyelesaikan penghijrahan.</0>",
        "Expires":"tamat tempoh",
        "ViewSelling":"Lihat jualan",
        "MySale" : "Jualan saya",
        "placeholder":"Cari nama",
        "names":"Nama",
        "Frozen":"beku",
        "Available":"Tersedia",
        "Action":"Tindakan",
        "feeTips":"Yuran pembalut DICP (0.0001 ICP) dicaj oleh lejar apabila pengguna memindahkan ICP. Penamaan IC tidak mengenakan sebarang bayaran apabila membalut/membuka DICP.",
        "SelectAssets":"Pilih Aset",
        "DestinationAddress":"Alamat destinasi",
        "InsufficientBalance":"Baki tidak mencukupi",
        "EnterAddressOrName":"Masukkan Alamat / Nama",
        "EnterAmount":"Masukkan jumlah",
        "quotaDialogTitle":"Apakah kuota?",
        "quotaDialogDesc":"Anda perlu menggunakan kuota semasa mendaftarkan nama 3-5 aksara. Nama dengan aksara berbeza yang boleh didaftarkan menggunakan kuota dan terdapat jenis kuota yang berbeza. Contohnya, Kuota 3 aksara boleh digunakan untuk mendaftar nama yang lebih besar daripada atau sama dengan 3 digit , tetapi kuota 4 aksara tidak boleh mendaftarkan nama 3 aksara."
    },
    "nameSet":{
        "menu":{
            "Profile":"Profil",
            "SocialMedia":"Media Sosial",
            "Crypto":"Kripto",
            "Canister":"Canister",
            "Link":"Pautan",
            "TextRecord":"Rekod teks",
            "Transfer":"Pemindahan",
            "Renew":"Memperbaharui",
            "Resolver":"Penyelesai",
            "ReverseRecord":"Rekod terbalik"
        },
        "tryItNow":"cuba sekarang",
        "EditProfile":"Edit Profil",
        "SetAvatar":"Tetapkan NFT/Gravatar sebagai avatar saya",
        "DisplayName":"Nama paparan",
        "Email":"E-mel",
        "Location":"Lokasi",
        "Description":"Penerangan",
        "reclaim":"Tuntut semula",
        "noOwnController":"Pengawal nama ini telah dipindahkan. Untuk menyediakan, sila tuntut semula dahulu.",
        "SetYourReverseRecord":"Tetapkan rekod Songsang anda",
        "reverseRecordTips":"Adakah anda mahu orang lain mendapatkan nama anda melalui ID utama anda? Selepas menyediakan nama utama anda, orang lain boleh menggunakan ID utama untuk menyemak nama anda.",
        "yourDecentralized":"Akses laman web terdesentralisasi anda",
        "canisterTips1":"Adakah anda mempunyai tapak web terdesentralisasi yang digunakan pada IC? jika anda ada, tetapkan id kanister tapak web di sini, kemudian anda boleh mengaksesnya melalui url",
        "canisterTips2":"ialah contoh tapak web terdesentralisasi yang digunakan pada IC",
        "hasUrlNotice1Title":"Sahkan tetapan",
        "hasUrlNotice":"Anda telah menetapkan URL, jika anda menetapkan id kanister, melawati <bikeUrl>{{bikeUrl}}</bikeUrl> akan melawati tapak terdesentralisasi yang anda gunakan pada IC, bukannya <url>{{url}}</url> yang anda tetapkan",
        "yourWebsite":"Pautan ke tapak web web2/web3 anda melalui url:",
        "urlTips1":"Adakah anda mempunyai laman web sekarang? jika anda ada, tetapkan url laman web di sini, kemudian anda boleh mengaksesnya melalui url",
        "urlNotice":"(Notis: anda telah menetapkan id kanister, ia akan mengakses laman web kanister dan bukannya url anda)",
        "hasBeenTransferred":"Pendaftar nama telah dipindahkan." 
    },
    "dangerConfirm":{
        "tips":"Adakah anda pasti mahu memindahkan ke alamat berikut?",
        "transferRegistrant":{
            "title":"Pendaftar pindahan",
            "note":"Nota: Pendaftar pindahan bermakna anda bukan lagi pemilik nama tersebut, ia tidak boleh dibatalkan setelah pemindahan selesai."
        },
        "transferController":{
            "title":"Pengawal pemindahan",
            "note":"Nota: Pengawal pemindahan hanya bermaksud memindahkan kawalan, anda masih pemilik nama dan boleh mengambil semula kawalan pada bila-bila masa dengan [Tuntut Semula]."
        },
        "reclaim":{
            "title":"pengawal tuntut semula"
        },
        "transferToken":{
            "title":"Pindahkan token",
            "note":"Nota: Ia tidak boleh dibatalkan setelah pemindahan selesai."
        }
    },
    "migration":{
        "desc":"ICNaming mengalih keluar sokongan untuk Identiti Internet. Jika anda telah log masuk dengan Internet Identity dan membeli nama sebelum ini, anda boleh melengkapkan pemindahan dalam halaman ini.",
        "MigrationSteps":"Langkah migrasi",
        "step1":"Sambung ke Internet Identity dan dompet sasaran yang anda ingin pindahkan",
        "step2":"Pilih item untuk dipindahkan",
        "step3":"Lengkapkan langkah migrasi",
        "Connect":"Sambung",
        "ConnectII":"Sambung ke Identiti Internet",
        "ConnectTargetWallet":"Sambungkan dompet sasaran",
        "connectTips":"Sila sambungkan dompet Identiti Internet dan dompet sasaran terlebih dahulu.",
        "SelectItems":"Pilih item untuk dipindahkan",
        "Migrate":"Berhijrah"
    },
    "blindBox":{
        "ConnectWallet":"Sambung dompet",
        "YourBalance":"Baki anda",
        "RewardTips":"Membeli kotak buta mempunyai peluang untuk mendapatkan item berikut",
        "Reward":"Ganjaran",
        "Chance":"Peluang",
        "Remark":"Teguran",
        "Reasons":"Sebab",
        "ReasonsTips":"Memperkenalkan cara baharu untuk menerokai kemungkinan perkhidmatan nama, Yang melaksanakan kepercayaan teras kami: keadilan. Bukan itu sahaja, kotak buta berfungsi pada mekanisme ketidakpastian, yang mewujudkan banyak keseronokan apabila pengguna terlibat. Selain itu, reka bentuk kebarangkalian awal kami mewujudkan peluang yang lebih tinggi untuk pengguna mendapatkan lebih banyak kuota dan kuota jarang dengan perbelanjaan minimum yang harganya berpatutan di pasaran."
    },
    "errorInfo":{
        "inputError":"Input anda adalah Nilai haram",
        "notFoundAddress":"Alamat crypto ini tidak dapat ditemui."
    },
    "toast":{
        "SaleSuccessfully":"Jualan Berjaya",
        "CancelledSuccessfully":"Berjaya Dibatalkan",
        "exceedPendingOrders":"Tidak boleh melebihi bilangan pesanan yang belum selesai",
        "WrapSuccessful":"Bungkus Berjaya",
        "UnWrapSuccessful":"Unwrap Berjaya",
        "SaveSuccessful":"Simpan berjaya!",
        "SetSuccessfully":"Tetapkan Berjaya",
        "MigrateSuccess":"Berhijrah Berjaya"
    },
    "cheap":{
        "FindCheap":"Cari kuota murah di pasaran, <0>Jimat {{cheapPrice}} DICP untuk mendaftar</0>",
        "FindOne":"Cari kuota {{quota}}-chars di pasaran, <0>{{cheapPrice}} DICP Belinya untuk mendaftar</0>",
        "BuyQuotaToRegTitle":"Beli Kuota & Daftar",
        "RegTitle":"Daftar",
        "you":"diri sendiri",
        "savings":"Penjimatan sebanyak {{cheap}} DICP"
    }
}