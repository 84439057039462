/* eslint-disable @typescript-eslint/no-explicit-any */ export var PermissionsType;
(function(PermissionsType) {
    PermissionsType["identity"] = "permissions-identity";
    PermissionsType["wallet"] = "permissions-wallet";
})(PermissionsType || (PermissionsType = {}));
export var SignerMessageKind;
(function(SignerMessageKind) {
    SignerMessageKind["client"] = "signer-client";
    SignerMessageKind["ready"] = "signer-ready";
    SignerMessageKind["success"] = "signer-client-success";
    SignerMessageKind["fail"] = "signer-client-failure";
})(SignerMessageKind || (SignerMessageKind = {}));
export var TransactionMessageKind;
(function(TransactionMessageKind) {
    TransactionMessageKind["client"] = "transaction-client";
    TransactionMessageKind["ready"] = "transaction-ready";
    TransactionMessageKind["success"] = "transaction-client-success";
    TransactionMessageKind["fail"] = "transaction-client-failure";
})(TransactionMessageKind || (TransactionMessageKind = {}));
