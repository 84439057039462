import { observable } from "mobx";
import { observer } from "mobx-react";
import { PureComponent } from "react";
import { Container, Navbar, Button, Dropdown, Nav } from "react-bootstrap";
import { ClickBoundary, Avatar } from "idea-react";
import classNames from "classnames";
import { t } from 'i18next';
import { AssetTypes } from "../../page/Account";
import logo from '../../image/logo.png';
import * as style from './index.module.less';

import { CopyButton } from "../CopyBox";
import { SessionBox, WalletLogo } from "../SessionBox";

import session from '../../model/Session';
import { formatAddress } from "../../model/utils/helper";
import { WalletType } from "../../model/utils/connector";
import { BlindBoxShow } from "../../model/utils/config";

const { APP_NAME } = process.env;

export const navArr = [
  { title: 'FAQ', href: 'https://www.icnaming.com/#faq', key:'FAQ'},
  {
    title: 'Wiki',
    href: 'https://lake-reward-33f.notion.site/ICNaming-Wiki-b901af6b34c9434f9623377905a4ae78',
    key:'wiki'
  },
  {
    title: 'header.docs',
    href: 'https://lake-reward-33f.notion.site/Technical-Reference-05edce2048a44471b038df838c3cd117',
    key:'docs'
  },
  { title: 'header.blindBox', href: '#/blind-box', key:'blind-box' },
  { title: 'header.market', href: '#/market', key:'market' }
];

interface AppHeaderProps {
  theme: 'light' | 'dark'
}
@observer
export class AppHeader extends PureComponent<AppHeaderProps> {
  @observable
  menuOpen = false;


  renderNavLink() {
    const nav = location.hash.split('/')[1];

    const showNavArr = BlindBoxShow
      ? navArr
      : navArr.filter(({ key }) => key !== 'blind-box');

    return showNavArr.map(({ title, href, key }) => {
      return (
        <Nav.Link
          key={key}
          className="mx-4 py-2 text-secondary"
          href={href}
          active={nav === key}
        >
          {t(title)}
        </Nav.Link>
      );
    });
  }

  renderUserMenu() {
    const { menuOpen } = this,
      { principal, type } = session.walletAuth || {},
      { isDesktop } = session;

    return (
      <Navbar.Text>
        {!principal ? (
          <SessionBox>
            <Button
              className={classNames(
                'border-0 bg-white text-primary shadow',
                style.btnConnect,
                isDesktop ? 'ms-3' : 'my-2 text-primary'
              )}
            >
              {t('base.connect')}
            </Button>
          </SessionBox>
        ) : (
          <ClickBoundary
            className="position-relative"
            onOuterClick={() => (this.menuOpen = false)}
          >
            <button
              className="appearance-none"
              onMouseEnter={() => (this.menuOpen = true)}
            >
              <Avatar
                className={`shadow rounded-circle bg-white p-2  ${style.avatar}`}
                src={WalletLogo[WalletType[type]]}
              />
            </button>
            {isDesktop ? (
              <Dropdown.Menu
                className="shadow rounded-3 border-0 right-0 text-center"
                show={menuOpen}
              >
                <Dropdown.Item>
                  <CopyButton value={principal}>
                    {formatAddress(principal)}
                  </CopyButton>
                </Dropdown.Item>
                {this.renderDropdownDivider()}
                {AssetTypes.map(type => (
                  <Dropdown.Item
                    key={type}
                    className="text-muted text-capitalize"
                    href={`#/account/${type}`}
                  >
                    {t(`base.${type}`)}
                  </Dropdown.Item>
                ))}
                {this.renderDropdownDivider()}
                <Dropdown.Item
                  className="text-muted"
                  onClick={() => session.disconnectWallet()}
                >
                  {t("base.Logout")}
                </Dropdown.Item>
              </Dropdown.Menu>
            ) : (
              <>
                {this.renderDropdownDivider('mb-3')}
                {AssetTypes.map(type => (
                  <Dropdown.Item
                    key={type}
                    className="text-secondary py-2 text-capitalize"
                    href={`#/account/${type}`}
                  >
                    {type}
                  </Dropdown.Item>
                ))}
                {this.renderDropdownDivider('mt-3')}
                <Dropdown.Item
                  className="text-secondary py-4"
                  onClick={() => session.disconnectWallet()}
                >
                  Log out
                </Dropdown.Item>
                {this.renderDropdownDivider('mb-3')}
              </>
            )}
          </ClickBoundary>
        )}
      </Navbar.Text>
    );
  }

  renderDropdownDivider = (className?: string) => (
    <Dropdown.Divider
      className={classNames(className, style.divider)}
    />
  );

  render() {
    const { theme } = this.props,
      { isDesktop } = session;
    return (
      <Navbar className={classNames(style[`nav-${theme}`])} variant={theme} sticky="top" expand="sm">
        <Container>
          <Navbar.Brand href=".">
            <img className={`${style.logo} me-2`} src={logo} />
            {APP_NAME}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="shadow-none" />
          <Navbar.Offcanvas placement="end" className={classNames(theme === 'dark' && style.darkOffCanvas)}>
            {isDesktop ? (
              <Navbar.Collapse className="justify-content-end">                
                {this.renderNavLink()}
                {this.renderUserMenu()}
              </Navbar.Collapse>
            ) : (
              <Navbar.Collapse className="justify-content-center mt-4 text-center">
                {this.renderUserMenu()}
                {this.renderNavLink()}
              </Navbar.Collapse>
            )}
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    )
  }
}