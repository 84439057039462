@font-face {
  font-family: 'iconfont'; /* Project id 3378940 */
  src: url('//at.alicdn.com/t/font_3378940_0ccboax4ljac.woff2?t=1651775743006')
      format('woff2'),
    url('//at.alicdn.com/t/font_3378940_0ccboax4ljac.woff?t=1651775743006')
      format('woff'),
    url('//at.alicdn.com/t/font_3378940_0ccboax4ljac.ttf?t=1651775743006')
      format('truetype');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-arrow-down:before {
  content: '\e7d2';
}
.icon-discord:before {
  content: '\e678';
}
.icon-github:before {
  content: '\e63b';
}
.icon-medium:before {
  content: '\e887';
}
.icon-twitter:before {
  content: '\e88a';
}
.icon-minus:before {
  content: '\e7fd';
}
.icon-copy:before {
  content: '\e614';
}
.icon-plus:before {
  content: '\e8fe';
}

@font-face {
  font-family: 'ADAM.CG PRO';
  font-style: normal;
  font-weight: 400;
  src: url('./font/ADAM.CGPRO.woff') format('woff');
}
@font-face {
  font-family: 'Eras Demi ITC';
  font-style: normal;
  src: url('./font/ITCErasStd-Demi.woff') format('woff');
}
.mystery-box-page {
  background: #050a21;
  color: #6876a1;
  width: 100%;
  overflow: hidden;
  .mystery-desc-title {
    color: #d0d5e6;
    margin-bottom: 1rem;
  }
}
.reward-table {
  width: 100%;
  th,
  td {
    border: 1px solid #3d456b;
    padding: 0.5rem 0.4rem;
    &:first-child {
      width: 42%;
      padding-right: 0.2rem;
    }
    &:last-child {
      width: 50%;
    }
    @media (min-width: 576px) {
      &:first-child {
        width: 25%;
      }
    }
  }
}

.mystery-box-wrap {
  padding: 30px 15px;
  max-width: 800px;
}

.mystery-box-card {
  margin-bottom: 30px;
  border: 1px solid;
  border-image: linear-gradient(
      180deg,
      rgba(71, 73, 102, 1),
      rgba(42, 44, 73, 1)
    )
    1 1;
  position: relative;
  &::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: 30px solid;
    border-color: transparent transparent transparent #050a21;
    position: absolute;
    left: -1px;
    top: -31px;
  }
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: 30px solid;
    border-color: transparent #050a21 transparent transparent;
    position: absolute;
    right: -2px;
    bottom: -30px;
  }
}
.mystery-box-card-inner {
  height: 100%;
  overflow: hidden;
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: 30px solid;
    border-color: transparent rgba(42, 44, 73, 1) transparent transparent;
    position: absolute;
    right: 0px;
    bottom: -30px;
  }
}
.mystery-box-card-header {
  height: 30px;
  line-height: 31px;
  background: url(./img/card_bg_top.png) no-repeat;
  background-size: auto 100%;
  text-indent: 50px;
  font-size: 1rem;
  font-family: 'ADAM.CG PRO', sans-serif;
  color: #fff;
  text-shadow: 0px 0px 4px #0f39ff;
  font-weight: 100;
  margin-top: -1px;
  margin-left: -1px;
}
.mystery-box-card-body {
  padding: 1.1rem;
}
.mystery-box-card-body-hd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.mbcbh-left {
  font-family: 'ADAM.CG PRO', sans-serif;
  color: #fff;
  text-shadow: 0px 0px 4px #0f39ff;
  &::before {
    content: '';
    display: inline-block;
    margin-right: 10px;
    width: 2px;
    height: 12px;
    background: #fffe30;
    box-shadow: 0px 0px 1px 0px #0f39ff;
  }
}
.mystery-box-card-body-desc {
  font-size: 12px;
  line-height: 1.2rem;
  margin-bottom: 0.5rem;
}
.mystery-box-card-body-bt {
  text-align: right;
}
.mystery-box-card-blank {
  color: #07daff;
  font-size: 12px;
  text-decoration: none;
  &::after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border: 5px solid;
    border-color: transparent transparent transparent #07daff;
    position: relative;
    margin-left: 5px;
  }
  &:hover {
    color: #ffcf30;
    &::after {
      border-color: transparent transparent transparent #ffcf30;
    }
  }
}
.mystery-box-card-body .box {
  width: 100%;
  height: 250px;
  overflow: hidden;
}
.mystery-box-card-body .box .lottie {
  bottom: 10px;
}
.box-op-wrap {
  max-width: 400px;
  font-size: 12px;
}
.progress-wrap {
  height: 30px;
}
.progress-bar {
  height: 12px;
  background: #050d21;
  border-radius: 6px;
  border: 1px solid #222f49;
  width: calc(100% - 50px);
}
.progress-bar-inner {
  border-radius: 8px;
  height: 8px;
}
.progress-bar-4 {
  background: linear-gradient(180deg, #ffcf30 0%, #e49800 100%);
  box-shadow: inset 0px -1px 3px 0px #c26100;
}
.progress-bar-3 {
  background: linear-gradient(180deg, #eff45a 0%, #266524 100%);
  box-shadow: inset 0px -1px 3px 0px #153005;
}
.progress-bar-badge {
  width: 40px;
  height: 28px;
  line-height: 28px;
  background: linear-gradient(180deg, #ff9500 0%, #ffbf00 100%);
  box-shadow: 0px 2px 5px 1px #3f1a00, 0px 2px 0px 0px #bf5002;
  border-radius: 14px;
  border: 1px solid rgba(255, 205, 39, 1);
  right: -40px;
  top: 0;
  transform: translateY(-10px);
  font-family: 'ADAM.CG PRO', sans-serif;
  font-size: 1rem;
  color: #fff;
  text-shadow: 0px 1px 2px #286613;
  z-index: 3;
}
.progress-bar-3 .progress-bar-badge {
  background: linear-gradient(180deg, #32a226 0%, #78c848 100%);
  box-shadow: 0px 2px 0px 0px #286613;
  border-radius: 14px;
  border: 1px solid rgba(198, 255, 39, 1);
}

.quota3 {
  background: url('./img/3@2x.png');
}
.quota4 {
  background: url('./img/4@2x.png');
}
.quota5 {
  background: url('./img/5@2x.png');
}
.quota6 {
  background: url('./img/6@2x.png');
}
.quota7 {
  background: url('./img/7@2x.png');
}
.dns {
  background: url('../../image/logo.png');
}
.prize {
  width: 28px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: contain;
}
.btn {
  cursor: pointer;
  width: 100%;
  height: 44px;
  line-height: 44px;
  display: block;
  margin: 0 auto 1rem;
  padding: 0 !important;
  font-size: 20px !important;
  font-family: 'Eras Demi ITC' !important;
  font-weight: normal;
  color: #ffffff !important;
  border-radius: 12px;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent !important;
}
.btn-buy,
.btn-confirm {
  background: linear-gradient(180deg, #ff9500 0%, #ffbf00 100%);
  box-shadow: 0px 6px 0px 0px #bf5002;
  border: 1px solid rgba(255, 205, 39, 1) !important;
  text-shadow: 0px 0px 4px #fb6a01 !important;
}
.btn-buy:hover,
.btn-confirm:hover {
  background: linear-gradient(180deg, #ffb800 0%, #ffda6e 100%) !important;
  box-shadow: 0px 6px 0px 0px #ff7c00 !important;
}
.btn-buy:active,
.btn-confirm:active {
  background: linear-gradient(180deg, #ef7600 0%, #ffbf00 100%) !important;
  box-shadow: 0px 6px 0px 0px #bf5002 !important;
}

.btn-connect {
  background: linear-gradient(180deg, #00cc88 0%, #00ff80 100%) !important;
  box-shadow: 0px 6px 0px 0px #008054 !important;
  border: 1px solid rgba(0, 255, 135, 1) !important;
  text-shadow: 0px 0px 4px #008054;
  &:hover {
    background: linear-gradient(180deg, #00ffaa 0%, #73ffb9 100%) !important;
    box-shadow: 0px 6px 0px 0px #00bd7d !important;
    border: 1px solid rgba(109, 255, 186, 1) !important;
  }
  &:active {
    background: linear-gradient(180deg, #008b61 0%, #00df70 100%);
    box-shadow: 0px 6px 0px 0px #008054;
    border: 1px solid rgba(0, 255, 135, 1);
  }
}

.btn-dicp {
  background: linear-gradient(180deg, #0088cc 0%, #00aaff 100%) !important;
  box-shadow: 0px 6px 0px 0px #005580 !important;
  border: 1px solid rgba(0, 170, 255, 1) !important;
  text-shadow: 0px 0px 4px #005580;
  &:hover {
    background: linear-gradient(180deg, #00aaff 0%, #55c6ff 100%) !important;
    box-shadow: 0px 6px 0px 0px #007dbc !important;
    border: 1px solid rgba(82, 197, 255, 1) !important;
  }
  &:active {
    background: linear-gradient(180deg, #004d94 0%, #00aaff 100%) !important;
    box-shadow: 0px 6px 0px 0px #005580 !important;
    border: 1px solid rgba(0, 170, 255, 1) !important;
  }
}

.btn-disable {
  background: #3e485d !important;
  box-shadow: 0px 6px 0px 0px #2e3646 !important;
  border: 1px solid rgba(96, 117, 160, 1) !important;
  cursor: not-allowed !important;
}

.box-btn {
  cursor: pointer;
  width: 55px;
  height: 40px;
  background: linear-gradient(180deg, #ff9500 0%, #ffbf00 100%);
  box-shadow: 0px 4px 0px 0px #bf5002;
  border-radius: 12px;
  border: 1px solid rgba(255, 205, 39, 1);
  color: #fff;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  &:hover {
    background: linear-gradient(180deg, #ffb800 0%, #ffda6e 100%);
    box-shadow: 0px 4px 0px 0px #ff7c00;
  }
  &:active {
    background: linear-gradient(180deg, #ef7600 0%, #ffbf00 100%);
    box-shadow: 0px 4px 0px 0px #bf5002;
  }
}

.box-form {
  height: 50px;
}
.input-number {
  width: 100%;
  margin: 0 10px;
  height: 40px;
  line-height: 40px;
  background: #09112a;
  box-shadow: 0px 3px 0px 0px #1e386c;
  border-radius: 12px;
  border: 1px solid #40598a;
  box-sizing: border-box;
  font-family: 'ADAM.CG PRO', sans-serif;
  text-align: center;
  color: #fff;
  font-size: 20px;
  text-shadow: 0px 1px 2px #bf5002;
  outline: none;
}
.input-number::-webkit-inner-spin-button,
.input-number::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input-number:focus {
  background: #101c40;
  box-shadow: 0px 3px 0px 0px #204388;
  border: 1px solid #84adff;
}

.fixed-wrap {
  position: fixed;
  background: linear-gradient(180deg, #081135 0%, #050a21 100%);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1100;
  &::before {
    position: absolute;
    content: '';
    display: block;
    width: 1018px;
    height: 619px;
    background: linear-gradient(180deg, #00c568 0%, rgba(0, 197, 104, 0) 100%);
    opacity: 0.3;
    filter: blur(50px);
  }
  &::after {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    right: 0;
    width: 914px;
    height: 457px;
    background: linear-gradient(180deg, #0093ff 0%, rgba(0, 147, 255, 0) 100%);
    opacity: 0.25;
    filter: blur(50px);
  }
}
.open-warp {
  height: 100%;
  z-index: 9;
}
.open-box {
  width: 100%;
  height: 342px;
}
.open-prize {
  max-width: 800px;
}
.open-prize-inner {
  height: 70vh;
}
.prize-row {
  background: url(./img/light@2x.png) no-repeat center;
  background-size: cover;
  padding: 0 5rem;
}
.prize-row-sup {
  font-size: 30px;
  height: 35px;
  font-family: 'Eras Demi ITC';
  font-weight: normal;
  color: #ffbe00;
  background-image: linear-gradient(180deg, #ff9a00 0%, #fac827 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.prize-row-sub {
  height: 28px;
  font-size: 24px;
  font-family: 'Eras Demi ITC';
  font-weight: normal;
  color: #ffbe00;
  background-image: linear-gradient(180deg, #ff9a00 0%, #fac827 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.prize-number {
  font-family: 'Eras Demi ITC';
  font-size: 24px;
  height: 29px;
  font-weight: 100;
  background-image: linear-gradient(180deg, #ff9a00 0%, #fac827 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.animate {
  animation-duration: 0.5s;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}
.animate.animate__delay-1s {
  animation-delay: 0.9s;
  animation-delay: 0.9s;
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.1, 0.3);
  }
  50% {
    opacity: 1;
  }
}
.animate_zoomIn {
  animation-name: zoomIn;
}
.footer {
  background-image: linear-gradient(
    360deg,
    #0c1e45 0%,
    #0d153e 0%,
    #090e2a 100%
  );
  .footer-link {
    color: #1d2863;
    margin: 0.5rem;
    transition: color 0.3s;
    &:last-child {
      &::before {
        content: '';
        display: inline-block;
        width: 1px;
        height: 1.5rem;
        margin: 0 1rem 0 0;
        background: #1d2863;
      }
    }
    &:hover,
    &:focus {
      color: #07daff;
    }
    .iconfont {
      font-size: 1.9rem;
    }
    .icon-twitter {
      font-size: 2.1rem;
    }
    .icon-medium {
      font-size: 2.1rem;
    }
    .icon-arrow-down {
      font-size: 2rem;
    }
  }
}

@media (min-width: 576px) {
  .progress-bar-badge {
    line-height: 23px;
  }
  .mystery-box-card-body .box {
    width: 365px;
    height: 250px;
  }

  .open-box {
    width: 390px;
    height: 100%;
  }
  .btn {
    width: 400px;
  }
  .open-prize-inner {
    height: 62vh;
  }
}

.wallet-logo {
  width: 1.5rem;
  margin-right: 10px;
}

.loading {
  left: 0%;
  top: 0%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  z-index: 888;
}
.spinner {
  animation: spinnerFour 1s linear infinite;
  border: solid 5px transparent;
  border-top: solid 5px #00cfbb;
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
}

@keyframes spinnerFour {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
