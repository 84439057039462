import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { PureComponent } from 'react';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Icon } from 'idea-react';
import { sleep } from 'web-utility';
import classNames from 'classnames';

import { IconButton } from './IconButton';
import session from '../model/Session';

export interface CopyBoxProps {
  className?: string;
  value?: string;
}

class CopyModel {
  @observable
  copied = false;

  async copy(value: string) {
    await navigator.clipboard.writeText(value);

    this.copied = true;

    await sleep(1);

    this.copied = false;
  }
}

@observer
export class CopyBox extends PureComponent<CopyBoxProps> {
  private store = new CopyModel();

  render() {
    const { className, value, children } = this.props,
      { isDesktop } = session,
      { copied } = this.store;

    return (
      <div className={classNames("d-flex align-items-center", isDesktop && "pe-5")}>
        {typeof children === 'string' ? (
          <OverlayTrigger 
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id={`tooltip-text`}>
                {children}
              </Tooltip>
            }>
              <span data-bs-toggle="tooltip" data-bs-title="Disabled tooltip" className={classNames("d-inline-block text-truncate", className)}>{children}</span>
          </OverlayTrigger>
        ) : (
          children
        )}
        <IconButton
          className="ms-2"
          name={copied ? 'check-lg' : 'files'}
          size={1}
          disabled={copied}
          onClick={({ currentTarget }) =>
            this.store.copy(
              value || currentTarget.previousElementSibling.textContent.trim()
            )
          }
        />
      </div>
    );
  }
}

@observer
export class CopyButton extends PureComponent<CopyBoxProps> {
  private store = new CopyModel();

  render() {
    const { value, children } = this.props,
      { copied } = this.store;

    return (
      <Button
        className="rounded-pill"
        size="sm"
        variant="primary"
        onClick={({ currentTarget }) =>
          this.store.copy(
            value || currentTarget.previousElementSibling.textContent.trim()
          )
        }
      >
        {children}

        <Icon className="ms-2" name={copied ? 'check-lg' : 'files'} />
      </Button>
    );
  }
}
