import { toggle } from './Base';
import { RegistrarDomainModel } from './Domain';
import { createPager } from './utils/stream';
import { DOMAIN_SUBFIX } from './utils/config';

const createNamePager = createPager<string>;

export class RegistryDomainModel extends RegistrarDomainModel {
  private stream: Record<string, ReturnType<typeof createNamePager>> = {};

  clear() {
    super.clear();

    for (const { reset } of Object.values(this.stream)) reset();
  }

  private createStream(address: string) {
    return createNamePager(
      async function* (this: RegistryDomainModel) {
        if (this.suffix !== DOMAIN_SUBFIX) return;

        for (let i = 0; ; i++) {
          const items = await this.registryIC.getControlledNamesByPager(
            address,
            i * this.pageSize,
            this.pageSize
          );
          if (i === 0) this.totalCount += 0;

          if (!items[0]) break;

          yield* items;
        }
      }.bind(this),
      async function* (this: RegistryDomainModel) {
        if (this.suffix !== 'icp') return;

        for (let i = 0; ; i++) {
          const items = await this.registryICP.getControlledNamesByPager(
            address,
            i * this.pageSize,
            this.pageSize
          );
          if (i === 0) this.totalCount += 0;

          if (!items[0]) break;

          yield* items;
        }
      }.bind(this)
    );
  }

  @toggle('downloading')
  async getList(address: string, pageIndex = this.pageIndex + 1) {
    const stream = (this.stream[address] ||= this.createStream(address));

    const names = await stream.getPage(pageIndex, this.pageSize);

    if (!names[0]) return (this.noMore = true) && this.list;

    this.pageIndex = pageIndex;

    if (names.length < this.pageSize) this.noMore = true;

    const list = await Promise.all(names.map(name => this.getOne(name)));

    return (this.list = list);
  }
}

export default new RegistryDomainModel();
