import { FC } from 'react';
import { Image } from 'react-bootstrap';

const ImageMap = {
  box: new URL('../image/person-box.png', import.meta.url) + '',
  files: new URL('../image/person-files.png', import.meta.url) + '',
  media: new URL('../image/person-media.png', import.meta.url) + ''
};

export interface EmptyCardProps {
  variant: keyof typeof ImageMap;
}

export const EmptyCard: FC<EmptyCardProps> = ({ variant, children }) => (
  <div className="my-4 text-center py-3">
    <Image
      className="mb-5"
      style={{ width: '22rem' }}
      src={ImageMap[variant]}
    />
    <p className="text-muted">{children || 'There is no data'}</p>
  </div>
);
