import { lazy, Suspense } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';

import { LoadingFullPage } from '../component/LoadingFullPage';
import './index.less';
import { HomePage } from './Home';
import SearchPage from './Search';
import FeelLuckyPage from './Search/Lucky';
import { MarketPage } from './Market';
import PressKit from './PressKit';
import '../model/utils/i18n/i18next-config';
const BlindBox = lazy(() => import('./BlindBox'));
const AccountPage = lazy(() => import('./Account'));
const CoinWrapPage = lazy(() => import('./Account/Asset/Wrap'));
const AssetTransferPage = lazy(() => import('./Account/Asset/Transfer'));
const MigrationPage = lazy(() => import('./Migration'));

const DomainCanisterPage = lazy(() => import('./Domain/Canister'));
const DomainLinkPage = lazy(() => import('./Domain/Link'));
const DomainCryptoPage = lazy(() => import('./Domain/Crypto'));
const DomainMediaPage = lazy(() => import('./Domain/Media'));
const DomainProfilePage = lazy(() => import('./Domain/Profile'));
const DomainRenewPage = lazy(() => import('./Domain/Renew'));
const DomainResolverPage = lazy(() => import('./Domain/Resolver'));
const DomainTextRecordPage = lazy(() => import('./Domain/TextRecord'));
const DomainTransferPage = lazy(() => import('./Domain/Transfer'));
const ReverseRecordPage = lazy(() => import('./Domain/ReverseRecord'));

const MarketSalePage = lazy(() => import('./Market/Sale'));
const MarketBuyPage = lazy(() => import('./Market/Buy'));
export function PageFrame() {
  return (
    <HashRouter>
      <Suspense fallback={<LoadingFullPage />}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/search/" element={<SearchPage />} />
          <Route path="/search/:keyword" element={<SearchPage />} />
          <Route path="/feel-lucky" element={<FeelLuckyPage />} />
          <Route path="/account/:asset" element={<AccountPage />} />
          <Route path="/account/assets/wrap/:coin" element={<CoinWrapPage />} />
          <Route
            path="/account/assets/:id/transfer"
            element={<AssetTransferPage />}
          />
          <Route path="/market" element={<MarketPage />} />
          <Route
            path="/market/domain/:name/sale"
            element={<MarketSalePage />}
          />
          <Route
            path="/market/domain/:name/buy/:order"
            element={<MarketBuyPage />}
          />
          <Route path="/domain/:name/profile" element={<DomainProfilePage />} />
          <Route path="/domain/:name/crypto" element={<DomainCryptoPage />} />
          <Route
            path="/domain/:name/canister"
            element={<DomainCanisterPage />}
          />
          <Route path="/domain/:name/link" element={<DomainLinkPage />} />
          <Route
            path="/domain/:name/text-record"
            element={<DomainTextRecordPage />}
          />
          <Route
            path="/domain/:name/resolver"
            element={<DomainResolverPage />}
          />
          <Route path="/domain/:name/renew" element={<DomainRenewPage />} />
          <Route
            path="/domain/:name/transfer"
            element={<DomainTransferPage />}
          />
          <Route
            path="/domain/:name/social-media"
            element={<DomainMediaPage />}
          />
           <Route
            path="/domain/:name/reverse-record"
            element={<ReverseRecordPage />}
          />
          <Route path="/blind-box" element={<BlindBox />} />
          <Route path="/migration" element={<MigrationPage />} />
          <Route path="/press-kit" element={<PressKit />} />
        </Routes>
      </Suspense>
    </HashRouter>
  );
}
