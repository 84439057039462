import { observable } from 'mobx';
import { SessionModel } from './Session';
import { IAuth } from './interface';
import { Actor, ActorSubclass, HttpAgent } from '@dfinity/agent';
import { Principal } from '@dfinity/principal';
import { WalletConnectError, WalletConnectErrorCode } from './utils/exception';
import {
  ICP_FAVORITES_ID,
  ICP_HOST,
  ICP_REGISTRAR_ID,
  ICP_REGISTRY_ID,
  ICP_RESOLVER_ID,
  IC_HOST
} from './utils/config';
import { IDL } from '@dfinity/candid';
import session from './Session';

export class AuthBase implements IAuth {
  @observable
  session: SessionModel;

  private static ic_agent?: HttpAgent;
  private static icp_agent?: HttpAgent;

  constructor() {
    this.session = session;
  }

  /**
   * create an actor
   * @param canisterId  canisterId
   * @param canisterDid  canisterDid
   * @param anonymous  create actor with anonymous identity
   * @returns
   */
  public async createActor<T>(
    canisterId: string,
    canisterDid: IDL.InterfaceFactory,
    anonymous: boolean = true
  ): Promise<ActorSubclass<T>> {
    if (!anonymous && !this.session.isConnected) {
      throw new WalletConnectError(
        WalletConnectErrorCode.NotConnected,
        'Wallet not connected'
      );
    }

    if (anonymous) {
      return this.createActorWithAnonymousIdentity(canisterId, canisterDid);
    }

    return await this.session.walletConnector.createActor(
      canisterId,
      canisterDid,
      this.session.walletAuth.type
    );
  }

  /**
   * create an actor with an anonymous identity
   * @param canisterDid
   * @param canisterId
   * @returns
   */
  private createActorWithAnonymousIdentity<T>(
    canisterId: string | Principal,
    canisterDid: IDL.InterfaceFactory
  ): ActorSubclass<T> {
    const actor = Actor.createActor<T>(canisterDid, {
      agent: this.getAgent(canisterId.toString()),
      canisterId
    });

    return actor;
  }

  private getAgent = (canisterId: string) => {
    const ids = [
      ICP_REGISTRAR_ID,
      ICP_REGISTRY_ID,
      ICP_RESOLVER_ID,
      ICP_FAVORITES_ID
    ];

    console.debug(`ic ${IC_HOST} icp: ${ICP_HOST}`);
    if (ids.some(id => id === canisterId)) {
      if (!AuthBase.icp_agent) {
        AuthBase.icp_agent = new HttpAgent({ host: ICP_HOST });
      }
      return AuthBase.icp_agent;
    } else {
      if (!AuthBase.ic_agent) {
        AuthBase.ic_agent = new HttpAgent({ host: IC_HOST });
        AuthBase.ic_agent.fetchRootKey().catch(e => {
          console.error(`fetchRootKey error: ${e}`);
        });
      }
      return AuthBase.ic_agent;
    }
  };
}
