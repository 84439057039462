import { FC } from 'react';

import { IconButton } from './IconButton';
import { MainLayout, MainLayoutProps } from './MainLayout';

export interface SubPageLayoutProps extends MainLayoutProps {
  title?: string;
  prevHref?: string;
}

export const SubPageLayout: FC<SubPageLayoutProps> = ({
  title,
  prevHref,
  children,
  ...rest
}) => (
  <MainLayout {...rest}>
    <h1 className="fs-5 fw-500">
      <IconButton
        className="me-3"
        name="arrow-left"
        href={prevHref}
        onClick={prevHref ? undefined : () => history.back()}
      />
      {title}
    </h1>

    <main className="pt-4">{children}</main>
  </MainLayout>
);
