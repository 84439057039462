.wrapper {
  .icon {
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
  }
  .children {
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
  }

  .input {
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;

    &:focus {
      box-shadow: none;
    }
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  }
}

.custom-search-control {
  color: rgba(140, 158, 204, 1) !important;
  font-weight: 400 !important;
}

.custom-search-icon {
  color: rgba(140, 158, 204, 1) !important;
}
