export default function(param) {
    var IDL = param.IDL;
    var AccountIdentifier = IDL.Text;
    var Duration = IDL.Record({
        secs: IDL.Nat64,
        nanos: IDL.Nat32
    });
    var ArchiveOptions = IDL.Record({
        max_message_size_bytes: IDL.Opt(IDL.Nat32),
        node_max_memory_size_bytes: IDL.Opt(IDL.Nat32),
        controller_id: IDL.Principal
    });
    var ICPTs = IDL.Record({
        e8s: IDL.Nat64
    });
    var LedgerCanisterInitPayload = IDL.Record({
        send_whitelist: IDL.Vec(IDL.Tuple(IDL.Principal)),
        minting_account: AccountIdentifier,
        transaction_window: IDL.Opt(Duration),
        max_message_size_bytes: IDL.Opt(IDL.Nat32),
        archive_options: IDL.Opt(ArchiveOptions),
        initial_values: IDL.Vec(IDL.Tuple(AccountIdentifier, ICPTs))
    });
    var AccountBalanceArgs = IDL.Record({
        account: AccountIdentifier
    });
    var CanisterId = IDL.Principal;
    var HeaderField = IDL.Tuple(IDL.Text, IDL.Text);
    var HttpRequest = IDL.Record({
        url: IDL.Text,
        method: IDL.Text,
        body: IDL.Vec(IDL.Nat8),
        headers: IDL.Vec(HeaderField)
    });
    var HttpResponse = IDL.Record({
        body: IDL.Vec(IDL.Nat8),
        headers: IDL.Vec(HeaderField),
        status_code: IDL.Nat16
    });
    var SubAccount = IDL.Vec(IDL.Nat8);
    var BlockHeight = IDL.Nat64;
    var NotifyCanisterArgs = IDL.Record({
        to_subaccount: IDL.Opt(SubAccount),
        from_subaccount: IDL.Opt(SubAccount),
        to_canister: IDL.Principal,
        max_fee: ICPTs,
        block_height: BlockHeight
    });
    var Memo = IDL.Nat64;
    var TimeStamp = IDL.Record({
        timestamp_nanos: IDL.Nat64
    });
    var SendArgs = IDL.Record({
        to: AccountIdentifier,
        fee: ICPTs,
        memo: Memo,
        from_subaccount: IDL.Opt(SubAccount),
        created_at_time: IDL.Opt(TimeStamp),
        amount: ICPTs
    });
    return IDL.Service({
        account_balance_dfx: IDL.Func([
            AccountBalanceArgs
        ], [
            ICPTs
        ], [
            "query"
        ]),
        get_nodes: IDL.Func([], [
            IDL.Vec(CanisterId)
        ], [
            "query"
        ]),
        http_request: IDL.Func([
            HttpRequest
        ], [
            HttpResponse
        ], [
            "query"
        ]),
        notify_dfx: IDL.Func([
            NotifyCanisterArgs
        ], [], []),
        send_dfx: IDL.Func([
            SendArgs
        ], [
            BlockHeight
        ], [])
    });
};
export var init = function(param) {
    var IDL = param.IDL;
    var AccountIdentifier = IDL.Text;
    var Duration = IDL.Record({
        secs: IDL.Nat64,
        nanos: IDL.Nat32
    });
    var ArchiveOptions = IDL.Record({
        max_message_size_bytes: IDL.Opt(IDL.Nat32),
        node_max_memory_size_bytes: IDL.Opt(IDL.Nat32),
        controller_id: IDL.Principal
    });
    var ICPTs = IDL.Record({
        e8s: IDL.Nat64
    });
    var LedgerCanisterInitPayload = IDL.Record({
        send_whitelist: IDL.Vec(IDL.Tuple(IDL.Principal)),
        minting_account: AccountIdentifier,
        transaction_window: IDL.Opt(Duration),
        max_message_size_bytes: IDL.Opt(IDL.Nat32),
        archive_options: IDL.Opt(ArchiveOptions),
        initial_values: IDL.Vec(IDL.Tuple(AccountIdentifier, ICPTs))
    });
    return [
        LedgerCanisterInitPayload
    ];
};
