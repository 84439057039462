"use strict";
exports.__esModule = true;
exports.init = exports.idlFactory = void 0;
var idlFactory = function (_a) {
    var IDL = _a.IDL;
    var Settings = IDL.Record({
        'dicp_receiver_account_id': IDL.Text,
        'allow_caller_ids': IDL.Vec(IDL.Principal)
    });
    var Stats = IDL.Record({
        'cycles_balance': IDL.Nat64,
        'latest_transaction_block_height': IDL.Nat64,
        'seconds_since_last_ledger_sync': IDL.Nat64,
        'payments_version': IDL.Nat64,
        'earliest_transaction_block_height': IDL.Nat64,
        'transactions_count': IDL.Nat64,
        'block_height_synced_up_to': IDL.Opt(IDL.Nat64),
        'latest_transaction_timestamp_nanos': IDL.Nat64,
        'earliest_transaction_timestamp_nanos': IDL.Nat64
    });
    var GetTipOfLedgerResponse = IDL.Record({
        'payments_version': IDL.Nat64,
        'block_height_synced_up_to': IDL.Nat64
    });
    var HttpRequest = IDL.Record({
        'url': IDL.Text,
        'method': IDL.Text,
        'body': IDL.Vec(IDL.Nat8),
        'headers': IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text))
    });
    var StreamingStrategy = IDL.Variant({
        'Callback': IDL.Record({
            'token': IDL.Record({}),
            'callback': IDL.Func([], [], [])
        })
    });
    var HttpResponse = IDL.Record({
        'body': IDL.Vec(IDL.Nat8),
        'headers': IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
        'streaming_strategy': IDL.Opt(StreamingStrategy),
        'status_code': IDL.Nat16
    });
    return IDL.Service({
        'check_mint_status': IDL.Func([IDL.Nat64], [IDL.Bool], ['query']),
        'get_setting': IDL.Func([], [Settings], ['query']),
        'get_stats': IDL.Func([], [Stats], ['query']),
        'get_tip_of_ledger': IDL.Func([], [GetTipOfLedgerResponse], ['query']),
        'http_request': IDL.Func([HttpRequest], [HttpResponse], ['query'])
    });
};
exports.idlFactory = idlFactory;
var init = function (_a) {
    var IDL = _a.IDL;
    return [IDL.Principal, IDL.Principal];
};
exports.init = init;
