@import (reference) '../index.less';

.title {
  background-image: linear-gradient(
    90deg,
    rgba(22, 210, 117, 1) 0,
    rgba(6, 133, 255, 1) 50.279054%,
    rgba(85, 0, 255, 1) 100%
  );
  width: max-content;
  color: rgba(18, 19, 21, 1);
  font-family: Montserrat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.custom-img {
  max-width: 50%;
}

.card:hover {
  cursor: pointer;
  padding: 0;
  border-radius: 1rem;
  .outer-shadow();
  overflow: hidden;
  .download {
    background-color: rgba(45, 67, 255, 0.1);
  }
}
