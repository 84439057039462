{
    "appName":"icNaming",
    "base":{
        "names":"名前",
        "favorites":"お気に入り",
        "assets":"アセット",
        "asset":"アセット",
        "Logout":"接続を解除",
        "Name":"名前",
        "registrant":"とうろくしゃ",
        "controller":"コントローラ",
        "Register":"注册",
        "Details":"詳細",
        "Expiration":"有効期限",
        "Resolver":"解決する",
        "Quota":"クォータ",
        "Buy":"買う",
        "Sell":"売る",
        "Price":"価格",
        "Amount":"量",
        "Item":"アイテム",
        "Cost":"料金",
        "balance":"残高",
        "wrap":"ラップICP",
        "unwrap":"DICPのアンラップ",
        "Cancel":"キャンセル",
        "Confirm":"確定",
        "Submit":"申し出る",
        "connect": "接続",
        "BuyName":"名称を購入",
        "BuyQuota":"クォータ購入",
        "SellName":"販売名",
        "SellQuota":"販売枠",
        "Setting":"設定",
        "Set":"セット",
        "transfer":"譲渡",
        "chars":"-文字",
        "type":"タイプ",
        "Fixed":"固定",
        "Renew":"更新する",
        "EnterAmount":"額を入れる",
        "EnterPrice":"価格を入力",
        "Save":"セーブ",
        "Delete":"消す",
        "NotSet":"未設定",
        "fee":"料金",
        "Tips":"ヒント",
        "Address":"アドレス",
        "to":"交換元",
        "from":"交換先"
    },
    "header": {
        "docs":"ドックス",
        "blindBox":"ミステリーボックス",
        "market":"マーケット"
    },
    "sessionBox":{
        "Select":"ウォレットを選択する",
        "title":"このDappに接続するウォレットを選択してください",
        "TryAgain":"再試行",
        "usedIIBefore":"以前、IIを使ったことがあるのですが？"
    },
    "home": {
        "slogan" : "分散型デジタルID"
    },
    "domainSearch":{
        "search":"検索",
        "placeholder":"名前またはアドレスの検索"
    },
    "domain" : {
        "card" : {
            "Buy":"買う",
            "View":"View",
            "Sell":"売る",
            "Claim":"认领",
            "HowToClaim":"认领方法",
            "registered":"この名前はすでに登録されています"
        },
        "CustomBadge":{
            "Available": "入手可",
            "Unavailable": "入手不可",
            "Reserved": "予約済み",
            "OnSale": "所有者による販売のため",
            "ForSaleByOwner":"所有者による販売のため"
        },
        "sell":{
            "Confirmation":"確認事項",
            "ThinkAgain":"考え直せ",
            "ConfirmCancel":"キャンセル確認",
            "AreYouSure":"以下の商品の販売を中止してよろしいですか？"
        }
    },
    "registerForm":{
        "RegistrationPeriod":"登録期間",
        "years":"年々",
        "year":"年",
        "RegistrationPrice":"登録価格",
        "Best":"ベスト",
        "TotalPrice":"合計金額",
        "What":"何が",
        "question":"3〜5文字の名前は購入枠でのみ登録可能",
        "getQuota":"クォータを取得する",
        "RenewPeriod":"更新時期",
        "questionTitle1":"ノルマって何？",
        "questionTitle2":"クォータを取得するには？",
        "questionTitle3":"ミステリーボックス",
        "questionDesc1":"3～5文字の名前を登録する場合、クォータを使用する必要があります。クォータを使用して登録できる文字数の異なる名前と、クォータには種類があります。例えば、4文字のクォータでは、3文字の名前を登録することはできません。",
        "questionDesc2":"ブラインドボックスを購入すると、クォータといくつかのDNSトークンが入っており、異なるキャラクターのクォータが得られる確率が異なります。具体的な確率表はブラインドボックスの購入インターフェイスに表示されます。以下のリンクをクリックして、購入することができます <0>blind box</0>。クォータは <1>マーケット</1> から購入することもできる。マーケットにあるクォータは他のユーザーによって販売される。",
        "questionDesc3":"以下のリンクは、ブラインドボックスの購入ページと詳細な紹介です",
        "whatBlindBox":"ブラインドボックスって何？",
        "whyBlindBox":"なぜブラインドボックスなのか？"
    },
    "market":{
        "Filter":"フィルター",
        "Items":"アイテム",
        "All":"すべて",
        "Length":"長さ",
        "SeeMySale":"マイセールスを見る",
        "Down":"閉じる",
        "placeholder":"検索名",
        "OwnedBy":"が所有している",
        "PurchaseConfirmation":"購入確認書",
        "PurchaseTips":"以下の商品は本当に購入されますか？"
    },
    "account":{
        "Receive":"レシービング",
        "nnsTips":"過去にIIウォレット(NNS)連携で名前を購入したことがある場合。<0>をクリックして、移行を完了させてください。</0>",
        "Expires":"期限切れ",
        "ViewSelling":"販売を見る",
        "MySale" : "マイセール",
        "placeholder":"検索名",
        "names":"名前",
        "Frozen":"フローズン",
        "Available":"入手可",
        "Action":"アクション",
        "feeTips":"DICPのラップフィー（0.0001 ICP）は、ICPを移動する際に台帳から請求されます。ICネーミングでは、DICPのラップ/アンラップ時に手数料はかかりません。",
        "SelectAssets":"アセットを選択",
        "DestinationAddress":"宛先アドレス",
        "InsufficientBalance":"バランス不足",
        "EnterAddressOrName":"アドレス/名前を入力",
        "EnterAmount":"額を入れる",
        "quotaDialogTitle":"ノルマって何？",
        "quotaDialogDesc":"3～5文字の名前を登録する場合、クォータを使用する必要があります。クォータを使用して登録できる文字数の異なる名前と、クォータには種類があります。例えば、3桁以上の名前を登録する場合は、3文字クォータを使用できますが、4文字クォータでは3桁の名前を登録することはできません。"
    },
    "nameSet":{
        "menu":{
            "Profile":"プロフィール",
            "SocialMedia":"ソーシャルメディア",
            "Crypto":"クリプト",
            "Canister":"キャニスター",
            "Link":"リンク",
            "TextRecord":"テキストレコード",
            "Transfer":"譲渡",
            "Renew":"更新する",
            "Resolver":"リゾルバ",
            "ReverseRecord":"逆解析"
        },
        "tryItNow":"今すぐ試す",
        "EditProfile":"プロフィール編集",
        "SetAvatar":"NFT/Gravatarをアバターに設定。",
        "DisplayName":"ディスプレイ名",
        "Email":"電子メール",
        "Location":"所在地",
        "Description":"記述",
        "reclaim":"リクレイム",
        "noOwnController":"この名称のコントローラは移管されました。設定するには、まず再生してください。",
        "SetYourReverseRecord":"リバースレコードを設定する",
        "reverseRecordTips":"プリンシパルIDから他の人に自分の名前を知ってもらいたいですか？プライマリーネームを設定すると、他の人がプリンシパルIDを使ってあなたの名前を確認することができるようになります。",
        "yourDecentralized":"分散型ウェブサイトへのアクセス",
        "canisterTips1":"IC上に分散型ウェブサイトを展開されている場合、ここにウェブサイトのキャニスターIDを設定すると、URLでアクセスできるようになります。",
        "canisterTips2":"は、IC上に展開された分散型ウェブサイトの例です。",
        "hasUrlNotice1Title":"設定を確認する",
        "hasUrlNotice":"URLを設定しましたが、キャニスターIDを設定すると、<bikeUrl>{{bikeUrl}}</bikeUrl> を訪問すると、設定した <url>{{url}}</url> ではなく、ICに展開した分散サイトが訪問されます。",
        "yourWebsite":"お客様のWeb2/Web3サイトへURLでリンクします",
        "urlTips1":"現在、ウェブサイトをお持ちですか？ もしお持ちなら、ここにウェブサイトのURLを設定すると、URLでアクセスできるようになります。",
        "urlNotice":"(注意: キャニスターIDを設定すると、あなたのURLの代わりにキャニスターのウェブサイトにアクセスすることになります)",
        "hasBeenTransferred":"名前の登録者が移管されました。" 
    },
    "dangerConfirm":{
        "tips":"以下のアドレスに転送してよろしいですか？",
        "transferRegistrant":{
            "title":"登録者変更",
            "note":"注：登録者変更とは、お客様が登録者でなくなることであり、登録者変更完了後のキャンセルはできません。"
        },
        "transferController":{
            "title":"支配権の異動",
            "note":"注: コントローラーの譲渡とは、コントロールの譲渡のみを意味します。あなたは引き続き名前の所有者であり、[リクレイム] によっていつでもコントロールを取り戻すことができます。"
        },
        "reclaim":{
            "title":"コントロールの回復"
        },
        "transferToken":{
            "title":"トークンの譲渡",
            "note":"注：転送完了後のキャンセルはできません。"
        }
    },
    "migration":{
        "desc":"ICNamingはInternet Identityのサポートを終了しました。以前にInternet Identityでログインし、名前を購入したことがある場合は、このページで移行を完了させることができます。",
        "MigrationSteps":"移行手順",
        "step1":"Internet Identityと移行先のウォレットに接続します。",
        "step2":"移行する項目を選択",
        "step3":"移行手順を完了する",
        "Connect":"接続",
        "ConnectII":"インターネットIDに接続する",
        "ConnectTargetWallet":"対象のウォレットを接続する",
        "connectTips":"インターネットIDウォレットとターゲットウォレットを先に接続してください。",
        "SelectItems":"移行する項目を選択",
        "Migrate":"マイグレイト"
    },
    "blindBox":{
        "ConnectWallet":"コネクトウォレット",
        "YourBalance":"あなたのバランス",
        "RewardTips":"ブラインドボックスを購入すると、以下のアイテムが手に入る確率があります",
        "Reward":"報奨金",
        "Chance":"チャンス",
        "Remark":"備考",
        "Reasons":"理由",
        "ReasonsTips":"ブラインドボックスの仕組みは、不確定要素が多いため、ユーザーが参加することで楽しさが生まれます。また、初期確率の設計により、最小限の出費でより多くの枠やレアな枠を獲得できる可能性が高く、市場でもリーズナブルな価格設定になっています。"
    },
    "errorInfo":{
        "inputError":"あなたの入力は違法です 値",
        "notFoundAddress":"この暗号化アドレスは見つかりません。"
    },
    "toast":{
        "SaleSuccessfully":"売却成功",
        "CancelledSuccessfully":"キャンセル成功",
        "exceedPendingOrders":"保留中の注文数を超えることはできない",
        "WrapSuccessful":"ラップの成功",
        "UnWrapSuccessful":"アンラップサクセス",
        "SaveSuccessful":"セーブ成功",
        "SetSuccessfully":"設定成功",
        "MigrateSuccess":"マイグレーション成功"
    },
    "cheap":{
        "FindCheap":"マーケットで安いノルマを発見 <0>登録で {{cheapPrice}} DICPを節約する</0>。",
        "FindOne":"マーケットで {{quota}}-chars のクォータを発見 <0>{cheapPrice}} DICPで購入・登録</0>",
        "BuyQuotaToRegTitle":"購入クォータ & 登録で",
        "RegTitle":"登録で",
        "you":"それはあなたです",
        "savings": "{{cheap}} DICPの節約"
    }
}