import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { MainLayout } from '../../component/MainLayout';
import { DomainSearch } from '../../component/DomainSearch';
import * as styles from './index.module.less';
import AD from './img/AD.png';
import sessionStore from '../../model/Session';
import { BlindBoxShow } from '../../model/utils/config';

export function HomePage() {
  const { isDesktop } = sessionStore;
  const { t, i18n } = useTranslation();
  return (
    <MainLayout>
      <h1 className={`display-1 mx-auto ${styles.title}`}>ICNaming</h1>

      <p className="text-center">{t('home.slogan')}</p>

      <DomainSearch auto />

      {BlindBoxShow && (
        <div className="text-center py-5">
          <Link to="/blind-box">
            <Image
              className={classNames('rounded', isDesktop ? 'w-50' : 'w-100')}
              src={AD}
            />
          </Link>
        </div>
      )}
    </MainLayout>
  );
}
