import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { PureComponent } from 'react';
import { Form, Image } from 'react-bootstrap';
import { SpinnerButton, Option, Select } from 'idea-react';
import { t } from 'i18next';

import { NumberInput } from './NumberInput';
import { CoinIcons } from './AssetBadge';
import { EditLayout } from './Exchange/EditLayout';
import { Dialog } from './Dialog';

import assetStore from '../model/Asset';
import marketStore from '../model/Market';
import { toastStore } from './Toast';

export type SaleProps = {
    show: boolean;
    title: string;
    onHide: () => void;
    name?: string;
    quota?: number;
    reLoadNames?: (orderId: bigint) => void;
    reLoadQuotas?: (orderId: number) => void;
};

@observer
export class DialogSell extends PureComponent<SaleProps> {
    @observable
    quotaAmount = 0;

    @observable
    closeButton = true;

    save = async ({ price, amount }: Record<'price' | 'amount', number>) => {
        const { name, quota, reLoadNames, reLoadQuotas } = this.props,
            { quotaAmount } = this;
        this.closeButton = false;
        if (name) {
            const orderId = await marketStore.sellName(name, price);
            toastStore.success(t("toast.SaleSuccessfully"));
            reLoadNames(orderId);
        } else {
            await marketStore.sellQuota(+quota, price, amount);
            toastStore.success(t("toast.SaleSuccessfully"));
            reLoadQuotas(quotaAmount);
            this.quotaAmount = 0;
        }
        this.closeButton = true;
    };

    renderForm = () => {
        const { quota } = this.props,
            { availableAmount } = assetStore.currentQuota || {},
            { uploading } = marketStore;

        return (
            <>
                <Form.Group className="mb-4">
                    <Form.Label>{t("base.type")}</Form.Label>
                    <Select value="0">
                        <Option value="0">
                            <Image src={CoinIcons.DICP} /> {t("base.Fixed")}{t("base.Price")}
                        </Option>
                    </Select>
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>{t("base.Price")}</Form.Label>
                    <div className="d-flex align-items-end">
                        <Form.Control
                            type="number"
                            name="price"
                            required
                            min={0}
                            step={0.01}
                            placeholder={t("base.EnterPrice")}
                        />
                        <small className="fw-bold ms-3">DICP</small>
                    </div>
                </Form.Group>
                {quota && (
                    <Form.Group className="mb-4">
                        <Form.Label>{t("base.Amount")}</Form.Label>

                        <NumberInput
                            name="amount"
                            max={availableAmount + ''}
                            value={this.quotaAmount}
                            onChange={value => (this.quotaAmount = value)}
                        />
                        <Form.Text>
                            {quota}{t("base.chars")} {t("base.Quota")} {t("account.Available")}: {availableAmount}
                        </Form.Text>
                    </Form.Group>
                )}
                <SpinnerButton
                    className="w-100"
                    type="submit"
                    animation="border"
                    loading={!!uploading}
                >
                    {t("base.Sell")}
                </SpinnerButton>
            </>
        );
    };

    render() {
        const { show, title, name, onHide } = this.props,
            { closeButton } = this,
            { currentQuota } = assetStore;
        return (
            <Dialog title={title}
                backdrop={closeButton ? true : 'static'}
                closeButton={closeButton}
                show={show}
                centered={true}
                size="lg"
                onHide={onHide}
            >
                <div className="pt-4">
                    <EditLayout
                        name={name}
                        quota={currentQuota?.quota}
                        availableAmount={currentQuota?.availableAmount as number}
                        onSave={this.save}
                    >
                        {this.renderForm()}
                    </EditLayout>
                </div>
            </Dialog>
        );
    }
}

