{
    "appName":"icNaming",
    "base":{
        "names":"Nama",
        "favorites":"Favorit",
        "assets":"Aset",
        "asset":"Aset",
        "Logout":"Logout",
        "Name":"Nama",
        "registrant":"pendaftar",
        "controller":"pengontrol",
        "Register":"Daftar",
        "Details":"Rincian",
        "Expiration":"Tanggal Kedaluwarsa",
        "Resolver":"Penyelesai",
        "Quota":"Kuota",
        "Buy":"Beli",
        "Sell":"Jual",
        "Price":"Harga",
        "Amount":"Jumlah",
        "Item":"Barang",
        "Cost":"Biaya",
        "balance":"keseimbangan",
        "wrap":"Bungkus ICP",
        "unwrap":"Buka bungkus DICP",
        "Cancel":"Batal",
        "Confirm":"Konfirmasi",
        "Submit":"Kirim",
        "connect": "Hubungkan",
        "BuyName":"Beli Nama",
        "BuyQuota":"Beli Kuota",
        "SellName":"Jual Nama",
        "SellQuota":"Jual Kuota",
        "Setting":"Pengaturan",
        "Set":"Set",
        "transfer":"transfer",
        "chars":"-chars",
        "type":"Jenis",
        "Fixed":"Tetap",
        "Renew":"Perbarui",
        "EnterAmount":"Masukkan jumlah",
        "EnterPrice":"Masukkan harga",
        "Save":"Simpan",
        "Delete":"Hapus",
        "NotSet":"Tidak Ditetapkan",
        "fee":"Biaya",
        "Tips":"Tips",
        "Address":"Alamat",
        "to":"untuk",
        "from":"dari"
    },
    "header": {
        "docs":"Dokumen",
        "blindBox":"BlindBox",
        "market":"Pasar"
    },
    "sessionBox":{
        "Select":"Pilih dompet",
        "title":"Silakan pilih dompet untuk terhubung ke dapp ini:",
        "TryAgain":"Coba lagi",
        "usedIIBefore":"Saya menggunakan II sebelumnya?"
    },
    "home": {
        "slogan" : "Identitas Digital Terdesentralisasi"
    },
    "domainSearch":{
        "search":"Cari",
        "placeholder":"Cari nama atau alamat"
    },
    "domain" : {
        "card" : {
            "Buy":"Beli",
            "View":"Lihat",
            "Sell":"Jual",
            "Claim":"Klaim",
            "HowToClaim":"Bagaimana cara mengklaim",
            "registered":"Nama ini sudah terdaftar."
        },
        "CustomBadge":{
            "Available": "Tersedia",
            "Unavailable": "Tidak tersedia",
            "Reserved": "Dicadangkan",
            "OnSale": "Dijual oleh pemilik",
            "ForSaleByOwner":"Dijual oleh pemilik"
        },
        "sell":{
            "Confirmation":"Konfirmasi",
            "ThinkAgain":"Pikirkan lagi",
            "ConfirmCancel":"Konfirmasi pembatalan",
            "AreYouSure":"Apakah Anda yakin untuk membatalkan penjualan item berikut?"
        }
    },
    "registerForm":{
        "RegistrationPeriod":"Periode Pendaftaran",
        "years":"tahun",
        "year":"Tahun",
        "RegistrationPrice":"Harga Pendaftaran",
        "Best":"Terbaik",
        "TotalPrice":"Total harga",
        "What":"Apa itu",
        "question":"3-5 karakter nama hanya dapat mendaftar melalui pembelian kuota",
        "getQuota":"Dapatkan kuota",
        "RenewPeriod":"Periode Perpanjangan",
        "questionTitle1":"Apa itu kuota?",
        "questionTitle2":"Bagaimana cara mendapatkan kuota?",
        "questionTitle3":"Kotak buta",
        "questionDesc1":"Anda perlu menggunakan kuota saat mendaftarkan nama 3-5 karakter. Nama dengan karakter yang berbeda yang dapat didaftarkan menggunakan kuota, dan ada berbagai jenis kuota. Misalnya, kuota 4 karakter tidak bisa mendaftarkan nama 3 karakter.",
        "questionDesc2":"Membeli blind box akan berisi kuota dan beberapa token DNS, dan probabilitas mendapatkan kuota chars yang berbeda bervariasi. Bagan probabilitas spesifik ditampilkan pada antarmuka pembelian blind box. Anda dapat mengklik tautan berikut untuk membeli <0>blind box</0>.Kuota juga bisa dibeli dari <1>pasar</1>. Kuota di pasar dijual oleh pengguna lain.",
        "questionDesc3":"Blind box adalah cara yang lebih baik untuk mendistribusikan nama, tautan berikut adalah halaman pembelian dan pengenalan blind box secara terperinci:",
        "whatBlindBox":"apa itu kotak buta?",
        "whyBlindBox":"mengapa kotak buta?"
    },
    "market":{
        "Filter":"Filter",
        "Items":"Barang",
        "All":"Semua",
        "Length":"Panjang",
        "SeeMySale":"Lihat Penjualan Saya",
        "Down":"Turun",
        "placeholder":"Cari Nama",
        "OwnedBy":"Dimiliki oleh",
        "PurchaseConfirmation":"Konfirmasi Pembelian",
        "PurchaseTips":"Apakah Anda yakin untuk membeli item berikut?"
    },
    "account":{
        "Receive":"Terima",
        "nnsTips":"Jika Anda telah membeli nama dengan menautkan dompet II(NNS) sebelumnya，<0>klik saya untuk menyelesaikan migrasi.</0>",
        "Expires":"Kedaluwarsa",
        "ViewSelling":"Lihat penjualan",
        "MySale" : "Penjualan saya",
        "placeholder":"Cari Nama",
        "names":"Nama",
        "Frozen":"Beku",
        "Available":"Tersedia",
        "Action":"Tindakan",
        "feeTips":"Biaya bungkus DICP (0,0001 ICP) dibebankan oleh buku besar ketika pengguna mentransfer ICP. IC Naming tidak membebankan biaya apa pun saat wrap/unwrap DICP.",
        "SelectAssets":"Pilih Aset",
        "DestinationAddress":"Alamat tujuan",
        "InsufficientBalance":"Aset yang tidak mencukupi",
        "EnterAddressOrName":"Masukkan Alamat / Nama",
        "EnterAmount":"Masukkan jumlah",
        "questionTitle1":"Apa itu kuota?",
        "quotaDialogDesc":"Anda perlu menggunakan kuota saat mendaftarkan nama 3-5 karakter. Nama dengan karakter yang berbeda yang dapat didaftarkan menggunakan kuota, dan ada berbagai jenis kuota. Misalnya, kuota 3 karakter dapat digunakan untuk mendaftarkan nama yang lebih besar atau sama dengan 3 digit, tetapi kuota 4 karakter tidak dapat mendaftarkan nama 3 karakter."
    },
    "nameSet":{
        "menu":{
            "Profile":"Profil",
            "SocialMedia":"Media sosial",
            "Crypto":"Crypto",
            "Canister":"Tabung",
            "Link":"Tautan",
            "TextRecord":"Catatan teks",
            "Transfer":"Transfer",
            "Renew":"Perbarui",
            "Resolver":"Penyelesai",
            "ReverseRecord":"Catatan terbalik"
        },
        "tryItNow":"coba sekarang",
        "EditProfile":"Sunting Profil",
        "SetAvatar":"Menetapkan NFT/Gravatar sebagai avatar saya",
        "DisplayName":"Tampilkan nama",
        "Email":"Email",
        "Location":"Lokasi",
        "Description":"Deskripsi",
        "reclaim":"Reklamasi",
        "noOwnController":"Pengontrol nama ini telah ditransfer. Untuk mengatur, silakan reklamasi terlebih dahulu.",
        "SetYourReverseRecord":"Tetapkan catatan Terbalik Anda",
        "reverseRecordTips":"Apakah Anda ingin orang lain mendapatkan nama Anda melalui ID utama Anda? Setelah menyiapkan nama utama Anda, orang lain bisa menggunakan ID utama untuk memeriksa nama Anda.",
        "yourDecentralized":"Akses situs web terdesentralisasi Anda",
        "canisterTips1":"Apakah Anda memiliki situs web terdesentralisasi yang digunakan di IC? jika demikian, atur id tabung situs web di sini, maka Anda dapat mengaksesnya dengan url ",
        "canisterTips2":"adalah contoh situs web terdesentralisasi yang digunakan pada IC",
        "hasUrlNotice1Title":"Konfirmasi pengaturan",
        "hasUrlNotice":"Anda telah mengatur URL, jika Anda mengatur id tabung, mengunjungi <bikeUrl>{{bikeUrl}}</bikeUrl> akan mengunjungi situs terdesentralisasi yang Anda gunakan di IC, bukan <url>{{url}}</url> yang Anda atur.",
        "yourWebsite":"Tautkan ke situs web2/web3 Anda melalui url:",
        "urlTips1":"Apakah Anda memiliki situs web sekarang? jika sudah, atur url situs web di sini, maka Anda dapat mengaksesnya dengan url",
        "urlNotice":"(Perhatikan: Anda telah menetapkan id canister, itu akan mengakses situs web canister, bukan url Anda)",
        "hasBeenTransferred":"Pendaftar nama telah ditransfer." 
    },
    "dangerConfirm":{
        "tips":"Apakah Anda yakin ingin mentransfer ke alamat berikut?",
        "transferRegistrant":{
            "title":"Transfer pendaftar",
            "note":"Catatan: Transfer pendaftar berarti Anda bukan lagi pemilik nama, tidak dapat dibatalkan setelah transfer selesai."
        },
        "transferController":{
            "title":"Pengontrol transfer",
            "note":"Catatan: Transfer controller hanya berarti mentransfer kontrol, Anda masih menjadi pemilik nama dan bisa mengambil kembali kontrol kapan saja dengan [Reklamasi]."
        },
        "reclaim":{
            "title":"reklamasi pengontrol"
        },
        "transferToken":{
            "title":"Mentransfer token",
            "note":"Catatan: Tidak dapat dibatalkan setelah transfer selesai."
        }
    },
    "migration":{
        "desc":"ICNaming menghapus dukungan untuk Internet Identity. Jika Anda telah masuk dengan Internet Identity dan membeli nama sebelumnya, Anda dapat menyelesaikan migrasi di halaman ini.",
        "MigrationSteps":"Langkah-langkah migrasi",
        "step1":"Sambungkan ke Internet Identity dan dompet target yang ingin Anda migrasikan",
        "step2":"Pilih item yang akan dimigrasikan",
        "step3":"Selesaikan langkah-langkah migrasi",
        "Connect":"Hubungkan",
        "ConnectII":"Hubungkan ke Identitas Internet",
        "ConnectTargetWallet":"Menghubungkan dompet target",
        "connectTips":"Harap hubungkan dompet Identitas Internet dan dompet target terlebih dahulu.",
        "SelectItems":"Pilih item yang akan dimigrasikan",
        "Migrate":"Bermigrasi"
    },
    "blindBox":{
        "ConnectWallet":"Hubungkan dompet",
        "YourBalance":"Keseimbangan Anda",
        "RewardTips":"Membeli blind box berpeluang mendapatkan item berikut ini",
        "Reward":"Hadiah",
        "Chance":"Kesempatan",
        "Remark":"Catatan",
        "Reasons":"Alasan",
        "ReasonsTips":"Memperkenalkan cara baru untuk mengeksplorasi kemungkinan layanan nama, yang melaksanakan keyakinan inti kami: keadilan, tidak hanya itu, kotak buta bekerja pada mekanisme ketidakpastian, yang menciptakan banyak kesenangan ketika pengguna terlibat. Selain itu, desain probabilitas awal kami menciptakan peluang yang lebih tinggi bagi pengguna untuk mendapatkan lebih banyak kuota dan kuota langka dengan pengeluaran minimum yang harganya masuk akal di pasar.Translated with www.DeepL.com/Translator (free version)"
    },
    "errorInfo":{
        "inputError":"Masukan Anda adalah Nilai ilegal",
        "notFoundAddress":"Masukan Anda adalah Nilai ilegal"
    },
    "toast":{
        "SaleSuccessfully":"Penjualan Berhasil",
        "CancelledSuccessfully":"Berhasil dibatalkan",
        "exceedPendingOrders":"Tidak bisa melebihi jumlah pesanan yang tertunda",
        "WrapSuccessful":"Bungkus Keberhasilan",
        "UnWrapSuccessful":"UnWrap Berhasil",
        "SaveSuccessful":"Selamat sukses!",
        "SetSuccessfully":"Berhasil Diatur",
        "MigrateSuccess":"Berhasil Bermigrasi"
    },
    "cheap":{
        "FindCheap": "Menemukan kuota murah di pasar, <0>Hemat {{cheapPrice}} DICP untuk pendaftaran</0>",
        "FindOne": "Temukan kredit {{quota}}-chars di marketplace, <0>{{cheapPrice}}} DICP untuk membeli dan mendaftar</0>",
        "BuyQuotaToRegTitle":"Membeli Kuota & Pendaftaran",
        "RegTitle":"Pendaftaran",
        "you":"Ini adalah Anda",
        "savings":"Penghematan {{cheap}} DICP"
    }
}