.footer {
  .icon-button {
    margin: 0 1rem;
    position: relative;

    &:last-child {
      &::before {
        position: absolute;
        left: -1rem;
        content: '';
        display: inline-block;
        width: 2px;
        height: 1.5rem;

        background: rgba(255, 255, 255, 1);
      }
    }
  }
}

.main {
  @media (min-width: 768px) and (min-width: 1550px) {
    max-width: 65vw !important;
  }

  @media (min-width: 1200px) and(max-width:1550px) {
    max-width: 75vw !important;
  }
}
