import { Hour, Minute, Second } from 'web-utility';
import { observable } from 'mobx';
import { Principal } from '@dfinity/principal';
import { RegistryDto } from '@icnaming/registry_client';

import { BaseModel, toggle } from './Base';
import { CryptoName, DomainCrypto, CryptoKey } from './Crypto';
import { NameRegistrarDetails, Registrar } from './canister/Registrar';
import { Registry } from './canister/Registry';
import { Resolver, ResolverKey, ResolverRecord } from './canister/Resolver';
import { withLogging, withCache } from './utils/errorLogger';
import {
  DOMAIN_SUBFIX,
  ICP_REGISTRAR_ID,
  ICP_REGISTRY_ID,
  ICP_RESOLVER_ID,
  IC_REGISTRAR_ID,
  IC_REGISTRY_ID,
  IC_RESOLVER_ID,
  RESOLVER_GET_NAME_STATUS_WRAPPER
} from './utils/config';
import {
  RESOLVER_KEY_URL,
  RESOLVER_KEY_AVATAR,
  RESOLVER_KEY_DISPLAY_NAME,
  RESOLVER_KEY_DESCRIPTION,
  RESOLVER_KEY_LOCATION,
  RESOLVER_KEY_ICP_CANISTER
} from './utils/config/ResolverKey';
import * as ResolverKeyMap from './utils/config/ResolverKey';

const ResolverKeys = Object.values(ResolverKeyMap);

export const DomainOwnerships = ['registrant', 'controller'] as const;

export type DomainOwnership = typeof DomainOwnerships[number];

export interface DomainMeta
  extends Omit<NameRegistrarDetails, 'owner'>,
  Omit<RegistryDto, 'owner'>,
  Record<DomainOwnership, Principal> {
  reserved?: boolean;
  registered?: boolean;
  transfered?: boolean;
}

export interface DomainProfile {
  logo?: string;
  primaryName?: string;
  displayName?: string;
  description?: string;
  location?: string;
  email?: string;
}

const ProfileKey: Record<keyof DomainProfile, ResolverKey> = {
  logo: RESOLVER_KEY_AVATAR,
  primaryName: ResolverKeyMap.RESOLVER_KEY_SETTING_REVERSE_RESOLUTION_PRINCIPAL,
  displayName: RESOLVER_KEY_DISPLAY_NAME,
  description: RESOLVER_KEY_DESCRIPTION,
  location: RESOLVER_KEY_LOCATION,
  email: ResolverKeyMap.RESOLVER_KEY_EMAIL
};

export const MediaPlatforms = [
  'GitHub',
  'Medium',
  'Twitter',
  'FaceBook',
  'Instagram',
  'Reddit',
  'Telegram',
  'Discord',
  'OpenChat',
  'Relation',
  'District',
  'Dscvr'
] as const;

export type DomainMedia = Partial<
  Record<typeof MediaPlatforms[number], string>
>;

const MediaKey: Record<keyof DomainMedia, ResolverKey> = {
  GitHub: ResolverKeyMap.RESOLVER_KEY_GITHUB,
  Medium: ResolverKeyMap.RESOLVER_KEY_MEDIUM,
  Twitter: ResolverKeyMap.RESOLVER_KEY_TWITTER,
  FaceBook: ResolverKeyMap.RESOLVER_KEY_FACEBOOK,
  Instagram: ResolverKeyMap.RESOLVER_KEY_INSTAGRAM,
  Reddit: ResolverKeyMap.RESOLVER_KEY_REDDIT,
  Telegram: ResolverKeyMap.RESOLVER_KEY_TELEGRAM,
  Discord: ResolverKeyMap.RESOLVER_KEY_DISCORD,
  OpenChat: ResolverKeyMap.RESOLVER_KEY_OPENCHAT,
  Relation: ResolverKeyMap.RESOLVER_KEY_RELATION,
  District: ResolverKeyMap.RESOLVER_KEY_DISTRIKT,
  Dscvr: ResolverKeyMap.RESOLVER_KEY_DSCVR
};

export class ResolverModel extends BaseModel {
  private resolverIC = new Resolver(IC_RESOLVER_ID);
  private resolverICP = new Resolver(ICP_RESOLVER_ID);
  private registrarIC = new Registrar(IC_REGISTRAR_ID);
  private registrarICP = new Registrar(ICP_REGISTRAR_ID);
  private registryIC = new Registry(IC_REGISTRY_ID);
  private registryICP = new Registry(ICP_REGISTRY_ID);

  @observable
  removing = 0;

  @observable
  meta?: DomainMeta;

  @observable
  profile: DomainProfile = {};

  @observable
  media: DomainMedia = {};

  @observable
  crypto: DomainCrypto = {};

  @observable
  canister?: string;

  @observable
  url?: string;

  @observable
  textRecords: Record<string, string> = {};

  clear() {
    // this.meta = this.canister = undefined;
    this.profile = {};
    this.media = {};
    this.crypto = {};
    this.textRecords = {};
  }

  @withCache(
    (_, __, name: string) => `${RESOLVER_GET_NAME_STATUS_WRAPPER}_${name}`,
    (Minute * 3) / Second
  )
  protected static async getNameStatus(registrar: Registrar, name: string) {
    return registrar.getNameStatus(name);
  }

  static async nameMetaOf(
    registry: Registry,
    registrar: Registrar,
    name: string
  ) {
    const registryGetDetailsWrapper = async (name: string) => {
      try {
        return await registry.getDetails(name);
      } catch {
        return { name, owner: void 0, resolver: void 0, ttl: 0n };
      }
    };
    const registrarGetDetailsWrapper = async (name: string) => {
      try {
        return await registrar.getNameDetails(name);
      } catch {
        return { name, createdAt: 0n, expiredAt: 0n };
      }
    };
    const [
      { owner: controller, ...registryData },
      { owner: registrant, ...registrarData },
      { kept, registered }
    ] = await Promise.all([
      registryGetDetailsWrapper(name),
      registrarGetDetailsWrapper(name),
      this.getNameStatus(registrar, name)
    ]);

    return {
      ...registryData,
      ...registrarData,
      registrant,
      controller,
      reserved: kept,
      registered,
      transfered: registrant + '' !== controller + ''
    };
  }

  private canisterOf(name: string) {
    const isIC = name.endsWith(`.${DOMAIN_SUBFIX}`);

    return {
      registry: isIC ? this.registryIC : this.registryICP,
      registrar: isIC ? this.registrarIC : this.registrarICP,
      resolver: isIC ? this.resolverIC : this.resolverICP
    };
  }

  @withLogging
  @toggle('downloading')
  async getMeta(name: string) {
    const { registry, registrar } = this.canisterOf(name);

    return (this.meta = await ResolverModel.nameMetaOf(
      registry,
      registrar,
      name
    ));
  }

  @toggle('uploading')
  async setMeta(name: string, resolver: string, ttl = 600) {
    await this.canisterOf(name).registry.setRecord(name, ttl, resolver);

    return this.getMeta(name);
  }

  @toggle('uploading')
  async transferRegistrar(name: string, target: string) {
    await this.canisterOf(name).registrar.transferName(target, name);

    return this.getMeta(name);
  }

  @toggle('uploading')
  async transferController(name: string, target: string) {
    const { registrar, registry } = this.canisterOf(name);

    const resolver = await registrar.getPublicResolver();

    await registry.transferController(name, target, resolver);

    return this.getMeta(name);
  }

  @toggle('uploading')
  async reclaimController(name: string) {
    await this.canisterOf(name).registrar.reclaimName(name);

    return this.getMeta(name);
  }

  private unpackData<T extends string>(
    map: Record<T, ResolverKey>,
    data: ResolverRecord
  ) {
    return Object.fromEntries(
      Object.entries(map)
        .map(([name, key]) => {
          const value = data[key as ResolverKey];

          return value && [name, value];
        })
        .filter(Boolean)
    ) as Record<T, string>;
  }

  private packData<T extends string>(
    map: Record<T, ResolverKey>,
    data: Partial<Record<T, string>>
  ): ResolverRecord {
    return Object.fromEntries(
      Object.entries(map)
        .map(([name, key]) => data[name] != null && [key, data[name]])
        .filter(Boolean)
    );
  }

  @toggle('downloading')
  async getMedia(name: string) {
    const data = await this.canisterOf(name).resolver.getRecordValues(name);

    return (this.media = this.unpackData(MediaKey, data));
  }

  @toggle('uploading')
  async setMedia(name: string, platform: keyof DomainMedia, link: string) {
    const media = { [platform]: link } as Record<keyof DomainMedia, string>;

    await this.canisterOf(name).resolver.setRecordValues(
      name,
      this.packData(MediaKey, media)
    );
    return (this.media = { ...this.media, ...media });
  }

  @toggle<ResolverModel>('removing')
  async removeMedia(name: string, platform: keyof DomainMedia) {
    await this.canisterOf(name).resolver.setRecordValues(
      name,
      this.packData(MediaKey, { [platform]: '' })
    );
    const { [platform]: removed, ...data } = this.media;

    return (this.media = data);
  }

  @toggle('downloading')
  async getProfile(name: string) {
    const data = await this.canisterOf(name).resolver.getRecordValues(name);

    return (this.profile = this.unpackData(ProfileKey, data));
  }

  @toggle('uploading')
  async setProfile(name: string, data: DomainProfile) {
    await this.canisterOf(name).resolver.setRecordValues(
      name,
      this.packData(ProfileKey, data)
    );
    return (this.profile = { ...this.profile, ...data });
  }

  @toggle('downloading')
  async getReverse(name:string,principal: string) {
    return await this.canisterOf(name).resolver.getReverse(principal);
  }

  @toggle('downloading')
  async getCrypto(name: string) {
    const data = await this.canisterOf(name).resolver.getRecordValues(name);

    return (this.crypto = this.unpackData(CryptoKey, data));
  }

  @toggle('uploading')
  async setCrypto(name: string, data: DomainCrypto) {
    await this.canisterOf(name).resolver.setRecordValues(
      name,
      this.packData(CryptoKey, data)
    );
    return (this.crypto = { ...this.crypto, ...data });
  }

  @toggle<ResolverModel>('removing')
  async removeCrypto(name: string, key: CryptoName) {
    var empty: DomainCrypto = {};
    if (key === 'ICP') {
      empty = { principalICP: '', accountICP: '' };
      var { principalICP, accountICP, ...withOutICP } = this.crypto;
    } else {
      empty = { [key]: '' };
      var { [key]: removed, ...withOutOther } = this.crypto;
    }
    await this.canisterOf(name).resolver.setRecordValues(
      name,
      this.packData(CryptoKey, empty)
    );
    return (this.crypto = withOutICP || withOutOther);
  }

  @toggle('downloading')
  async getCanister(name: string) {
    const data = await this.canisterOf(name).resolver.getRecordValues(name);

    const { canister } = this.unpackData(
      { canister: RESOLVER_KEY_ICP_CANISTER },
      data
    );
    return (this.canister = canister);
  }

  @toggle('uploading')
  async setCanister(name: string, canister: string) {
    await this.canisterOf(name).resolver.setRecordValues(name, {
      [RESOLVER_KEY_ICP_CANISTER]: canister
    });
    return (this.canister = canister);
  }

  @toggle<ResolverModel>('removing')
  async removeCanister(name: string) {
    await this.canisterOf(name).resolver.setRecordValues(name, {
      [RESOLVER_KEY_ICP_CANISTER]: ''
    });
    return (this.canister = void 0);
  }

  @toggle('downloading')
  async getUrl(name: string) {
    const data = await this.canisterOf(name).resolver.getRecordValues(name);

    const { url } = this.unpackData(
      { url: RESOLVER_KEY_URL },
      data
    );
    return (this.url = url);
  }

  @toggle('uploading')
  async setUrl(name: string, url: string) {
    await this.canisterOf(name).resolver.setRecordValues(name, {
      [RESOLVER_KEY_URL]: url
    });
    return (this.url = url);
  }

  @toggle<ResolverModel>('removing')
  async removeUrl(name: string) {
    await this.canisterOf(name).resolver.setRecordValues(name, {
      [RESOLVER_KEY_URL]: ''
    });
    return (this.url = void 0);
  }

  @toggle('downloading')
  async getTextRecords(name: string) {
    const data = await this.canisterOf(name).resolver.getRecordValues(name);

    const textRecords = Object.fromEntries(
      Object.entries(data).filter(
        ([key, value]) => value && !ResolverKeys.includes(key as ResolverKey)
      )
    );
    return (this.textRecords = textRecords);
  }

  @toggle('uploading')
  async setTextRecords(name: string, data: Record<string, string>) {
    await this.canisterOf(name).resolver.setRecordValues(name, data);

    return (this.textRecords = { ...this.textRecords, ...data });
  }

  @toggle<ResolverModel>('removing')
  async removeTextRecord(name: string, key: string) {
    await this.canisterOf(name).resolver.setRecordValues(name, { [key]: '' });

    const { [key]: removed, ...data } = this.textRecords;

    return (this.textRecords = data);
  }
}

export default new ResolverModel();
