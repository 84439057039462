import { TypeKeys, Constructor } from 'web-utility';
import { observable } from 'mobx';

export abstract class BaseModel {
  @observable
  uploading = 0;

  @observable
  downloading = 0;
}

export function toggle<T extends BaseModel = BaseModel>(
  key: TypeKeys<T, boolean | number>
) {
  return (target: any, name: string, meta: PropertyDescriptor) => {
    const origin = meta.value as (...data: any[]) => Promise<any>;

    meta.value = async function (...data: any[]) {
      const isNumber = typeof this[key] === 'number';
      try {
        this[key] = isNumber ? this[key] + 1 : true;

        return await origin.apply(this, data);
      } finally {
        this[key] = isNumber ? this[key] - 1 : false;
      }
    };
  };
}

export abstract class ListModel<T> extends BaseModel {
  @observable
  pageIndex = 0;

  @observable
  pageSize = 10;

  @observable
  totalCount = 0;

  @observable
  noMore = false;

  @observable
  list: T[] = [];

  @observable
  current: T = {} as T;

  clear() {
    this.pageIndex = this.totalCount = 0;
    this.noMore = false;
    this.list = [];
    this.current = {} as T;
  }

  abstract getList(
    id?: any,
    pageIndex?: number,
    pageSize?: number,
    ...filter: any[]
  ): Promise<T[]>;

  async getOne(id: any): Promise<T> {
    return this.current;
  }
}

export abstract class BufferListModel<T> extends ListModel<T> {
  protected buffer?: BufferListModel<T>;
  protected buffering = false;
  protected needFlush = false;

  clear() {
    super.clear();

    this.buffer = void 0;
    this.buffering = this.needFlush = false;
  }

  protected abstract loadList(
    id?: any,
    pageIndex?: number,
    pageSize?: number,
    ...filter: any[]
  ): Promise<T[]>;

  protected flush() {
    this.list = [...this.list, ...this.buffer.list];
    this.pageIndex = this.buffer.pageIndex;
    this.noMore = this.buffer.noMore;

    this.buffer.clear();
  }

  protected endBuffer() {
    const buffer = this.buffer;

    return () => {
      this.buffering = false;

      if (this.needFlush && buffer === this.buffer) this.flush();
    };
  }

  @toggle('downloading')
  async getList(
    id: any,
    pageIndex = this.pageIndex + 1,
    pageSize = this.pageSize,
    ...filter: any[]
  ) {
    const Class = this.constructor as Constructor<BufferListModel<T>>;

    this.buffer ||= new Class();

    if (!this.buffering)
      if (!this.buffer.list[0]) {
        await this.loadList(id, pageIndex, pageSize, ...filter);

        if (!this.noMore) {
          this.buffering = true;
          this.buffer
            .loadList(id, pageIndex + 1, pageSize, ...filter)
            .then(this.endBuffer());
        }
      } else {
        this.flush();
      }
    else this.needFlush = true;

    return this.list;
  }
}
