import { Principal } from '@dfinity/principal';
import {
  TransferError,
  _SERVICE as LedgerActor,
  idlFactory as ledgerIDL
} from '@deland-labs/ic_ledger_client';

import { AuthBase } from '../AuthBase';
import { Balance, TokenInfo } from './DICP';
import {
  ICP_TOKEN_DECIMALS,
  ICP_TOKEN_ID,
  ICP_TOKEN_SYMBOL,
  LEDGER_ID
} from '../utils/config';
import { withLogging } from '../utils/errorLogger';
import { ErrorInfo, CanisterErrorCode } from '../utils/exception';
import {
  hexToBytes,
  toBigInt,
  parseToCommon,
  parseToOrigin,
  principalToAccountID
} from '../utils/helper/converter';

const ICP_INFO: TokenInfo = {
  id: ICP_TOKEN_ID,
  fee: {
    minimum: BigInt(10000),
    rate: 0,
    rateDecimals: 0
  },
  decimals: ICP_TOKEN_DECIMALS,
  name: ICP_TOKEN_SYMBOL,
  symbol: ICP_TOKEN_SYMBOL
};

export class Ledger extends AuthBase {
  /**
   * get token info
   */
  public async getTokenInfo() {
    return ICP_INFO;
  }

  /**
   * transfer icp
   */
  @withLogging
  public async transfer(to: string, amount: number) {
    const ledger = await this.createLedgerActor(false);
    const amountBN = toBigInt(parseToOrigin(amount, ICP_TOKEN_DECIMALS));
    const accountId = hexToBytes(to);
    const res = await ledger?.transfer({
      amount: { e8s: amountBN },
      memo: BigInt(0),
      to: accountId,
      fee: { e8s: BigInt(10_000) },
      created_at_time: [],
      from_subaccount: []
    });
    if ('Ok' in res) return res.Ok;

    throw this.parseTransferErrorToErrorInfo(res.Err);
  }

  /**
   * get ICP balance
   *
   * @param address wallet address of user
   */
  @withLogging
  public async balanceOf(address: string): Promise<Balance> {
    const ledger = await this.createLedgerActor(),
      account = hexToBytes(principalToAccountID(Principal.fromText(address)));
    const icp = await ledger.account_balance({ account });
    return {
      id: ICP_TOKEN_ID,
      balance: parseToCommon(icp.e8s, ICP_TOKEN_DECIMALS)
    };
  }

  /**
   * create ledger actor
   *
   * @param anonymous is anonymous access
   * @returns ledger actor
   */
  private createLedgerActor(anonymous = true) {
    return this.createActor<LedgerActor>(LEDGER_ID, ledgerIDL, anonymous);
  }

  private parseTransferErrorToErrorInfo(err: TransferError): ErrorInfo {
    switch (true) {
      case 'TxTooOld' in err:
        return {
          code: CanisterErrorCode.LedgerError,
          message: 'tx too old'
        };
      case 'BadFee' in err:
        return {
          code: CanisterErrorCode.LedgerError,
          message: 'bad fee'
        };
      case 'TxDuplicate' in err:
        return {
          code: CanisterErrorCode.LedgerError,
          message: 'tx duplicate'
        };
      case 'TxCreatedInFuture' in err:
        return {
          code: CanisterErrorCode.LedgerError,
          message: 'tx created in future'
        };
      case 'InsufficientFunds' in err:
        return {
          code: CanisterErrorCode.LedgerError,
          message: 'insufficient funds'
        };
      default:
        return {
          code: CanisterErrorCode.LedgerError,
          message: 'unknown error'
        };
    }
  }
}
