import * as style from './index.module.less';
import sessionStore from '../../model/Session';
import { FC } from 'react';
import classnames from 'classnames';
interface LoadingProps {
  width?: number;
  height?: number;
  className?: string;
}
export const Loading: FC<LoadingProps> = ({ width, height, className }) => {
  const { isDesktop } = sessionStore;
  const _width = isDesktop ? width : width / 2;
  const _height = isDesktop ? height : height / 2;
  return (
    <div className={classnames(style.spin, className)} style={{ width: _width, height: _height }} />
  );
};

export const Spinner: FC<LoadingProps> = ({ className }) => {
  
  return (
    <div className={classnames(style['lds-spinner'], className)}>
      {
        Array(12).fill(0).map((_, index) => {
          return <div key={index} />;
        })
      }
    </div>
  );
};
