import { FC } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { formToJSON } from 'web-utility';

import { OrderCardProps, OrderCard } from './OrderCard';

export interface EditLayoutProps extends OrderCardProps {
  onSave?: (data: Record<string, any>) => any;
}

export const EditLayout: FC<EditLayoutProps> = ({
  children,
  onSave,
  ...rest
}) => (
  <Row className="mx-0 g-5">
    <Col xs={12} sm={4} className="ps-0">
      <OrderCard {...rest} />
    </Col>
    <Form
      className="col-12 col-sm-8 round-card p-5"
      onSubmit={event => {
        event.preventDefault();

        onSave?.(formToJSON(event.currentTarget));
      }}
    >
      {children}
    </Form>
  </Row>
);
