{
    "appName":"icNaming",
    "base":{
        "names":"名称",
        "favorites":"收藏",
        "assets":"资产",
        "asset":"资产",
        "Logout":"退出",
        "Name":"名称",
        "registrant":"注册人",
        "controller":"控制人",
        "Register":"注册",
        "Details":"详细",
        "Expiration":"到期时间",
        "Resolver":"解析器",
        "Quota":"额度",
        "Buy":"购买",
        "Sell":"出售",
        "Price":"价格",
        "Amount":"数量",
        "Item":"项目",
        "Cost":"费用",
        "balance":"余额",
        "wrap":"兑换ICP",
        "unwrap":"兑换DICP",
        "Cancel":"取消",
        "Confirm":"确认",
        "Submit":"提交",
        "connect": "连接钱包",
        "BuyName":"购买名称",
        "BuyQuota":"购买额度",
        "SellName":"出售名称",
        "SellQuota":"出售额度",
        "Setting":"设置",
        "Set":"设置",
        "transfer":"转让",
        "chars":"位长度",
        "type":"类型",
        "Fixed":"固定",
        "Renew":"续费",
        "EnterAmount":"输入数量",
        "EnterPrice":"输入价格",
        "Save":"保存",
        "Delete":"删除",
        "NotSet":"未设置",
        "fee":"费用",
        "Tips":"提示",
        "Address":"地址",
        "to":"到",
        "from":"从"
    },
    "header": {
        "docs":"文档",
        "blindBox":"盲盒",
        "market":"交易市场"
    },
    "sessionBox":{
        "Select":"选择一个钱包",
        "title":"请选择一个钱包来连接到这个dapp：",
        "TryAgain":"再试试",
        "usedIIBefore":"我以前用过II？"
    },
    "home": {
        "slogan" : "去中心化的数字身份"
    },
    "domainSearch":{
        "search":"搜索",
        "placeholder":"搜索名称或地址"
    },
    "domain" : {
        "card" : {
            "Buy":"购买",
            "View":"详情",
            "Sell":"出售",
            "Claim":"申请",
            "HowToClaim":"如何申请",
            "registered":"这个名称已经被注册了。"
        },
        "CustomBadge":{
            "Available": "可注册",
            "Unavailable": "不能注册",
            "Reserved": "保留",
            "OnSale": "用户出售",
            "ForSaleByOwner":"所有者出售"
        },
        "sell":{
            "Confirmation":"确认",
            "ThinkAgain":"在想想",
            "ConfirmCancel":"确认取消",
            "AreYouSure":"你确定要取消以下物品的销售吗？"
        }
    },
    "registerForm":{
        "RegistrationPeriod":"注册期限",
        "years":"年",
        "year":"年",
        "RegistrationPrice":"注册价格",
        "Best":"推荐",
        "TotalPrice":"总价",
        "What":"什么是",
        "question":"3-5个字符的名称只能通过额度注册",
        "getQuota":"获取额度",
        "RenewPeriod":"续费期限",
        "questionTitle1":"什么是额度?",
        "questionTitle2":"如何获得额度?",
        "questionTitle3":"盲盒",
        "questionDesc1":"注册3-5个字符的名称时需要使用额度。不同字符的名称可以使用额度行注册，而且有不同类型的额度。例如，4位字符的额度不能注册3位字符的名称。",
        "questionDesc2":"购买盲盒将包含一个额度和一些DNS代币，获得不同的字符额度的概率是不同的。具体概率图显示在盲盒购买界面。你可以点击下面的链接来购买<0>盲盒</0>。 配额也可以从<1>市场</1>购买。市场上的配额是由其他用户出售的。",
        "questionDesc3":"盲盒是一种更好的分配名称的方式。以下链接是盲盒的购买页面和详细介绍。",
        "whatBlindBox":"什么是盲盒?",
        "whyBlindBox":"为什么是盲盒?"
    },
    "market":{
        "Filter":"筛选",
        "Items":"选项",
        "All":"全部",
        "Length":"长度",
        "SeeMySale":"我出售的",
        "Down":"关闭",
        "placeholder":"搜索名称",
        "OwnedBy":"所有者",
        "PurchaseConfirmation":"购买确认",
        "PurchaseTips":"你确定要购买以下物品吗？"
    },
    "account":{
        "Receive":"接收",
        "nnsTips":"如果你以前通过链接II钱包(NNS)购买过名称，<0>点击我，完成迁移</0>",
        "Expires":"到期",
        "ViewSelling":"查看销售",
        "MySale" : "正在出售",
        "placeholder":"搜索名称",
        "names":"名称",
        "Frozen":"冻结",
        "Available":"可用",
        "Action":"操作",
        "feeTips":"当用户转移ICP时,DICP转移费用(0.0001 ICP)由分类账收取。ICNaming在兑换DICP时不收取任何费用。",
        "SelectAssets":"选择资产",
        "DestinationAddress":"目标地址",
        "InsufficientBalance":"余额不足",
        "EnterAddressOrName":"输入地址或名称",
        "EnterAmount":"输入数量",
        "quotaDialogTitle":"什么是额度？",
        "quotaDialogDesc":"注册3-5个字符的名称时需要使用配额。不同字符的名称可以使用配额进行注册，而且有不同类型的配额。例如，3位数的配额可以用来注册大于或等于3位数的名称，但4位数的配额不能注册3位数的名称。"
    },
    "nameSet":{
        "menu":{
            "Profile":"简介",
            "SocialMedia":"社交媒体",
            "Crypto":"加密货币",
            "Canister":"容器",
            "Link":"链接",
            "TextRecord":"文本记录",
            "Transfer":"转移",
            "Renew":"续费",
            "Resolver":"解析器",
            "ReverseRecord":"反向解析"
        },
        "tryItNow":"试一试",
        "EditProfile":"编辑个人简介",
        "SetAvatar":"设置一个NFT/Gravatar作为我的头像",
        "DisplayName":"显示名称",
        "Email":"邮箱",
        "Location":"位置",
        "Description":"简介",
        "reclaim":"回收",
        "noOwnController":"该名称的控制人已被转让。要设置，请先收回",
        "SetYourReverseRecord":"设置你的反向记录",
        "reverseRecordTips":"你想让其他人通过你的主身份获得你的名称吗？在设置了你的主名称后，其他人可以通过主要ID来查询你的名称。",
        "yourDecentralized":"访问你的去中心化网站",
        "canisterTips1":"如果你有一个部署在IC上的去中心化网站，在这里设置网站的Canister ID，然后你就可以通过url来访问它 ",
        "canisterTips2":"是一个部署在IC上的去中心化网站的例子。",
        "hasUrlNotice1Title":"确认设置",
        "hasUrlNotice":"你已经设置了URL，如果你设置了canister id，访问<bikeUrl>{{bikeUrl}}</bikeUrl>将访问你在IC上部署的去中心化站点，而不是你设置的<url>{{url}}</url>",
        "yourWebsite":"通过网址链接到你的web2/web3网站：",
        "urlTips1":"你现在有网站吗？如果有，在这里设置网站的网址，然后你就可以通过网址来访问它。",
        "urlNotice":"(注意：你已经设置了容器的ID，它将访问容器的网站而不是你的网址。)",
        "hasBeenTransferred":"该名称的注册人已被转让。"
    },
    "dangerConfirm":{
        "tips":"你确定要转移到以下地址吗？",
        "transferRegistrant":{
            "title":"转让注册人",
            "note":"注意：转让注册人意味着你不再是该名称的所有者，一旦完成转让就不能取消。"
        },
        "transferController":{
            "title":"转让控制人",
            "note":"注意：转让控制人只意味着转让控制权，你仍然是名称的所有者，可以在任何时候通过[回收]拿回控制权"
        },
        "reclaim":{
            "title":"回收控制权"
        },
        "transferToken":{
            "title":"转移代币",
            "note":"注意：一旦转让完成，就不能取消。"
        }
    },
    "migration":{
        "desc":"ICNaming取消了对II的支持。如果你以前用II登录并购买了名称，你可以在这个页面完成迁移。",
        "MigrationSteps":"迁移步骤",
        "step1":"连接到II和您想迁移到的目标钱包",
        "step2":"选择要迁移的项目",
        "step3":"完成迁移步骤",
        "Connect":"连接钱包",
        "ConnectII":"连接II钱包",
        "ConnectTargetWallet":"连接目标钱包",
        "connectTips":"请先连接II钱包和目标钱包",
        "SelectItems":"选择要迁移的项目",
        "Migrate":"迁移"
    },
    "blindBox":{
        "ConnectWallet":"连接钱包",
        "YourBalance":"您的余额",
        "RewardTips":"购买盲盒有机会获得以下物品",
        "Reward":"奖励",
        "Chance":"概率",
        "Remark":"备注",
        "Reasons":"原因",
        "ReasonsTips":"引入一种新的方式来探索名称服务的可能性，这也贯彻了我们的核心信念：公平。不仅如此，盲盒的工作机制是不确定的，当用户参与进来时，会产生很多乐趣。此外，我们最初的概率设计为用户创造了更高的机会，让他们以最低的消费获得更多的配额和稀有的额度，在市场上价格是合理的。"
    },
    "errorInfo":{
        "inputError":"你的输入有误",
        "notFoundAddress":"没有找到这个加密地址"
    },
    "toast":{
        "SaleSuccessfully":"出售成功",
        "CancelledSuccessfully":"取消成功",
        "exceedPendingOrders":"不能超过待处理订单的数量",
        "WrapSuccessful":"兑换成功",
        "UnWrapSuccessful":"兑换成功",
        "SaveSuccessful":"保存成功！",
        "SetSuccessfully":"设置成功",
        "MigrateSuccess":"迁移成功"
    },
    "cheap":{
        "FindCheap":"在市场上发现一个便宜的额度，<0>可节约 {{cheapPrice}} DICP 进行注册</0>",
        "FindOne":"在市场上发现一个{{quota}}-chars的额度，<0>{{cheapPrice}} DICP 购买并注册</0>",
        "BuyQuotaToRegTitle":"购买额度并注册",
        "RegTitle":"注册",
        "you":"是你自己",
        "savings":"节约 {{cheap}} DICP"
    }
}