import { FC } from 'react';
import { Button } from 'react-bootstrap';
import { t } from 'i18next';

import { OrderCard } from './OrderCard';
import { parseToCommon } from '../../../model/utils/helper';
import { DICP } from '../../../model/canister/DICP';
import { MarketOrder } from '../../../model/Market';

export interface MarketCardOrder extends MarketOrder {
  onBuy: (order: any) => void;
  user: string;
}

export const MarketCard: FC<MarketCardOrder> = ({ orderId, orderType, price, user, onBuy }) => {
  const name = 'Name' in orderType ? orderType.Name : '',
    quota = 'Quota' in orderType ? orderType.Quota : undefined;
  const quotaLength = quota && Object.values(quota.quota_type)[0];

  return (
    <OrderCard
      name={name}
      quota={quotaLength}
      availableAmount={quota?.quota_left_diff}
    >
      <div className="d-flex justify-content-between mt-2 mb-3">
        <span>{t("base.Price")}</span>
        <span>{parseToCommon(price, DICP.tokenInfo?.decimals) + ''} DICP</span>
      </div>
      <Button
        className="w-100"
        variant="primary"
        onClick={() => onBuy({
          title: name ? t("base.BuyName"): t("base.BuyQuota"),
          order: orderId,
          price: price,
          name,
          quota: quotaLength,
          user,
          orderType
        })}
      >
        {t("base.Buy")}
      </Button>
    </OrderCard>
  );
};
