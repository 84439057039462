import { Principal } from '@dfinity/principal';
import { queryWithCache } from '@deland-labs/local-cache';

import { principalValidate } from './helper';

export function withLogging(
  target: any,
  key: string,
  meta: PropertyDescriptor
) {
  const origin = meta.value;

  meta.value = function (...data: any[]) {
    //console.debug(`${key} Input:`, data);

    const end = (value: any) => {
        //console.debug(`${key} Output:`, value);

        return value;
      },
      result = origin.apply(this, data);

    if (result instanceof Promise)
      return result.then(end, error => {
        console.warn(`Error in ${key}: ${error}`);

        throw error;
      });

    return end(result);
  };
}

Reflect.set(Principal.prototype, 'toJSON', function () {
  return this + '';
});

export function withCache<T>(
  key?: string | ((instance: T, ...funcArgs: any[]) => string),
  ttl?: number
) {
  return (target: T, name: string, meta: PropertyDescriptor) => {
    const origin = meta.value as (...data: any[]) => Promise<any>;

    meta.value = async function (...data: any[]) {
      const cacheKey = typeof key === 'function' ? key(this, ...data) : key;

      const result: any = await queryWithCache(
        origin.bind(this, ...data),
        cacheKey || name,
        ttl
      );
      for (const name in result)
        if (principalValidate(result[name]))
          result[name] = Principal.fromText(result[name]);

      return result;
    };
  };
}
