import { observable, computed, IReactionDisposer, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { FormEvent, PureComponent } from 'react';
import { Row, Col, Form, Image, Button } from 'react-bootstrap';
import { Icon, SpinnerButton, Select, Option } from 'idea-react';
import classNames from 'classnames';
import { formToJSON } from 'web-utility';
import { t } from "i18next";
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { LoadingFullCon } from './LoadingFullPage';
import { QuotaIcons } from './AssetBadge';
import { Dialog } from './Dialog';
import { DialogBuyQuotaToRegister } from './DialogBuyQuotaToRegister';

import {
  NumberLike,
  toBigInt,
  toBigNumber,
  parseToCommon,
  parseToOrigin
} from '../model/utils/helper';
import {
  BUYABLE_NAME_MIN_LENGTH,
  REGISTRABLE_NAME_MIN_LENGTH
} from '../model/utils/config';
import { DICP } from '../model/canister/DICP';
import marketStore from '../model/Market';
import { MarketOrdersQueryReq } from '../model/canister/Market';
import { RegisterNameByDICPReq } from '../model/canister/Registrar';
import sessionStore from '../model/Session';
import assetStore from '../model/Asset';
import domainStore from '../model/Domain';

export interface RegisterFormProps {
  name: string;
  onFinish?: () => any;
}

@observer
export class RegisterForm extends PureComponent<RegisterFormProps> {
  get nameLength() {
    return this.props.name.split('.')[0]?.length;
  }

  @computed
  get unitPrice() {
    const { priceInIcpE8s = 0n } = domainStore.currentPrice;

    return parseToCommon(priceInIcpE8s, DICP.tokenInfo?.decimals);
  }

  @observable
  years = 1;

  @observable
  quotaLength = 0;

  @observable
  quotaInstructions = false;

  @observable
  showDialogBuyQuota = false;

  @observable
  dialogBuyOrder;

  get quotaOnly() {
    return this.nameLength < BUYABLE_NAME_MIN_LENGTH;
  }

  @computed
  get price() {
    return this.unitPrice.times(this.years);
  }

  @computed
  get tCycles() {
    const { priceInXdrPermyriad = 0n } = domainStore.currentPrice;

    return toBigNumber(priceInXdrPermyriad).div(10000).toNumber();
  }

  @computed
  get lowDICP() {
    const { DICP: balance } = assetStore.balance;

    return !balance || balance.lt(this.price);
  }

  @computed
  get availableQuota() {
    const { nameLength } = this, { list } = assetStore;
    const availableQuota = list.filter(asset => asset.type === 'quota' && asset.availableAmount > 0 && asset.quota <= nameLength) || [];
    return availableQuota.length > 0 ? availableQuota.slice(-1)[0] : null;
  }

  @computed
  get cheaperOrder() {
    const { nameLength } = this, { list } = marketStore;
    if (list.length === 0) return null;
    const quotaPrice = parseToCommon(list[0].price, DICP.tokenInfo?.decimals);
    return {
      cheapPrice: nameLength > 5 ? this.unitPrice.minus(quotaPrice).toNumber() : quotaPrice.toNumber(),
      order: list[0]
    };
  }

  private disposers: IReactionDisposer[] = [];

  async componentDidMount() {

    await domainStore.getPrice(this.props.name);

    await this.checkMyQuotas()
    this.disposers.push(
      reaction(
        () => this.years,
        years => years > 1 && (this.quotaLength = 0)
      ),
      reaction(
        () => this.quotaLength,
        quota => quota > 0 && (this.years = 1)
      ),
      reaction(
        () => this.nameLength,
        () => this.getMarketData()
      ),
      reaction(
        () => this.availableQuota,
        () => this.quotaLength = this.availableQuota !== null ? (this.nameLength <= 7 ? this.availableQuota.quota : 7) : 0
      )
    );

    this.quotaLength = this.availableQuota !== null ? (this.nameLength <= 7 ? this.availableQuota.quota : 7) : 0;
    this.getMarketData()
  }

  componentWillUnmount() {
    for (const disposer of this.disposers) disposer();
  }

  getMarketData = async () => {
    const filter: MarketOrdersQueryReq = {
      includeName: false,
      includeQuota: true,
      price_min: 0,
      onlyMySale: false,
      lengthFilter: this.nameLength <= 7 ? this.nameLength : 7
    };
    marketStore.clear();
    await marketStore.getList(filter, 1);
  }

  checkMyQuotas = async () => {
    const { principal } = sessionStore.walletAuth || {};
    if (principal) await assetStore.getList(principal);
    if (!this.quotaOnly) return;

    const [fitQuota] = assetStore.list
      .map(
        ({ quota, availableAmount }) =>
          availableAmount && quota <= this.nameLength && quota
      )
      .filter(Boolean)
      .sort((x, y) => y - x);
    this.quotaLength = fitQuota;
  }

  handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const { name, onFinish } = this.props,
      { quotaLength } = this,
      { years } = formToJSON<Pick<RegisterNameByDICPReq, 'years'>>(
        event.currentTarget
      );
    await domainStore.register(
      quotaLength
        ? { name, years, quotaType: { LenGte: quotaLength } }
        : {
          name,
          years,
          dicpAmount: toBigInt(
            parseToOrigin(this.price, DICP.tokenInfo?.decimals)
          )
        }
    );
    onFinish?.();
  };

  renderCommonFields() {
    const { quotaOnly, unitPrice, years } = this;

    return (
      <>
        <Form.Group as={Row} className="align-items-center mt-4">
          <Form.Label column xs={4} className="fw-normal text-muted">
            {t("registerForm.RegistrationPeriod")}
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              type="number"
              name="years"
              className="text-center text-primary rounded-pill"
              min={1}
              max={10}
              value={years}
              disabled={quotaOnly}
              onChange={({ currentTarget }) =>
                (this.years = +currentTarget.value)
              }
            />
          </Col>
          <Col as="strong" xs={2}>
            {t("registerForm.years")}
          </Col>
        </Form.Group>
        {!quotaOnly && (
          <Form.Group as={Row} className="align-items-center mt-4">
            <Form.Label column xs={4} className="fw-normal text-muted">
              {t("registerForm.RegistrationPrice")}
            </Form.Label>
            <Col as="strong" xs={8}>
              {unitPrice + ''} DICP ≈ {this.tCycles + ''} T Cycles / {t("registerForm.year")}
            </Col>
          </Form.Group>
        )}
      </>
    );
  }

  renderQuotaIcon = (value: NumberLike, amount: NumberLike = 1) =>
    !value || value === '0' ? (
      'None'
    ) : (
      <span>
        <Image
          style={{ width: '1.5rem' }}
          src={QuotaIcons[+(value + '') - 1]}
        />{' '}
        x {amount + ''}
      </span>
    );

  renderQuota() {
    const { nameLength, quotaLength } = this,
      { list } = assetStore;

    return (
      <Select
        value={quotaLength && quotaLength + ''}
        valueRender={this.renderQuotaIcon}
        onChange={value => (this.quotaLength = +value)}
      >
        <Option value="0">None</Option>

        {QuotaIcons.slice(REGISTRABLE_NAME_MIN_LENGTH - 1, nameLength)
          .map((icon, index, { length }) => {
            const quotaLength = index + REGISTRABLE_NAME_MIN_LENGTH;
            const { availableAmount } =
              list.find(({ quota }) => quota === quotaLength) || {};

            return (
              !!availableAmount && (
                <Option
                  key={quotaLength}
                  className="w-100 d-flex justify-content-between align-items-center"
                  value={quotaLength + ''}
                >
                  {this.renderQuotaIcon(quotaLength, availableAmount)}

                  {(index + 1 === length || (quotaLength > 7 && index === 6)) && (
                    <span className="text-secondary">
                      <Icon name="hand-thumbs-up" />
                      {t("registerForm.Best")}
                    </span>
                  )}
                </Option>
              )
            );
          })
          .reverse()}
      </Select>
    );
  }

  iconButtonWantQuota() {
    return (
      <Button
        className={'border-0 bg-transparent p-0 shadow-none'}
        onClick={() => (this.quotaInstructions = true)}
      >
        <Icon
          name="question-circle"
          className="ms-1 fw-500 text-muted"
        />
      </Button>
    )
  }

  renderQuotaInstructionsDialog() {
    const { quotaInstructions } = this;
    return (
      <Dialog
        show={quotaInstructions}
        backdrop={true}
        closeButton={true}
        centered={true}
        title={t("base.Tips")}
        onHide={() => (this.quotaInstructions = false)}
      >
        <strong>1. {t("registerForm.questionTitle1")}</strong>
        <p className="fs-14" style={{ wordBreak: 'break-word' }}>
          {t("registerForm.questionDesc1")}
        </p>
        <strong>2. {t("registerForm.questionTitle2")}</strong>
        <p className="fs-14" style={{ wordBreak: 'break-word' }}>
          <Trans
            t={t}
            i18nKey="registerForm.questionDesc2"
            components={[
              <Link key={0} to="/blind-box">blind box</Link>,
              <Link key={1} to="/market">market</Link>
            ]}
          />
        </p>
        <strong>3. {t("registerForm.questionTitle3")}</strong>
        <p className="fs-14" style={{ wordBreak: 'break-word' }}>
          {t("registerForm.questionDesc3")} <Link to="/blind-box?_to=reward">{t("registerForm.whatBlindBox")}</Link>{' '}
          <Link to="/blind-box?_to=reasons">{t("registerForm.whyBlindBox")}</Link>
        </p>
      </Dialog>
    )
  }

  renderNewFields() {
    const { isDesktop } = sessionStore,
      { quotaLength, quotaOnly, price, nameLength } = this;

    return (
      <>
        <Form.Group as={Row} className="align-items-center mt-4">
          <Form.Label column xs={4} className="fw-normal text-muted">
            {t("base.Quota")}
            {this.iconButtonWantQuota()}
          </Form.Label>
          <Col xs={6}>{this.renderQuota()}</Col>
        </Form.Group>
        <Form.Group as={Row}>
          {(quotaLength === 0 && nameLength <= 5) && (
            <Form.Text className="text-end text-danger" as={Col} xs={isDesktop ? 10 : 12}>
              {t("registerForm.question")}
              {this.iconButtonWantQuota()}
            </Form.Text>
          )}
        </Form.Group>

        {!quotaOnly && (
          <Form.Group as={Row} className="align-items-center mt-4">
            <Form.Label column xs={4} className="fw-normal text-muted">
              {t("registerForm.TotalPrice")}
            </Form.Label>
            <Col as="strong" xs={8}>
              {quotaLength ? 0 : price + ''} DICP
            </Col>
          </Form.Group>
        )}
      </>
    );
  }

  renderCheaper() {
    const { nameLength, cheaperOrder, price } = this;
    const i18nKey = nameLength > 5 ? "cheap.FindCheap" : "cheap.FindOne";
    return (cheaperOrder !== null && cheaperOrder.cheapPrice > 0) && (
      <Row className="small mt-3">
        <Col md={{ offset: 4, span: 8 }} xs={{ offset: 0, span: 12 }}>
          <Trans
            t={t}
            i18nKey={i18nKey}
            values={{
              quota: nameLength,
              cheapPrice: cheaperOrder.cheapPrice
            }}
            components={[
              <Button variant="link"
                className="fs-14 p-0 shadow-none"
                onClick={() => {
                  this.showDialogBuyQuota = true;
                  this.dialogBuyOrder = {
                    name: this.props.name,
                    order: cheaperOrder.order.orderId + '',
                    price: cheaperOrder.order.price,
                    quota: nameLength <= 7 ? nameLength : 7,
                    user: cheaperOrder.order.user,
                    cheap: cheaperOrder.cheapPrice,
                    constPrice: nameLength > 5 && price.toNumber()
                  }
                }} />,
            ]}
          />
        </Col>
      </Row>)
  }

  render() {
    const {
      downloading: aLoading,
      balance: { ICP = '--', DICP = '--' }
    } = assetStore,
      { downloading: dLoading, uploading } = domainStore,
      { downloading: markLoading } = marketStore,
      { isDesktop } = sessionStore;
    const { availableQuota, quotaLength } = this;
    return (<>

      <Form
        className={classNames('round-card px-sm-5 py-4')}
        onSubmit={this.handleSubmit}
      >
        {(!!aLoading || !!dLoading || !!markLoading)
          ?
          <div style={{ height: "8rem" }}>
            <LoadingFullCon />
          </div>
          :
          <>
            {this.renderCommonFields()}
            {this.renderNewFields()}
            {(availableQuota === null || quotaLength === 0) && this.renderCheaper()}
            <Row className="align-items-center mt-4">
              <Col xs={{ offset: 3, span: 6 }} className="text-center">
                <SpinnerButton
                  className="rounded-pill px-5 text-nowrap fs-14"
                  size="lg"
                  variant="primary"
                  animation="border"
                  {...(this.quotaLength > 0
                    ? { type: 'submit' }
                    : this.quotaOnly
                      ? { href: '#/blind-box' }
                      : this.lowDICP
                        ? { href: '#/account/assets/wrap/ICP' }
                        : { type: 'submit' })}
                  loading={!!uploading}
                >
                  {this.quotaLength > 0
                    ? t("base.Submit")
                    : this.quotaOnly
                      ? t("registerForm.getQuota")
                      : this.lowDICP
                        ? t("base.wrap")
                        : t("base.Submit")}
                </SpinnerButton>
              </Col>
              <Col xs={3} className="text-end">
                <a
                  target="_blank"
                  href="https://lake-reward-33f.notion.site/FAQ-606cf23e394b44a2b3e89f8c0756473f"
                  className={classNames("text-decoration-none text-nowrap", !isDesktop ? 'fs-12' : 'fs-14')}
                >
                  {t("registerForm.What")} DICP?
                </a>
              </Col>
            </Row>
            <ul className="list-inline small mt-3 text-center text-muted">
              <li className="list-inline-item">ICP {t("base.balance")}: {ICP + ''}</li>
              <li className="list-inline-item">DICP {t("base.balance")}: {DICP + ''}</li>
            </ul>
          </>
        }
        {this.renderQuotaInstructionsDialog()}
      </Form>
      <DialogBuyQuotaToRegister
        show={this.showDialogBuyQuota}
        onHide={() => this.showDialogBuyQuota = false}
        {...this.dialogBuyOrder}
      />
    </>);
  }
}