import { random } from 'lodash';
import { observable, computed, action, reaction } from 'mobx';
import { Principal } from '@dfinity/principal';

import { createPager } from './utils/stream';
import {
  DomainSuffix,
  DOMAIN_SUBFIX,
  DICP_ID,
  DICP_MINTER_ID,
  ICP_REGISTRAR_ID,
  ICP_REGISTRY_ID,
  ICP_TOKEN_DECIMALS,
  IC_FAVORITES_ID,
  IC_REGISTRAR_ID,
  IC_REGISTRY_ID,
  MARKET_ID,
  REGISTRABLE_NAME_MIN_LENGTH
} from './utils/config';

import { DICP } from './canister/DICP';
import { Registry } from './canister/Registry';
import {
  NamePriceTable,
  NamePriceTableItem,
  NameRegistrarDetails,
  RegisterNameByDICPReq,
  RegisterNameByQuotaReq,
  Registrar
} from './canister/Registrar';
import { Favorites } from './canister/Favorites';
import { Market, OrderFilterType, OrderStatus } from './canister/Market';

import { service } from './service';
import { BufferListModel, toggle } from './Base';
import sessionStore from './Session';
import { DomainMeta, ResolverModel } from './Resolver';

export interface Domain extends Partial<DomainMeta> {
  price?: bigint;
  favorite?: boolean;
  orderId?: bigint;
}

type LuckyPageMeta = Record<
  'domainLength' | 'domainCount' | 'pageSize' | 'pageCount',
  number
>;
const createOwnNamePager = createPager<NameRegistrarDetails>;

export class RegistrarDomainModel extends BufferListModel<Domain> {
  protected registryIC = new Registry(IC_REGISTRY_ID);
  protected registryICP = new Registry(ICP_REGISTRY_ID);
  protected registrarIC = new Registrar(IC_REGISTRAR_ID);
  protected registrarICP = new Registrar(ICP_REGISTRAR_ID);
  protected canisterDICP = new DICP(DICP_ID, DICP_MINTER_ID);
  protected canisterFavorite = new Favorites(IC_FAVORITES_ID);
  protected canisterMarket = new Market(MARKET_ID, ICP_TOKEN_DECIMALS);

  protected price?: NamePriceTable;
  protected ownStream: Record<string, ReturnType<typeof createOwnNamePager>> =
    {};
  protected allSource: Record<number, AsyncGenerator<string>> = {};

  @observable
  lucking = false;

  @observable
  luckyIndex: LuckyPageMeta[] = [];

  @computed
  get maxLuckyLength() {
    return this.luckyIndex.at(-1)?.domainLength;
  }

  @observable
  currentPrice: Partial<NamePriceTableItem> = {};

  @observable
  liking = false;

  @observable
  favorites: string[] = [];

  @observable
  suffix: DomainSuffix = DOMAIN_SUBFIX;

  @observable
  saleCount = 0;

  constructor() {
    super();

    reaction(
      () => this.favorites,
      favorites => {
        this.list = this.list.map(({ name, ...rest }) => ({
          ...rest,
          name,
          favorite: favorites.includes(name)
        }));
      }
    );
  }

  clear() {
    super.clear();

    for (const { reset } of Object.values(this.ownStream)) reset();

    this.favorites = [];
    this.saleCount = 0;
  }

  @action
  switchSuffix(suffix: DomainSuffix) {
    this.clear();
    this.suffix = suffix;
  }

  protected async getLuckyIndex() {
    if (!this.luckyIndex[0]) {
      const { body } = await service.get<LuckyPageMeta[]>('/index.json');

      this.luckyIndex = body;
    }
    return this.luckyIndex;
  }

  protected createOwnStream(address: string) {
    return createOwnNamePager(
      async function* (this: RegistrarDomainModel) {
        if (this.suffix !== DOMAIN_SUBFIX) return;

        for (let i = 0; ; i++) {
          const items = await this.registrarIC.getRegistrantNamesWithPager(
            address,
            i * this.pageSize,
            this.pageSize
          );
          if (i === 0) this.totalCount += 0;

          if (!items[0]) break;

          yield* items;
        }
      }.bind(this),
      async function* (this: RegistrarDomainModel) {
        if (this.suffix !== 'icp') return;

        for (let i = 0; ; i++) {
          const items = await this.registrarICP.getRegistrantNamesWithPager(
            address,
            i * this.pageSize,
            this.pageSize
          );
          if (i === 0) this.totalCount += 0;

          if (!items[0]) break;

          yield* items;
        }
      }.bind(this),
      async function* (this: RegistrarDomainModel) {
        if (!sessionStore.walletAuth || this.suffix !== DOMAIN_SUBFIX) return;

        for (let i = 0; ; i++) {
          const list = await this.canisterMarket.getMyTradingOrdersWithPager(
            {
              onlyMySale: true,
              includeName: true,
              includeQuota: false,
              orderStatus: [OrderStatus.OnSale]
            },
            i * this.pageSize,
            this.pageSize
          );
          if (!list[0]) break;

          for (const { orderId, user, price, orderType } of list)
            if ('Name' in orderType)
              yield {
                name: orderType.Name,
                registrant: Principal.fromText(user),
                price,
                orderId
              };
        }
      }.bind(this)
    );
  }

  protected async loadList(
    address: string,
    pageIndex?: number,
    pageSize?: number
  ) {
    if (pageSize !== this.pageSize) pageIndex = 1;

    const stream = (this.ownStream[address] ||= this.createOwnStream(address));

    const domains = await stream.getPage(pageIndex, pageSize);

    this.pageSize = pageSize;

    if (!domains[0]) this.noMore = true;
    else {
      if (sessionStore.walletAuth)
        this.saleCount = await this.canisterMarket.getMyTradingOrdersCount(
          address,
          OrderFilterType.Name
        );
      this.totalCount += this.saleCount;
      this.pageIndex = pageIndex;

      if (domains.length < this.pageSize) this.noMore = true;

      this.list = [...this.list, ...domains];

      const list = await Promise.all(
        domains.map(async ({ name, ...meta }) => ({
          ...(await this.getOne(name)),
          ...meta
        }))
      );
      this.list = [...this.list.slice(0, -list.length), ...list];
    }
    return this.list;
  }

  @toggle('downloading')
  async getOne(name: string) {
    const isIC = name.endsWith(`.${DOMAIN_SUBFIX}`);

    return (this.current = await ResolverModel.nameMetaOf(
      isIC ? this.registryIC : this.registryICP,
      isIC ? this.registrarIC : this.registrarICP,
      name
    ));
  }

  async search(name: string) {
    name = `${name}.${DOMAIN_SUBFIX}`;

    this.list = [await this.getOne(name)];

    const [order] = await this.canisterMarket.getMarketOrdersWithPager(
      {
        includeName: true,
        includeQuota: false,
        nameFilter: name,
        onlyMySale: false
      },
      0,
      1
    );
    if (order) {
      await this.canisterDICP.initialize();

      const { orderId, price } = order;

      this.list = [{ ...this.list[0], orderId, price }];
    }
    if (sessionStore.walletAuth) this.getFavorites();

    return this.list;
  }

  protected createLuckyStream(nameLength: number) {
    const that = this;

    return (async function* () {
      const pages = await that.getLuckyIndex();

      while (true) {
        const { pageCount } = pages.find(
          ({ domainLength }) => domainLength === nameLength
        );
        const { body } = await service.get<string[]>(
          `${nameLength}/${random(pageCount)}.json`
        );
        yield* body;
      }
    })();
  }

  @toggle('downloading')
  async getLuckyList(
    start = REGISTRABLE_NAME_MIN_LENGTH,
    end?: number,
    count = this.pageSize
  ) {
    const pages = await this.getLuckyIndex();

    end ||= pages.at(-1).domainLength;

    this.clear();

    for (let i = 0; i < count; ) {
      const length = random(start, end);

      const generator = (this.allSource[length] ||=
        this.createLuckyStream(length));

      const { done, value } = await generator.next();

      if (done) continue;

      const name = `${value}.${DOMAIN_SUBFIX}`;

      this.list = [...this.list, { name }];

      this.getOne(name);
      i++;
    }
    if (sessionStore.walletAuth) this.getFavorites();

    return this.list;
  }

  protected async getFavorites() {
    return (this.favorites = await this.canisterFavorite.getFavorites());
  }

  @toggle('downloading')
  async getFavoriteList() {
    const list: Domain[] = [],
      names = await this.getFavorites();

    for (const name of names) list.push(await this.getOne(name));

    this.list = list;

    this.favorites = names;

    return this.list;
  }

  @toggle<RegistrarDomainModel>('liking')
  async addFavorite(name: string) {
    const { favorites } = this;

    try {
      this.favorites = [...favorites, name];

      await this.canisterFavorite.addFavorite(name);

      return this.favorites;
    } catch (error) {
      this.favorites = favorites.filter(n => n !== name);

      throw error;
    }
  }

  @toggle<RegistrarDomainModel>('liking')
  async removeFavorite(name: string) {
    const { favorites } = this;

    try {
      this.favorites = favorites.filter(n => n !== name);

      await this.canisterFavorite.removeFavorite(name);

      return this.favorites;
    } catch (error) {
      this.favorites = favorites;

      throw error;
    }
  }

  @toggle('downloading')
  async getPrice(name: string) {
    await this.canisterDICP.initialize();

    const [{ length }] = name.split('.'),
      { items } = (this.price ||= await this.registrarIC.getPriceTable());

    return (this.currentPrice =
      items.find(({ nameLen }) => nameLen === length) || items.at(-1));
  }

  @toggle('uploading')
  async register(
    request: RegisterNameByDICPReq | RegisterNameByQuotaReq,
    renew = false
  ) {
    if ('dicpAmount' in request) {
      await this.canisterDICP.initialize();
      await this.canisterDICP.approve(
        IC_REGISTRAR_ID,
        Number(request.dicpAmount)
      );
    }
    return renew
      ? this.registrarIC.renewName(request as RegisterNameByDICPReq)
      : this.registrarIC.registerName(request);
  }
}

export default new RegistrarDomainModel();
