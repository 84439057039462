import { FC } from 'react';
import { IdeaDialog, IdeaDialogProps } from 'idea-react';

import { parseToCommon } from '../../model/utils/helper';
import { DICP } from '../../model/canister/DICP';
import { t } from 'i18next';

export interface CancelSaleDialogProps extends Omit<IdeaDialogProps, 'title'> {
  name: string;
  price: bigint;
  centered?: boolean;
  backdrop?: boolean;
}

export const CancelSaleDialog: FC<CancelSaleDialogProps> = ({
  name,
  price,
  ...rest
}) => (
  <IdeaDialog
    title={t("domain.sell.Confirmation")}
    cancelText={t("domain.sell.ThinkAgain")}
    confirmText={t("domain.sell.ConfirmCancel")}
    {...rest}
  >
    <p className="text-muted">
      {t("domain.sell.AreYouSure")}
    </p>
    <div className="d-flex justify-content-between mt-3">
      <span className="text-primary">{name}</span>
      {parseToCommon(price, DICP.tokenInfo?.decimals) + ''} DICP
    </div>
  </IdeaDialog>
);
