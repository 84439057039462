import { observable, computed, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { FormEvent, PureComponent } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { SpinnerButton } from 'idea-react';
import { t } from 'i18next';

import { NumberInput } from './NumberInput';
import { Dialog } from './Dialog';
import { SessionBox } from './SessionBox';
import { AssetBadge } from './AssetBadge';
import { EditLayout } from './Exchange/EditLayout';
import { CancelSaleDialog } from './Exchange/CancelSaleDialog';
import { toastStore } from './Toast';

import {
    englishDate,
    formatAddress,
    parseToCommon
} from '../model/utils/helper';
import { DICP } from '../model/canister/DICP';
import sessionStore from '../model/Session';
import resolverStore from '../model/Resolver';
import marketStore from '../model/Market';
import assetStore from '../model/Asset';
import classNames from 'classnames';
import { OrderType } from '@icnaming/exchange_client';

export type DialogBuyProps = {
    show: boolean;
    title: string;
    onHide: () => void;
    reLoadMarket?: () => void;
    isMarket?: boolean;
    price?: bigint;
    orderType?: OrderType;
    name?: string;
    quota?: number;
    user: string;
    order: string;
    reLoadNames?: () => void;
}

@observer
export class DialogBuy extends PureComponent<DialogBuyProps> {
    @observable
    cancelConfirming = false;

    @observable
    buyConfirming = false;

    @computed
    get isOwner() {
        const { user } = this.props;
        return user === sessionStore.walletAuth?.principal;
    }

    @computed
    get principal() {
        return sessionStore.walletAuth?.principal;
    }


    get fullName() {
        const { name, quota } = this.props;

        return name || `${quota}-chars ${t("base.Quota")}`;
    }

    @observable
    amount?: number;

    @computed
    get cost() {
        const { amount = 1 } = this,
            { price = 0n } = this.props;

        return price * BigInt(Math.round(amount));
    }

    @computed
    get lowDICP() {
        const { cost } = this;
        const { DICP: balance } = assetStore.balance;

        return (
            !balance ||
            balance.toNumber() <
            parseToCommon(cost, DICP.tokenInfo?.decimals).toNumber()
        );
    }
    async componentDidMount() {
        const { principal } = this;
        const { name, order } = this.props;
        if (principal) await assetStore.getBalance(principal);
        if (name) resolverStore.getMeta(name);

        if (order) marketStore.getOne(order);
        reaction(
            () => this.props.order,
            async order => order && (marketStore.getOne(order))
        );
        reaction(
            () => this.props.name,
            async name => name && (resolverStore.getMeta(name))
        );
        reaction(
            () => this.principal,
            async principal => principal && (await assetStore.getBalance(principal))
        );
    }

    confirm = () => {
        const { orderType } = this.props;
        this.isOwner
            ? (this.cancelConfirming = true)
            : orderType && 'Quota' in orderType
                ? orderType.Quota.quota_left_diff >= this.amount
                    ? (this.buyConfirming = true)
                    : toastStore.warning(t("toast.exceedPendingOrders"))
                : (this.buyConfirming = true);
    };

    save = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { amount } = this,
            { order } = this.props;

        await marketStore.buy(order, amount);

        location.hash = `/account/${amount ? 'assets' : 'names'}`;

    };

    cancel = async () => {

        const { name, order, isMarket, reLoadNames, onHide, reLoadMarket } = this.props;

        await marketStore.cancel(order);

        toastStore.success(t('toast.CancelledSuccessfully'));

        this.cancelConfirming = false;

        if (isMarket) reLoadMarket();
        if (!isMarket && name) reLoadNames();
        onHide();
    };

    renderPrice() {
        const { price } = marketStore.current;
        return (
            <Row as="li" className="my-3">
                <Col className="text-muted">{t("base.Price")}</Col>
                <Col className="text-end">
                    <AssetBadge type="coin" name="DICP" />
                    <span className="fw-500">
                        {price ? parseToCommon(price, DICP.tokenInfo?.decimals) + '' : '--'}
                    </span>
                </Col>
            </Row>
        );
    }

    renderNameMeta() {
        const { expiredAt = 0n } = resolverStore.meta || {};
        return (
            <>
                <Row as="li" className="my-3">
                    <Col className="text-muted">{t("base.Expiration")}</Col>
                    <Col className="text-end">
                        {expiredAt ? englishDate.format(+(expiredAt + '')) : '--'}
                    </Col>
                </Row>
                {this.renderPrice()}
            </>
        );
    }

    renderQuotaMeta() {
        const { amount, isOwner } = this,
            { orderType } = this.props;
        return (
            <>
                <Row as="li" className="my-3">
                    <Col className="text-muted">{t("base.Amount")}</Col>
                    <Col className="text-end fw-500">
                        {orderType &&
                            'Quota' in orderType &&
                            orderType.Quota.quota_left_diff}
                    </Col>
                </Row>
                {this.renderPrice()}
                {!isOwner && (
                    <Form.Group as={Row}>
                        <Form.Label className="text-muted fw-normal" column>
                        {t("base.Buy")}{t("base.Amount")}
                        </Form.Label>
                        <Col>
                            <NumberInput
                                placeholder={t("base.EnterAmount")}
                                name="amount"
                                required
                                min={1}
                                value={amount}
                                onChange={value => (this.amount = value)}
                            />
                        </Col>
                    </Form.Group>
                )}
            </>
        );
    }

    renderConfirm() {
        const { buyConfirming, fullName, amount, cost } = this,
            { uploading } = marketStore;

        return (
            <Dialog
                title={t("market.PurchaseConfirmation")}
                closeButton={false}
                backdrop="static"
                centered={true}
                show={buyConfirming}
            >
                <strong className="text-muted">
                    {t("market.PurchaseTips")}
                </strong>

                <ul className="list-unstyled my-3">
                    <Row as="li">
                        <Col sm={4} className="text-muted my-2">
                            {t("base.Item")}
                        </Col>
                        <Col sm={8} className="text-end" as="strong">
                            {fullName}
                        </Col>
                    </Row>
                    {amount && (
                        <Row as="li">
                            <Col sm={4} className="text-muted my-2">
                                {t("base.Amount")}
                            </Col>
                            <Col sm={8} className="text-end text-break" as="strong">
                                {amount}
                            </Col>
                        </Row>
                    )}
                    <Row as="li">
                        <Col sm={4} className="text-muted my-2">
                            {t("base.Cost")}
                        </Col>
                        <Col sm={8} className="text-end text-break" as="strong">
                            {parseToCommon(cost, DICP.tokenInfo?.decimals) + ''} DICP
                        </Col>
                    </Row>
                </ul>

                <Form
                    className="my-3 d-flex justify-content-around"
                    onSubmit={this.save}
                    onReset={() => (this.buyConfirming = false)}
                >
                    <Button
                        type="reset"
                        variant="outline-secondary"
                        disabled={!!uploading}
                    >
                        {t("base.Cancel")}
                    </Button>
                    <SpinnerButton
                        className="text-capitalize"
                        type="submit"
                        animation="border"
                        loading={!!uploading}
                    >
                        {t("base.Confirm")}
                    </SpinnerButton>
                </Form>
            </Dialog>
        );
    }

    render() {
        const { user, orderType, show, title, name, quota, onHide } = this.props,
            { downloading, uploading } = marketStore,
            { price } = marketStore.current;
        const { cancelConfirming, isOwner, fullName, principal } = this;
        const { isDesktop } = sessionStore,
            {
                downloading: aLoading,
                balance: { DICP = '--' }
            } = assetStore;
        return (
            <Dialog title={title}
                backdrop={true}
                closeButton={true}
                show={show}
                size="lg"
                centered={true}
                onHide={() => onHide()}
            >
                <div className="pt-4">
                    <EditLayout
                        name={name}
                        quota={+quota}
                        availableAmount={
                            orderType && 'Quota' in orderType && orderType.Quota.quota_left_diff
                        }
                        onSave={this.confirm}
                    >
                        <h1 className="h3 text-break">{fullName}</h1>

                        {quota && <p className="text-muted">{t("market.OwnedBy")} {formatAddress(user)}</p>}

                        <ul className="list-unstyled my-4">
                            {name ? this.renderNameMeta() : this.renderQuotaMeta()}
                        </ul>
                        <Row>
                            <Col sm={6} className="text-muted my-2">
                                <SessionBox className="flex-fill">
                                    <SpinnerButton
                                        className="w-100"
                                        variant={
                                            isOwner
                                                ? 'danger'
                                                : this.lowDICP
                                                    ? 'outline-primary'
                                                    : 'primary'
                                        }
                                        {...(isOwner
                                            ? { type: 'submit' }
                                            : this.lowDICP
                                                ? { href: '#/account/assets/wrap/ICP' }
                                                : { type: 'submit' })}
                                        animation="border"
                                        loading={!!uploading}
                                    >
                                        {!principal
                                            ? t('base.connect')
                                            : isOwner
                                                ? t("base.Cancel")
                                                : this.lowDICP
                                                    ? t("base.wrap")
                                                    : t("base.Buy")}
                                    </SpinnerButton>
                                </SessionBox>
                            </Col>
                            <Col>
                                {principal && (
                                    <div
                                        className={classNames(
                                            'd-flex',
                                            isDesktop
                                                ? 'flex-column align-items-end'
                                                : 'flex-row justify-content-center'
                                        )}
                                    >
                                        <span className="text-muted">{t("base.balance")}</span>
                                        <strong className="ms-2">{DICP + ''} DICP</strong>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </EditLayout>
                </div>

                <CancelSaleDialog
                    backdrop={false}
                    centered={true}
                    name={fullName}
                    price={price}
                    show={cancelConfirming}
                    loading={!!uploading}
                    onCancel={() => (this.cancelConfirming = false)}
                    onConfirm={this.cancel}
                />
                {this.renderConfirm()}
            </Dialog>
        );
    }
}