import classNames from 'classnames';
import { HTMLAttributes, InputHTMLAttributes, FC } from 'react';
import { Form, FormControlProps, Button, InputGroup } from 'react-bootstrap';

import * as styles from './index.module.less';

export interface NumberInputProps
  extends Omit<
      InputHTMLAttributes<HTMLInputElement>,
      keyof HTMLAttributes<HTMLInputElement> | 'type' | 'value' | 'size'
    >,
    Omit<FormControlProps, 'type' | 'onChange'> {
  bordered?: boolean;
  onChange?: (value?: number) => any;
}

export const NumberInput: FC<NumberInputProps> = ({
  className,
  style,
  bordered = true,
  max,
  onChange,
  ...props
}: NumberInputProps) => (
  <InputGroup
    className={classNames(!bordered && styles.borderless, className)}
    style={style}
  >
    <Form.Control
      {...props}
      className={classNames(max && 'border-end-0')}
      type="number"
      max={max}
      onChange={({ currentTarget: { value } }) =>
        onChange?.(value ? +value : undefined)
      }
    />
    {max != null && (
      <Button
        className="border border-start-0 bg-white fw-500 text-primary"
        onClick={() => onChange?.(+max)}
      >
        MAX
      </Button>
    )}
  </InputGroup>
);
