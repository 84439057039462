import { Image } from 'react-bootstrap';
import { Icon } from 'idea-react';

import { Asset } from '../model/Asset';
import { CryptoName } from '../model/Crypto';

export type AssetBadgeCryptoName = CryptoName | "DICP" | "DNS";

export const CoinIcons: Record<AssetBadgeCryptoName, string> = {
  DICP:new URL('../image/coin/DICP.png', import.meta.url) + '',
  DNS:new URL('../image/coin/DNS.png', import.meta.url) + '',
  ICP:new URL('../image/coin/ICP.png', import.meta.url) + '',
  BTC:new URL('../image/coin/BTC.png', import.meta.url) + '',
  ETH:new URL('../image/coin/ETH.png', import.meta.url) + '',
  BNB:new URL('../image/coin/BNB.png', import.meta.url) + '',
  XRP:new URL('../image/coin/XRP.png', import.meta.url) + '',
  ADA:new URL('../image/coin/ADA.png', import.meta.url) + '',
  SOL:new URL('../image/coin/SOL.png', import.meta.url) + '',
  DOGE:new URL('../image/coin/DOGE.png', import.meta.url) + '',
  DOT:new URL('../image/coin/DOT.png', import.meta.url) + '',
  MATIC:new URL('../image/coin/MATIC.png', import.meta.url) + '',
  TRX:new URL('../image/coin/TRX.png', import.meta.url) + '',
  AVAX:new URL('../image/coin/AVAX.png', import.meta.url) + '',
  ETC:new URL('../image/coin/ETC.png', import.meta.url) + '',
  LTC:new URL('../image/coin/LTC.png', import.meta.url) + '',
  XLM:new URL('../image/coin/XLM.png', import.meta.url) + '',
  XMR:new URL('../image/coin/XMR.png', import.meta.url) + '',
  ALGO:new URL('../image/coin/ALGO.png', import.meta.url) + '',
  BCH:new URL('../image/coin/BCH.png', import.meta.url) + '',
  VET:new URL('../image/coin/VET.png', import.meta.url) + '',
  XTZ:new URL('../image/coin/XTZ.png', import.meta.url) + '',
  AAVE:new URL('../image/coin/AAVE.png', import.meta.url) + '',
  BSV:new URL('../image/coin/BSV.png', import.meta.url) + '',
  ZEC:new URL('../image/coin/ZEC.png', import.meta.url) + '',
  BTT:new URL('../image/coin/BTT.png', import.meta.url) + '',
  NEO:new URL('../image/coin/NEO.png', import.meta.url) + '',
  DASH:new URL('../image/coin/DASH.png', import.meta.url) + '',
  WAVES:new URL('../image/coin/WAVES.png', import.meta.url) + '',
  BTG:new URL('../image/coin/BTG.png', import.meta.url) + '',
  XEM:new URL('../image/coin/XEM.png', import.meta.url) + '',
  DCR:new URL('../image/coin/DCR.png', import.meta.url) + '',
  GNO:new URL('../image/coin/GNO.png', import.meta.url) + ''
};

export const QuotaIcons = [
  new URL('../image/quota/Quota-1.png', import.meta.url) + '',
  new URL('../image/quota/Quota-2.png', import.meta.url) + '',
  new URL('../image/quota/Quota-3.png', import.meta.url) + '',
  new URL('../image/quota/Quota-4.png', import.meta.url) + '',
  new URL('../image/quota/Quota-5.png', import.meta.url) + '',
  new URL('../image/quota/Quota-6.png', import.meta.url) + '',
  new URL('../image/quota/Quota-7.png', import.meta.url) + ''
];

export type AssetBadgeProps = Pick<Asset, 'type' | 'name' | 'quota'>;

export const AssetBadge = ({
  type,
  quota,
  name = type === 'quota' ? `Quota` : ''
}: AssetBadgeProps) => (
  <>
    {type === 'coin' ? (
      <Image style={{ width: '1.5rem' }} src={CoinIcons[name]} />
    ) : type === 'renewal' ? (
      <Icon
        className="text-warning align-middle"
        size={1.5}
        name="cash-stack"
      />
    ) : type === 'quota' ? (
      <Image style={{ width: '1.5rem' }} src={QuotaIcons[quota - 1]} />
    ) : (
      <Icon
        className="text-secondary align-middle"
        size={1.5}
        name="ticket-perforated"
      />
    )}
    <span className="mx-2">{name}</span>
  </>
);
