{
    "appName":"icNaming",
    "base":{
        "names":"이름",
        "favorites":"즐겨찾기",
        "assets":"자산",
        "asset":"유산",
        "Logout":"연결 끊기",
        "Name":"이름",
        "registrant":"등록자",
        "controller":"제어 장치",
        "Register":"등록하다",
        "Details":"세부",
        "Expiration":"만료일",
        "Resolver":"해결사",
        "Quota":"할당량",
        "Buy":"구입",
        "Sell":"팔다",
        "Price":"가격",
        "Amount":"양",
        "Item":"안건",
        "Cost":"비용",
        "balance":"균형",
        "wrap":"랩 ICP",
        "unwrap":"DICP 풀기",
        "Cancel":"취소",
        "Confirm":"확인하다",
        "Submit":"제출하다",
        "connect": "지갑 연결",
        "BuyName":"구매 이름",
        "BuyQuota":"구매 할당량",
        "SellName":"판매 이름",
        "SellQuota":"판매 할당량",
        "Setting":"환경",
        "Set":"세트",
        "transfer":"옮기다",
        "chars":"-문자",
        "type":"유형",
        "Fixed":"결정된",
        "Renew":"고쳐 쓰다",
        "EnterAmount":"금액을 입력하세요",
        "EnterPrice":"가격 입력",
        "Save":"구하다",
        "Delete":"삭제",
        "NotSet":"설정되지 않음",
        "fee":"요금",
        "Tips":"팁",
        "Address":"주소",
        "to":"결과",
        "from":"시작"
    },
    "header": {
        "docs":"문서",
        "blindBox":"블라인드 박스",
        "market":"시장"
    },
    "sessionBox":{
        "Select":"지갑 선택",
        "title":"이 dapp에 연결할 지갑을 선택하세요:",
        "TryAgain":"다시 시도하십시오",
        "usedIIBefore":"내가 전에 II를 사용?"
    },
    "home": {
        "slogan" : "분산된 디지털 ID"
    },
    "domainSearch":{
        "search":"검색",
        "placeholder":"이름 또는 주소 검색"
    },
    "domain" : {
        "card" : {
            "Buy":"구입",
            "View":"보다",
            "Sell":"팔다",
            "Claim":"주장하다",
            "HowToClaim":"청구 방법",
            "registered":"이미 등록된 이름입니다."
        },
        "CustomBadge":{
            "Available": "사용 가능",
            "Unavailable": "이용할 수 없는",
            "Reserved": "예약된",
            "OnSale": "소유자별 판매",
            "ForSaleByOwner":"소유자별 판매"
        },
        "sell":{
            "Confirmation":"확정",
            "ThinkAgain":"다시 생각 해봐",
            "ConfirmCancel":"취소 확인",
            "AreYouSure":"다음 항목의 판매를 취소하시겠습니까?"
        }
    },
    "registerForm":{
        "RegistrationPeriod":"등록기간",
        "years":"연령",
        "year":"년도",
        "RegistrationPrice":"등록 가격",
        "Best":"최고",
        "TotalPrice":"총 가격",
        "What":"뭐야",
        "question":"3-5자 이름은 구매 할당량을 통해서만 등록할 수 있습니다.",
        "getQuota":"할당량 가져오기",
        "RenewPeriod":"갱신 기간",
        "questionTitle1":"할당량이 무엇입니까?",
        "questionTitle2":"할당량을 얻는 방법?",
        "questionTitle3":"블라인드 박스",
        "questionDesc1":"3~5자리 이름을 등록할 때 할당량을 사용해야 합니다. 할당량을 사용하여 등록할 수 있는 다른 문자의 이름과 할당량 유형이 있습니다. 예를 들어 4자 할당량은 3자 이름을 등록할 수 없습니다.",
        "questionDesc2":"블라인드 박스를 구입하면 할당량과 일부 DNS 토큰이 포함되며 다른 문자 할당량을 얻을 확률은 다양합니다. 블라인드 박스 구매 인터페이스에 특정 확률 차트가 표시됩니다. 다음 링크를 클릭하시면 구매하실 수 있습니다 <0>블라인드 박스</0>. 할당량은 <1>시장</1> 에서도 구입할 수 있습니다. 시장의 할당량은 다른 사용자가 판매합니다.",
        "questionDesc3":"블라인드 박스는 이름을 배포하는 더 좋은 방법입니다. 다음 링크는 블라인드 박스에 대한 구매 페이지 및 자세한 소개입니다.",
        "whatBlindBox":"블라인드 박스가 뭔가요?",
        "whyBlindBox":"블라인드 박스는 왜?"
    },
    "market":{
        "Filter":"필터",
        "Items":"아이템",
        "All":"모두",
        "Length":"길이",
        "SeeMySale":"내 판매 보기",
        "Down":"폐쇄",
        "placeholder":"이름 검색",
        "OwnedBy":"에게 소유 된",
        "PurchaseConfirmation":"구매 확인",
        "PurchaseTips":"다음 항목을 구매하시겠습니까?"
    },
    "account":{
        "Receive":"받다",
        "nnsTips":"이전에 II 지갑(NNS)을 연결하여 이름을 구매한 경우,<0>마이그레이션을 완료하려면 나를 클릭하십시오.</0>",
        "Expires":"만료",
        "ViewSelling":"판매 보기",
        "MySale" : "내 판매",
        "placeholder":"이름 검색",
        "names":"이름",
        "Frozen":"겨울 왕국",
        "Available":"사용 가능",
        "Action":"동작",
        "feeTips":"DICP 랩 수수료(0.0001 ICP)는 사용자가 ICP를 양도할 때 원장에서 청구합니다. IC Naming은 DICP를 포장/해제할 때 수수료를 부과하지 않습니다.",
        "SelectAssets":"자산 선택",
        "DestinationAddress":"목적지 주소",
        "InsufficientBalance":"잔액 불충분",
        "EnterAddressOrName":"주소/이름 입력",
        "EnterAmount":"금액을 입력하세요",
        "quotaDialogTitle":"할당량이 무엇인가요?",
        "quotaDialogDesc":"3~5자리 이름을 등록할 때 할당량을 사용해야 합니다. 할당량을 사용하여 등록할 수 있는 다른 문자의 이름과 할당량 유형이 있습니다. 예를 들어, 3자 할당량은 3자리 이상의 이름을 등록하는 데 사용할 수 있지만 4자 할당량은 3자 이름을 등록할 수 없습니다."
    },
    "nameSet":{
        "menu":{
            "Profile":"프로필",
            "SocialMedia":"소셜 미디어",
            "Crypto":"암호화",
            "Canister":"깡통",
            "Link":"링크",
            "TextRecord":"텍스트 기록",
            "Transfer":"옮기다",
            "Renew":"고쳐 쓰다",
            "Resolver":"해결하다",
            "ReverseRecord":"역 구문 분석"
        },
        "tryItNow":"지금 시도",
        "EditProfile":"프로필 편집",
        "SetAvatar":"NFT/Gravatar를 내 아바타로 설정",
        "DisplayName":"이름 표시하기",
        "Email":"이메일",
        "Location":"위치",
        "Description":"설명",
        "reclaim":"개심",
        "noOwnController":"이 이름의 컨트롤러가 이전되었습니다. 설정하려면 먼저 회수하세요.",
        "SetYourReverseRecord":"리버스 레코드 설정",
        "reverseRecordTips":"다른 사람들이 귀하의 주 ID를 통해 귀하의 이름을 알길 원하십니까? 기본 이름을 설정한 후 다른 사용자가 보안 주체 ID를 사용하여 이름을 확인할 수 있습니다.",
        "yourDecentralized":"분산 웹사이트에 액세스",
        "canisterTips1":"IC에 배포된 분산 웹사이트가 있습니까? 있는 경우 여기에 웹 사이트의 캐니스터 ID를 설정하면 URL로 액세스할 수 있습니다.",
        "canisterTips2":"IC에 배포된 분산 웹사이트의 예입니다.",
        "hasUrlNotice1Title":"설정 확인",
        "hasUrlNotice":"URL을 설정했습니다. 캐니스터 ID를 설정한 경우 <bikeUrl>{{bikeUrl}}</bikeUrl> 를 방문하면 설정한 <url>{{url}}</url> 대신 IC에 배포한 분산 사이트를 방문합니다.",
        "yourWebsite":"URL을 통해 web2/web3 웹사이트에 링크:",
        "urlTips1":"지금 웹사이트가 있습니까? 있는 경우 여기에 웹 사이트의 URL을 설정하면 URL로 액세스할 수 있습니다.",
        "urlNotice":"(참고: 캐니스터 ID를 설정하면 URL 대신 캐니스터 웹사이트에 액세스합니다)",
        "hasBeenTransferred":"명의 등록자가 이전되었습니다." 
    },
    "dangerConfirm":{
        "tips":"다음 주소로 이전하시겠습니까?",
        "transferRegistrant":{
            "title":"이전 등록자",
            "note":"참고: 이전 등록자는 귀하가 더 이상 이름의 소유자가 아니며 이전이 완료되면 취소할 수 없음을 의미합니다."
        },
        "transferController":{
            "title":"전송 컨트롤러",
            "note":"참고: 컨트롤러 양도는 제어권 양도를 의미하며, 여전히 이름의 소유자이며 [회수]를 통해 언제든지 제어권을 되찾을 수 있습니다."
        },
        "reclaim":{
            "title":"회수 컨트롤러"
        },
        "transferToken":{
            "title":"토큰 전송",
            "note":"참고: 전송이 완료되면 취소할 수 없습니다."
        }
    },
    "migration":{
        "desc":"ICnaming은 인터넷 ID에 대한 지원을 제거했습니다. 이전에 인터넷 ID로 로그인하고 이름을 구입했다면 이 페이지에서 마이그레이션을 완료할 수 있습니다.",
        "MigrationSteps":"마이그레이션 단계",
        "step1":"마이그레이션하려는 대상 지갑과 인터넷 ID에 연결합니다.",
        "step2":"마이그레이션할 항목 선택",
        "step3":"마이그레이션 단계 완료",
        "Connect":"연결하다",
        "ConnectII":"인터넷 ID에 연결",
        "ConnectTargetWallet":"대상 지갑을 연결합니다.",
        "connectTips":"먼저 인터넷 아이덴티티 지갑과 대상 지갑을 연결해주세요.",
        "SelectItems":"마이그레이션할 항목 선택",
        "Migrate":"마이그레이션"
    },
    "blindBox":{
        "ConnectWallet":"지갑 연결",
        "YourBalance":"당신의 균형",
        "RewardTips":"블라인드 박스 구매 시 확률적으로 다음 아이템 획득",
        "Reward":"보상",
        "Chance":"가능성",
        "Remark":"주목",
        "Reasons":"이유",
        "ReasonsTips":"우리의 핵심 신념인 공정성을 수행하는 이름 서비스의 가능성을 탐색하는 새로운 방법을 소개합니다. 뿐만 아니라 블라인드 박스는 사용자가 참여할 때 많은 재미를 만드는 불확실성의 메커니즘에 작동합니다. 또한 초기 확률 설계는 사용자가 시장에서 합리적인 가격인 최소 지출로 더 많은 할당량과 희귀 할당량을 얻을 수 있는 더 높은 기회를 만듭니다."
    },
    "errorInfo":{
        "inputError":"귀하의 입력은 잘못된 값입니다",
        "notFoundAddress":"이 암호화 주소를 찾을 수 없습니다."
    },
    "toast":{
        "SaleSuccessfully":"판매 성공",
        "CancelledSuccessfully":"성공적으로 취소됨",
        "exceedPendingOrders":"대기 중인 주문 수를 초과할 수 없습니다.",
        "WrapSuccessful":"랩 성공",
        "UnWrapSuccessful":"포장 해제 성공",
        "SaveSuccessful":"저장 성공!",
        "SetSuccessfully":"성공적으로 설정",
        "MigrateSuccess":"성공적으로 마이그레이션"
    },
    "cheap":{
        "FindCheap":"시장에서 저렴한 크레딧을 찾고 <0>등록을 위해 {{cheapPrice}} DICP를 절약</0>",
        "FindOne":"시장에서 {{quota}}-chars 크레딧 찾기 <0>{{cheapPrice}} DICP 구매 및 등록</0>",
        "BuyQuotaToRegTitle":"구매 할당량 & 등록하다",
        "RegTitle":"등록하다",
        "you":"당신 자신",
        "savings": "{{cheap}} DICP 절감"
    }
}