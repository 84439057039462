export async function* mergeStream<T, R = void, N = T>(
  ...sources: (() => AsyncIterator<T, R, N>)[]
) {
  var iterators = sources.map(item => item());

  while (iterators[0]) {
    const dones: number[] = [];

    for (
      let i = 0, iterator: AsyncIterator<T>;
      (iterator = iterators[i]);
      i++
    ) {
      const { done, value } = await iterator.next();

      if (!done) yield value;
      else dones.push(i);
    }
    iterators = iterators.filter((_, i) => !dones.includes(i));
  }
}

export function createPager<T, R = void, N = T>(
  ...sources: (() => AsyncIterator<T, R, N>)[]
) {
  var cache: T[], stream: AsyncGenerator<T>;

  function reset() {
    cache = [];
    stream = mergeStream<T, R, N>(...sources);
  }

  reset();

  return {
    reset,
    async getPage(index = 1, size = 10) {
      const count = size * index;

      for (let i = count - cache.length; i; i--) {
        const { done, value } = await stream.next();

        if (done) break;

        cache.push(value);
      }

      return cache.slice(count - size, count);
    }
  };
}
