import { Day, Minute, Quarter, Second, Week } from 'web-utility';
import { Principal } from '@dfinity/principal';
import {
  QuotaType,
  RegistrationDetails,
  RegistrationDto,
  idlFactory as registrarIDL,
  _SERVICE as RegistrarActor,
  PriceTable
} from '@icnaming/registrar_client';

import { withLogging, withCache } from '../utils/errorLogger';
import { CanisterError, CanisterErrorCode } from '../utils/exception';
import { AuthBase } from '../AuthBase';
import {
  REGISTRAR_DETAIL_CACHE_KEY,
  REGISTRAR_QUOTA_CACHE_KEY,
  REGISTRY_DETAIL_CACHE_KEY,
  RESOLVER_GET_NAME_STATUS_WRAPPER
} from '../utils/config';
import { deleteCache } from '@deland-labs/local-cache';

export enum NameStatus {
  Unavailable = 1,
  Available = 2,
  Reserved = 3,
  OnSale = 4
}

export interface RegisterNameByDICPReq {
  name: string;
  years: number;
  dicpAmount: bigint;
}

export interface RegisterNameByQuotaReq {
  name: string;
  years: number;
  quotaType: QuotaType;
}

export interface NameRegistrarDetails {
  name: string;
  createdAt: bigint;
  expiredAt: bigint;
  owner?: Principal;
}

export interface NamePriceTableItem {
  nameLen: number;
  priceInIcpE8s: bigint;
  priceInXdrPermyriad: bigint;
}

export interface NamePriceTable {
  icpXdrRate: bigint;
  items: NamePriceTableItem[];
}

export class Registrar extends AuthBase {
  private registrarCanisterId: string;

  constructor(registrarCanisterId: string) {
    super();
    this.registrarCanisterId = registrarCanisterId;
  }

  /**
   * check name status
   */
  @withLogging
  public async getNameStatus(name: string) {
    const registrar = await this.createRegistrarActor(this.registrarCanisterId);
    const res = await registrar.get_name_status(name);

    if ('Ok' in res) return res.Ok;

    throw new CanisterError(res.Err);
  }

  /**
   * approve name
   *
   * @param target approve to other account, which can do anything like the owner
   */
  @withLogging
  public async approve(name: string, target: string) {
    const registrar = await this.createRegistrarActor(
      this.registrarCanisterId,
      false
    );
    const pid = Principal.fromText(target);
    const res = await registrar.approve(name, pid);

    if ('Ok' in res) return res.Ok;

    throw new CanisterError(res.Err);
  }

  /**
   * get quota count of user address
   *
   * @param address user wallet address
   */
  @withLogging
  @withCache(
    (_, address: string, quotaType: QuotaType) =>
      `${REGISTRAR_QUOTA_CACHE_KEY}_${
        'LenGte' in quotaType ? quotaType.LenGte : quotaType.LenEq
      }`,
    (Minute * 5) / Second
  )
  public async getQuotaCount(address: string, quotaType: QuotaType) {
    const registrar = await this.createRegistrarActor(
      this.registrarCanisterId,
      false
    );
    const res = await registrar.get_quota(
      Principal.fromText(address),
      quotaType
    );
    if ('Ok' in res) return res.Ok;

    throw new CanisterError(res.Err);
  }

  /**
   * get public resolver address
   */
  @withLogging
  @withCache('naming_public_resolver', Week / Second)
  async getPublicResolver() {
    const registrar = await this.createRegistrarActor(this.registrarCanisterId);
    const res = await registrar.get_public_resolver();

    if ('Ok' in res) return res.Ok;

    throw new CanisterError(res.Err);
  }

  /**
   * get name details
   *
   * @param name name to check
   */
  @withLogging
  @withCache(
    (_, name: string) => `${REGISTRAR_DETAIL_CACHE_KEY}_${name}`,
    Day / Second
  )
  public async getNameDetails(name: string): Promise<NameRegistrarDetails> {
    const registrar = await this.createRegistrarActor(this.registrarCanisterId);

    const res = await registrar.get_details(name);

    if ('Err' in res) throw new CanisterError(res.Err);

    return this.parseRegistrationDetailsToNameRegistrarDetails(res.Ok);
  }

  /**
   * get price table
   */
  @withLogging
  @withCache('registrar_get_price_table', Quarter / Second)
  public async getPriceTable() {
    const registrar = await this.createRegistrarActor(this.registrarCanisterId);
    const res = await registrar.get_price_table();

    if ('Err' in res) throw new CanisterError(res.Err);

    return this.parseNamePriceTable(res.Ok);
  }

  /**
   * get my registered names details
   *
   * @param limit size limit
   * @returns registered names details
   */
  @withLogging
  public async getRegistrantNamesWithPager(
    owner: string,
    offset: number,
    limit: number
  ) {
    const registrar = await this.createRegistrarActor(this.registrarCanisterId);

    const res = await registrar.get_names(Principal.fromText(owner), {
      offset: BigInt(offset),
      limit: BigInt(limit)
    });

    // if ('Ok' in res)
    //   return {
    //     totalCount: res.Ok.total_count,
    //     items: res.Ok.items.map(
    //       this.parseRegistrationDetailsToNameRegistrarDetails
    //     )
    //   };

    if ('Ok' in res)
      return res.Ok.items.map(
        this.parseRegistrationDetailsToNameRegistrarDetails
      );

    throw new CanisterError(res.Err);
  }

  /**
   * register name
   */
  @withLogging
  public async registerName(
    req: RegisterNameByDICPReq | RegisterNameByQuotaReq
  ) {
    if (req.years === 0)
      throw new CanisterError({
        code: CanisterErrorCode.RegisterYearMustBeGt1,
        message: `register years must >= 1`
      });

    const rbd = req as RegisterNameByDICPReq;
    const rbq = req as RegisterNameByQuotaReq;

    if (rbd.dicpAmount > 0 && rbd.years > 0)
      return this.registerByDICP(rbd.name, rbd.years, rbd.dicpAmount);

    if (!rbq.quotaType) return;

    if (rbq.years !== 1)
      throw new CanisterError({
        code: CanisterErrorCode.RegisterNameWithQuotaYearMustBe1,
        message: `register name with quota must be 1`
      });

    return this.registerByQuota(rbq.name, rbq.quotaType);
  }

  /**
   * renew name
   */
  @withLogging
  public async renewName({ name, years, dicpAmount }: RegisterNameByDICPReq) {
    const registrar = await this.createRegistrarActor(
      this.registrarCanisterId,
      false
    );

    const res = await registrar.renew_name({
      name,
      years,
      approve_amount: dicpAmount
    });

    if ('Ok' in res) {
      this.clearNameDetailCache(name);
      return res.Ok;
    }
    throw new CanisterError(res.Err);
  }

  /**
   * reclaim name
   *
   * @param name name to reclaim
   */
  @withLogging
  public async reclaimName(name: string) {
    const registrar = await this.createRegistrarActor(
      this.registrarCanisterId,
      false
    );
    const res = await registrar.reclaim_name(name);

    if ('Ok' in res) {
      this.clearNameDetailCache(name);
      return res.Ok;
    }

    throw new CanisterError(res.Err);
  }

  private clearNameDetailCache = (name: string) => {
    const cacheKey1 = `${REGISTRAR_DETAIL_CACHE_KEY}_${name}`;
    const cacheKey2 = `${REGISTRY_DETAIL_CACHE_KEY}_${name}`;
    const cacheKey3 = `${RESOLVER_GET_NAME_STATUS_WRAPPER}_${name}`;
    deleteCache(cacheKey1);
    deleteCache(cacheKey2);
    deleteCache(cacheKey3);
  };

  private clearQuotaCache = (quotaType: QuotaType) => {
    const cacheKey = `${REGISTRAR_QUOTA_CACHE_KEY}_${
      'LenGte' in quotaType ? quotaType.LenGte : quotaType.LenEq
    }`;
    deleteCache(cacheKey);
  };

  /**
   * transfer quota
   */
  @withLogging
  public async transferQuota(
    to: string,
    quotaType: QuotaType,
    transferCount: number
  ) {
    const registrar = await this.createRegistrarActor(
      this.registrarCanisterId,
      false
    );
    const res = await registrar.transfer_quota(
      Principal.fromText(to),
      quotaType,
      transferCount
    );
    if ('Ok' in res) {
      this.clearQuotaCache(quotaType);
      return res.Ok;
    }

    throw new CanisterError(res.Err);
  }

  /**
   * transfer name to another principal
   *
   * @param to transfer to
   */
  @withLogging
  public async transferName(to: string, name: string) {
    const registrar = await this.createRegistrarActor(
      this.registrarCanisterId,
      false
    );
    const res = await registrar.transfer(name, Principal.fromText(to));

    if ('Ok' in res) {
      this.clearNameDetailCache(name);
      return res.Ok;
    }

    throw new CanisterError(res.Err);
  }

  @withLogging
  private async registerByQuota(name: string, type: QuotaType) {
    const registrar = await this.createRegistrarActor(
      this.registrarCanisterId,
      false
    );
    const res = await registrar.register_with_quota(name, type);

    if ('Ok' in res) {
      this.clearNameDetailCache(name);
      this.clearQuotaCache(type);
      return res.Ok;
    }

    throw new CanisterError(res.Err);
  }

  @withLogging
  private async registerByDICP(
    name: string,
    years: number,
    dicpAmount: bigint
  ) {
    const registrar = await this.createRegistrarActor(
      this.registrarCanisterId,
      false
    );

    const pay = await registrar.register_with_payment({
      name,
      approve_amount: dicpAmount,
      years
    });

    if ('Ok' in pay) {
      this.clearNameDetailCache(name);
      return pay.Ok;
    }

    throw new CanisterError(pay.Err);
  }

  private parseRegistrationDetailsToNameRegistrarDetails({
    created_at,
    expired_at,
    ...rest
  }: RegistrationDetails | RegistrationDto): NameRegistrarDetails {
    return {
      createdAt: created_at,
      expiredAt: expired_at,
      ...rest
    };
  }

  private parseNamePriceTable(priceTable: PriceTable): NamePriceTable {
    return {
      icpXdrRate: priceTable.icp_xdr_conversion_rate,
      items: priceTable.items.map(i => ({
        nameLen: i.len,
        priceInIcpE8s: i.price_in_icp_e8s,
        priceInXdrPermyriad: i.price_in_xdr_permyriad
      }))
    };
  }

  /**
   * create registrar actor
   *
   * @param registrarCanisterId  registrar canister id
   * @param anonymous is anonymous access
   * @returns registrar actor
   */
  private createRegistrarActor(registrarCanisterId: string, anonymous = true) {
    return this.createActor<RegistrarActor>(
      registrarCanisterId,
      registrarIDL,
      anonymous
    );
  }
}
