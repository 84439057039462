import { FC, ReactNode } from 'react';
import { Modal, ModalHeaderProps, ModalProps } from 'react-bootstrap';

import { IconButton } from './IconButton';

export interface DialogProps
  extends Pick<ModalProps, 'show' | 'backdrop'>,
    Pick<ModalHeaderProps, 'closeButton'> {
  title: ReactNode;
  centered?: boolean;
  size?: 'sm' | 'lg';
  onHide?: () => any;
  onReturn?: () => any;
}

export const Dialog: FC<DialogProps> = ({
  title,
  closeButton = true,
  backdrop,
  children,
  show,
  centered,
  size,
  onHide,
  onReturn
}) => (
  <Modal {...{ backdrop, show, onHide, centered, size }}>
    <Modal.Header className="border-0">
      {onReturn && <IconButton name="chevron-left" onClick={onReturn} />}

      <Modal.Title>{title}</Modal.Title>

      {closeButton && <IconButton name="x-lg" onClick={onHide} />}
    </Modal.Header>
    <Modal.Body>{children}</Modal.Body>
  </Modal>
);
