{
    "appName":"icNaming",
    "base":{
        "names":"pangalan",
        "favorites":"Mga paborito",
        "assets":"Mga asset",
        "asset":"Asset",
        "Logout":"I-disconnect",
        "Name":"pangalan",
        "registrant":"nagparehistro",
        "controller":"controller",
        "Register":"Magrehistro",
        "Details":"Mga Detalye",
        "Expiration":"Petsa ng Pag-expire",
        "Resolver":"Lutasin",
        "Quota":"Quota",
        "Buy":"Bumili",
        "Sell":"Ibenta",
        "Price":"Presyo",
        "Amount":"Halaga",
        "Item":"item",
        "Cost":"Gastos",
        "balance":"balanse",
        "wrap":"Wrap ICP",
        "unwrap":"Unwrap DICP",
        "Cancel":"Kanselahin",
        "Confirm":"Kumpirmahin",
        "Submit":"Isumite",
        "connect": "I-connect",
        "BuyName":"Bumili ng Pangalan",
        "BuyQuota":"Bumili ng Quota",
        "SellName":"Ibenta ang Pangalan",
        "SellQuota":"Ibenta ang Quota",
        "Setting":"Setting",
        "Set":"Itakda",
        "transfer":"paglipat",
        "chars":"-char",
        "type":"Uri",
        "Fixed":"Nakapirming",
        "Renew":"I-renew",
        "EnterAmount":"Ipasok ang halaga",
        "EnterPrice":"Magpasok ang presyo",
        "Save":"I-save",
        "Delete":"Tanggalin",
        "NotSet":"Hindi Nakatakda",
        "fee":"Bayad",
        "Tips":"Mga tip",
        "Address":"Address",
        "to":"sa",
        "from":"mula sa"
    },
    "header": {
        "docs":"Docs",
        "blindBox":"BlindBox",
        "market":"Merkado"
    },
    "sessionBox":{
        "Select":"Pumili ng wallet",
        "title":"Mangyaring pumili ng wallet upang kumonekta sa dapp na ito:",
        "TryAgain":"Subukan muli",
        "usedIIBefore":"Ginamit ko ang II dati?"
    },
    "home": {
        "slogan" : "Mga Desentralisadong Digital na Pagkakakilanlan"
    },
    "domainSearch":{
        "search":"Maghanap",
        "placeholder":"Maghanap ng mga pangalan o address"
    },
    "domain" : {
        "card" : {
            "Buy":"Bumili",
            "View":"Tingnan",
            "Sell":"Ibenta",
            "Claim":"Claim",
            "HowToClaim":"Paano mag-claim",
            "registered":"Nakarehistro na ang pangalang ito."
        },
        "CustomBadge":{
            "Available": "Available",
            "Unavailable": "hindi available",
            "Reserved": "Nakareserba",
            "OnSale": "Binibenta ng may-ari",
            "ForSaleByOwner":"Binibenta ng may-ari"
        },
        "sell":{
            "Confirmation":"Kumpirmasyon",
            "ThinkAgain":"Mag-isip muli",
            "ConfirmCancel":"Kumpirmahin ang pagkansela",
            "AreYouSure":"Sigurado ka bang kanselahin ang pagbebenta ng sumusunod na item?"
        }
    },
    "registerForm":{
        "RegistrationPeriod":"Panahon ng Pagpaparehistro",
        "years":"taon",
        "year":"Taon",
        "RegistrationPrice":"Presyo ng Pagpaparehistro",
        "Best":"Best",
        "TotalPrice":"Kabuuang presyo",
        "What":"Ano ang",
        "question":"Ang mga pangalan ng 3-5 character ay maaari lamang magrehistro sa pamamagitan ng mga quota sa pagbili",
        "getQuota":"Kumuha ng quota",
        "RenewPeriod":"Panahon ng Pag-renew",
        "questionTitle1":"Ano ang quota?",
        "questionTitle2":"Paano makakuha ng quota?",
        "questionTitle3":"Blind box",
        "questionDesc1":"Kailangan mong gumamit ng quota kapag nagrerehistro ng 3-5-chars na pangalan. Mga pangalan na may iba't ibang char na maaaring irehistro gamit ang mga quota, at mayroong iba't ibang uri ng quota. Halimbawa, ang 4-chars na quota ay hindi maaaring magrehistro ng 3-chars na mga pangalan.",
        "questionDesc2":"Ang pagbili ng blind box ay maglalaman ng quota at ilang DNS token, at nag-iiba-iba ang posibilidad na makakuha ng iba't ibang chars quota. Ang partikular na tsart ng posibilidad ay ipinapakita sa interface ng pagbili ng blind box. Maaari mong i-click ang sumusunod na link upang bilhin ang <0>blind box</0>. Maaari ding mabili ang mga quota sa <1>merkado</1>. Ang mga quota sa merkado ay ibinebenta ng ibang mga gumagamit.",
        "questionDesc3":"Ang Blind box ay isang mas mahusay na paraan upang ipamahagi ang mga pangalan. Ang sumusunod na link ay ang page ng pagbili at detalyadong pagpapakilala ng blind box:",
        "whatBlindBox":"ano yung blind box?",
        "whyBlindBox":"bakit blind box?"
    },
    "market":{
        "Filter":"Salain",
        "Items":"Mga bagay",
        "All":"Lahat",
        "Length":"Ang haba",
        "SeeMySale":"Tingnan ang Aking Sale",
        "Down":"Pababa",
        "placeholder":"Maghanap ng mga pangalan",
        "OwnedBy":"Pag-aari ni",
        "PurchaseConfirmation":"Pagkumpirma ng Pagbili",
        "PurchaseTips":"Sigurado ka bang bibilhin ang sumusunod na item?"
    },
    "account":{
        "Receive":"Tumanggap",
        "nnsTips":"Kung bumili ka ng mga pangalan sa pamamagitan ng pag-link sa II wallet(NNS) dati,<0>i-click ako para makumpleto ang paglipat.</0>",
        "Expires":"Mag-e-expire",
        "ViewSelling":"Tingnan ang pagbebenta",
        "MySale" : "Ang benta ko",
        "placeholder":"Maghanap ng mga pangalan",
        "names":"Mga pangalan",
        "Frozen":"Nagyelo",
        "Available":"Available",
        "Action":"Aksyon",
        "feeTips":"Ang DICP wrap fee (0.0001 ICP) ay sinisingil ng ledger kapag inilipat ng mga user ang ICP. Ang IC Naming ay hindi naniningil ng anumang mga bayarin kapag binabalot/binubuksan ang DICP.",
        "SelectAssets":"Piliin ang Mga Asset",
        "DestinationAddress":"Address ng patutunguhan",
        "InsufficientBalance":"Hindi sapat na balanse",
        "EnterAddressOrName":"Ilagay ang Address / Pangalan",
        "EnterAmount":"Ipasok ang halaga",
        "quotaDialogTitle":"Ano ang quota?",
        "quotaDialogDesc":"Kailangan mong gumamit ng quota kapag nagrerehistro ng 3-5-chars na pangalan. Mga pangalan na may iba't ibang char na maaaring irehistro gamit ang mga quota, at mayroong iba't ibang uri ng quota. Halimbawa, Ang 3-chars na quota ay maaaring gamitin upang magrehistro ng mga pangalan na mas malaki kaysa sa o katumbas ng 3 digit, ngunit ang 4-chars na quota ay hindi maaaring magrehistro ng 3-chars na mga pangalan."
    },
    "nameSet":{
        "menu":{
            "Profile":"Profile",
            "SocialMedia":"Social Media",
            "Crypto":"Crypto",
            "Canister":"Canister",
            "Link":"Link",
            "TextRecord":"Tala ng teksto",
            "Transfer":"Paglipat",
            "Renew":"I-renew",
            "Resolver":"Lutasin",
            "ReverseRecord":"Baliktarin ang rekord"
        },
        "tryItNow":"subukan ito ngayon",
        "EditProfile":"I-edit ang Profile",
        "SetAvatar":"Magtakda ng NFT/Gravatar bilang aking avatar",
        "DisplayName":"Display name",
        "Email":"Email",
        "Location":"Lokasyon",
        "Description":"Paglalarawan",
        "reclaim":"Bawiin",
        "noOwnController":"Nailipat na ang controller ng pangalang ito. Para mag-set up, paki-reclaim muna.",
        "SetYourReverseRecord":"Itakda ang iyong Reverse record",
        "reverseRecordTips":"Gusto mo bang makuha ng ibang tao ang iyong pangalan sa pamamagitan ng iyong principal ID? Pagkatapos i-set up ang iyong pangunahing pangalan, maaaring gamitin ng iba ang principal ID upang suriin ang iyong pangalan.",
        "yourDecentralized":"I-access ang iyong desentralisadong website",
        "canisterTips1":"Mayroon ka bang desentralisadong website na naka-deploy sa IC? kung mayroon ka, itakda ang canister id ng website dito, pagkatapos ay maaari mong i-access ito sa pamamagitan ng url",
        "canisterTips2":"ay isang halimbawa ng isang desentralisadong website na naka-deploy sa IC",
        "hasUrlNotice1Title":"Kumpirmahin ang mga setting",
        "hasUrlNotice":"Naitakda mo na ang URL, kung itatakda mo ang canister id, bibisitahin ng pagbisita sa <bikeUrl>{{bikeUrl}}</bikeUrl> ang desentralisadong site na iyong na-deploy sa IC, sa halip na ang <url>{{url}}</url>na iyong itinakda",
        "yourWebsite":"Mag-link sa iyong web2/web3 website sa pamamagitan ng url:",
        "urlTips1":"May website ka na ba ngayon? kung mayroon ka, itakda ang url ng website dito, pagkatapos ay maa-access mo ito sa pamamagitan ng url",
        "urlNotice":"(Pansinin: naitakda mo ang canister id, maa-access nito ang canister website sa halip na ang iyong url)",
        "hasBeenTransferred":"Nailipat na ang nagparehistro ng pangalan." 
    },
    "dangerConfirm":{
        "tips":"Sigurado ka bang gusto mong lumipat sa sumusunod na address?",
        "transferRegistrant":{
            "title":"Transfer registrant",
            "note":"Tandaan: Ang transfer registrant ay nangangahulugan na hindi na ikaw ang may-ari ng pangalan, hindi ito maaaring kanselahin kapag nakumpleto na ang paglipat."
        },
        "transferController":{
            "title":"Transfer controller",
            "note":"Tandaan: Ang transfer controller ay nangangahulugan lamang ng paglilipat ng kontrol, ikaw pa rin ang may-ari ng pangalan at maaaring bawiin ang kontrol anumang oras sa pamamagitan ng [Reclaim]."
        },
        "reclaim":{
            "title":"bawiin ang controller"
        },
        "transferToken":{
            "title":"Ilipat ang token",
            "note":"Tandaan: Hindi ito maaaring kanselahin kapag nakumpleto ang paglipat."
        }
    },
    "migration":{
        "desc":"Inalis ng ICNaming ang suporta para sa Internet Identity. Kung naka-log in ka gamit ang Internet Identity at bumili ng mga pangalan dati, maaari mong kumpletuhin ang paglipat sa pahinang ito.",
        "MigrationSteps":"Mga hakbang sa paglilipat",
        "step1":"Kumonekta sa Internet Identity at sa target na wallet na gusto mong i-migrate",
        "step2":"Pumili ng mga item na ililipat",
        "step3":"Kumpletuhin ang mga hakbang sa paglipat",
        "Connect":"I-connect",
        "ConnectII":"Kumonekta sa Internet Identity",
        "ConnectTargetWallet":"Ikonekta ang target na wallet",
        "connectTips":"Pakikonekta muna ang Internet Identity wallet at target na wallet.",
        "SelectItems":"Pumili ng mga item na ililipat",
        "Migrate":"Mag-migrate"
    },
    "blindBox":{
        "ConnectWallet":"I-connect ang Wallet",
        "YourBalance":"Iyong balanse",
        "RewardTips":"Ang pagbili ng blind box ay may pagkakataong makuha ang mga sumusunod na item",
        "Reward":"Gantimpala",
        "Chance":"Pagkakataon",
        "Remark":"Puna",
        "Reasons":"Mga dahilan",
        "ReasonsTips":"Ipinapakilala ang isang bagong paraan upang tuklasin ang mga posibilidad ng serbisyo ng pangalan, Na nagsasagawa ng aming mga pangunahing paniniwala: pagiging patas. Gayundin, ang aming paunang disenyo ng posibilidad ay lumilikha ng mas mataas na pagkakataon para sa mga user na makakuha ng mas maraming quota at bihirang quota na may pinakamababang paggasta na ang presyo ay makatwiran sa merkado."
    },
    "errorInfo":{
        "inputError":"Ang iyong input ay ilegal na halaga",
        "notFoundAddress":"Hindi mahanap ang crypto address na ito."
    },
    "toast":{
        "SaleSuccessfully": "Matagumpay na Pagbebenta",
        "CancelledSuccessfully": "Matagumpay na Kinansela",
        "exceedPendingOrders": "Hindi maaaring lumampas sa bilang ng mga nakabinbing order",
        "WrapSuccessful": "I-wrap ang Matagumpay",
        "UnWrapSuccessful": "UnWrap Successful",
        "SaveSuccessful": "Matagumpay ang pag-save!",
        "SetSuccessfully": "Matagumpay na Itakda",
        "MigrateSuccess": "Matagumpay na Lumipat"
    },
    "cheap":{
        "FindCheap": "Maghanap ng murang quota sa merkado,<0>Mag-ipon ng {{cheapPrice}} DICP para sa pagpaparehistro</0>",
        "FindOne": "Maghanap ng isa sa merkado {{quota}}-chars Quota,<0>{{cheapPrice}} DICP Buy and Register</0>",
        "BuyQuotaToRegTitle":"Bumili ng quota & Pagpaparehistro",
        "RegTitle":"Pagpaparehistro",
        "you":"ng iyong sarili",
        "savings":"Mga matitipid na {{cheap}} DICP"
    }
}