export const RESOLVER_KEY_ICP_CANISTER = 'canister.icp' as const;
export const RESOLVER_KEY_ICP_PRINCIPAL = 'principal.icp' as const;
export const RESOLVER_KEY_ICP_ACCOUNT_ID = 'account_id.icp' as const;

export const RESOLVER_KEY_BTC = 'token.btc' as const;
export const RESOLVER_KEY_ETH = 'token.eth' as const;
export const RESOLVER_KEY_BNB = 'token.bnb' as const;
export const RESOLVER_KEY_XRP = 'token.xrp' as const;
export const RESOLVER_KEY_ADA = 'token.ada' as const;
export const RESOLVER_KEY_SOL = 'token.sol' as const;
export const RESOLVER_KEY_DOGE = 'token.doge' as const;
export const RESOLVER_KEY_DOT = 'token.dot' as const;
export const RESOLVER_KEY_MATIC = 'token.matic' as const;
export const RESOLVER_KEY_TRX = 'token.trx' as const;
export const RESOLVER_KEY_AVAX = 'token.avax' as const;
export const RESOLVER_KEY_ETC = 'token.etc' as const;
export const RESOLVER_KEY_LTC = 'token.ltc' as const;
export const RESOLVER_KEY_XLM = 'token.xlm' as const;
export const RESOLVER_KEY_XMR = 'token.xmr' as const;
export const RESOLVER_KEY_ALGO = 'token.algo' as const;
export const RESOLVER_KEY_BCH = 'token.bch' as const;
export const RESOLVER_KEY_VET = 'token.vet' as const;
export const RESOLVER_KEY_XTZ = 'token.xtz' as const;
export const RESOLVER_KEY_AAVE = 'token.aave' as const;
export const RESOLVER_KEY_BSV = 'token.bsv' as const;
export const RESOLVER_KEY_ZEC = 'token.zec' as const;
export const RESOLVER_KEY_BTT = 'token.btt' as const;
export const RESOLVER_KEY_NEO = 'token.neo' as const;
export const RESOLVER_KEY_DASH = 'token.dash' as const;
export const RESOLVER_KEY_WAVES = 'token.waves' as const;
export const RESOLVER_KEY_BTG = 'token.btg' as const;
export const RESOLVER_KEY_XEM = 'token.xem' as const;
export const RESOLVER_KEY_DCR = 'token.dcr' as const;
export const RESOLVER_KEY_GNO = 'token.gno' as const;

export const RESOLVER_KEY_EMAIL = 'email' as const;
export const RESOLVER_KEY_URL = 'url' as const;
export const RESOLVER_KEY_AVATAR = 'avatar' as const;
export const RESOLVER_KEY_DISPLAY_NAME = 'display_name' as const;
export const RESOLVER_KEY_DESCRIPTION = 'description' as const;
export const RESOLVER_KEY_LOCATION = 'location' as const;
export const RESOLVER_KEY_NOTICE = 'notice' as const;
export const RESOLVER_KEY_KEYWORDS = 'keywords' as const;

export const RESOLVER_KEY_TWITTER = 'com.twitter' as const;
export const RESOLVER_KEY_GITHUB = 'com.github' as const;
export const RESOLVER_KEY_FACEBOOK = 'com.facebook' as const;
export const RESOLVER_KEY_MEDIUM = 'com.medium' as const;
export const RESOLVER_KEY_DISCORD = 'com.discord' as const;
export const RESOLVER_KEY_TELEGRAM = 'com.telegram' as const;
export const RESOLVER_KEY_INSTAGRAM = 'com.instagram' as const;
export const RESOLVER_KEY_REDDIT = 'com.reddit' as const;
export const RESOLVER_KEY_DSCVR = 'com.dscvr' as const;
export const RESOLVER_KEY_DISTRIKT = 'com.distrikt' as const;
export const RESOLVER_KEY_RELATION = 'com.relation' as const;
export const RESOLVER_KEY_OPENCHAT = 'com.openchat' as const;

export const RESOLVER_KEY_SETTING_REVERSE_RESOLUTION_PRINCIPAL =
  'settings.reverse_resolution.principal' as const;
