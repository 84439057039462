import { asyncLoop } from 'web-utility';
import { observable } from 'mobx';
import BigNumber from 'bignumber.js';
import { Principal } from '@dfinity/principal';

import { ListModel, toggle } from './Base';
import { NumberLike, principalToAccountID, toBigNumber } from './utils/helper';
import { withLogging } from './utils/errorLogger';
import {
  IC_REGISTRAR_ID,
  DICP_ID,
  DICP_MINTER_ID,
  DNS_ID,
  SUB_ACCOUNT_ZERO,
  DICP_SUBACCOUNT_FIRST_BYTE,
  REGISTRABLE_NAME_MIN_LENGTH,
  DNS_TOKEN_DECIMALS,
  WRAP_FEE
} from './utils/config';
import { DFT } from './canister/DFT';
import { DICP } from './canister/DICP';
import { Ledger } from './canister/Ledger';
import { Registrar } from './canister/Registrar';

export const CoinNames = [
  'BTC',
  'ETH',
  'USDT',
  'USDC',
  'ICP',
  'DICP',
  'DNS'
] as const;

export type CoinName = typeof CoinNames[number];

export interface Asset {
  id: string;
  type: 'coin' | 'synthesis' | 'renewal' | 'quota';
  name?: CoinName | string;
  quota?: number;
  account?: string;
  frozenAmount: NumberLike;
  availableAmount: NumberLike;
}

export class AssetModel extends ListModel<Asset> {
  private ledgerICP = new Ledger();
  private ledgerDFT = new DFT(DICP_ID);
  private ledgerDICP = new DICP(DICP_ID, DICP_MINTER_ID);
  private ledgerDNS = new DFT(DNS_ID);
  private registrar = new Registrar(IC_REGISTRAR_ID);

  @observable
  balance: Partial<Record<CoinName, BigNumber>> = {};

  @observable
  currentQuota?: Asset;

  clear() {
    super.clear();

    this.balance = {};
    this.currentQuota = {} as Asset;
  }

  @toggle('downloading')
  async getBalance(address: string) {
    await this.ledgerDICP.initialize();

    await Promise.all([
      this.ledgerICP
        .balanceOf(address)
        .then(({ balance: ICP }) => (this.balance = { ...this.balance, ICP })),
      this.ledgerDICP
        .getBalance(address)
        .then(
          ({ balance: DICP }) => (this.balance = { ...this.balance, DICP })
        ),
      this.ledgerDNS.balanceOf(address).then(
        ({ balance }) =>
          (this.balance = {
            ...this.balance,
            DNS: balance.shiftedBy(-DNS_TOKEN_DECIMALS)
          })
      )
    ]);

    return this.balance;
  }

  @withLogging
  @toggle('downloading')
  async transferFeeOf(amount: NumberLike, coin: string) {
    switch (coin) {
      case 'DNS':
        return DFT.calcTransferFee(
          toBigNumber(amount),
          await this.ledgerDNS.getTokenInfo()
        );
      case 'DICP':
        return DFT.calcTransferFee(
          toBigNumber(amount),
          await this.ledgerDICP.getTokenInfo()
        );
      case 'ICP':
        return WRAP_FEE;
    }
  }

  @toggle('uploading')
  async transferCoin(
    from: CoinName,
    to: CoinName,
    amount: number,
    target = ''
  ) {
    if (from === 'DNS' && to === 'DNS')
      return this.ledgerDNS.transferToken(target, amount, DNS_TOKEN_DECIMALS);

    if (from === 'ICP' && to === 'ICP')
      return this.ledgerICP.transfer(target, amount);

    if (from === 'DICP' && to === 'DICP') {
      await this.ledgerDICP.initialize();

      return this.ledgerDFT.transferToken(target, amount);
    }
    if (from === 'DICP' && to === 'ICP') {
      const { blockHeight } = await this.ledgerDICP.withdraw(amount);

      return new Promise<boolean>(resolve => {
        const stop = asyncLoop(async () => {
          const done = await this.ledgerDICP.checkWithdrawStatus(blockHeight);

          if (!done) return;

          stop();
          resolve(done);
        });
      });
    }

    if (from === 'ICP' && to === 'DICP') {
      const DICP_TARGET_SUB_ACCOUNT = Uint8Array.from(SUB_ACCOUNT_ZERO);
      const dicpTarget = principalToAccountID(
        Principal.fromText(DICP_ID),
        Uint8Array.from([
          DICP_SUBACCOUNT_FIRST_BYTE,
          ...DICP_TARGET_SUB_ACCOUNT.slice(1)
        ])
      );
      console.debug('DICP_SUBACCOUNT_FIRST_BYTE', DICP_SUBACCOUNT_FIRST_BYTE);
      console.debug('DICP target', dicpTarget);

      const blockHeight = await this.ledgerICP.transfer(dicpTarget, amount);

      return new Promise<boolean>(resolve => {
        const stop = asyncLoop(async () => {
          const done = await this.ledgerDICP.checkMintStatus(blockHeight);

          if (!done) return;

          stop();
          resolve(done);
        }, 0.5);
      });
    }
  }

  @toggle('uploading')
  transferQuota(address: string, length: number, amount: number) {
    return this.registrar.transferQuota(address, { LenGte: length }, amount);
  }

  @toggle('downloading')
  async getOneQuota(address: string, length: number) {
    const availableAmount = await this.registrar.getQuotaCount(address, {
      LenGte: length
    });
    return (this.currentQuota = {
      id: `Quota-${length}`,
      type: 'quota',
      quota: length,
      availableAmount,
      frozenAmount: 0
    });
  }

  async getList(address: string) {
    const { ICP, DICP, DNS } = await this.getBalance(address);

    this.list = [
      {
        id: 'ICP',
        type: 'coin',
        name: 'ICP',
        availableAmount: ICP,
        frozenAmount: 0
      },
      {
        id: 'DICP',
        type: 'coin',
        name: 'DICP',
        availableAmount: DICP,
        frozenAmount: 0
      },
      {
        id: 'DNS',
        type: 'coin',
        name: 'DNS',
        availableAmount: DNS,
        frozenAmount: 0
      }
    ];

    const quotas = await Promise.all(
      Array.from(new Array(5), (_, i) =>
        this.getOneQuota(address, i + REGISTRABLE_NAME_MIN_LENGTH)
      )
    );
    return (this.list = [...this.list, ...quotas]);
  }
}

export default new AssetModel();
