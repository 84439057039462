import { Quarter, Second } from 'web-utility';
import { deleteCache } from '@deland-labs/local-cache';
import {
  idlFactory as resolverIDL,
  _SERVICE as ResolverActor
} from '@icnaming/resolver_client';

import { AuthBase } from '../AuthBase';
import { withCache, withLogging } from '../utils/errorLogger';
import { CanisterError } from '../utils/exception';
import * as ResolverKeys from '../utils/config/ResolverKey';
import { Principal } from '@dfinity/principal';

export type ResolverKey = typeof ResolverKeys[keyof typeof ResolverKeys];

export type ResolverRecord = Partial<Record<ResolverKey, string>>;
const RESOLVER_RECORD_CACHE_KEY = 'resolver_record';
export class Resolver extends AuthBase {
  private resolverCanisterId: string;

  constructor(resolverCanisterId: string) {
    super();
    this.resolverCanisterId = resolverCanisterId;
  }

  /**
   * get name record values
   */
  @withLogging
  // @withCache(
  //   (_, name: string) => `${RESOLVER_RECORD_CACHE_KEY}_${name}`,
  //   Quarter / Second
  // )
  public async getRecordValues(name: string) {
    const resolver = await this.createResolverActor(
      this.resolverCanisterId,
      false
    );
    const res = await resolver.get_record_value(name);

    if ('Ok' in res) return Object.fromEntries(res.Ok) as ResolverRecord;

    throw new CanisterError(res.Err);
  }

   /**
   * get reverse value
   */
    @withLogging
    public async getReverse(principal: string) {
      const resolver = await this.createResolverActor(
        this.resolverCanisterId,
        false
      );
      const res = await resolver.reverse_resolve_principal(Principal.fromText(principal));
        
      if ('Ok' in res) return res.Ok;
  
      throw new CanisterError(res.Err);
    }

  /**
   * set name record
   *
   * @param patchKeyValues key/value update in patch mode
   */
  @withLogging
  public async setRecordValues(name: string, patchKeyValues: ResolverRecord) {
    const resolver = await this.createResolverActor(
      this.resolverCanisterId,
      false
    );
    const res = await resolver.set_record_value(
      name,
      Object.entries(patchKeyValues)
    );
    if ('Ok' in res) {
      deleteCache(`${RESOLVER_RECORD_CACHE_KEY}_${name}`);
      return res.Ok;
    }

    throw new CanisterError(res.Err);
  }

  private createResolverActor(resolverCanisterId: string, anonymous = true) {
    return this.createActor<ResolverActor>(
      resolverCanisterId,
      resolverIDL,
      anonymous
    );
  }
}
