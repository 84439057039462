.toast-position {
  position: fixed;
  top: 6rem;
  right: 10vw;
  z-index: 2000;

  @media (max-width: 576px) {
    right: 1.625rem;
  }
}

.toast-div {
  padding: 1.875rem;
  border-radius: 0.625rem;
}

.loading-line {
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: 2px solid rgba(45, 67, 255, 1);
  width: 0;
  animation-name: loading-line;
  animation-duration: var(--toast-animation-time);
  animation-iteration-count: infinite;
}

@keyframes loading-line {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}
