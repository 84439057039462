@import (reference) '../index.less';

.accordion-body-custom {
  padding: 1.875rem;
  background: #ffffff;
  .inner-shadow();
  border-radius: 6px;
  overflow: hidden;
}

.filter-custom-desktop {
  margin-top: 1rem;
  padding: 1.875rem;
  .outer-shadow();
  border-radius: 20px;
}

.filter-custom-phone {
  margin-top: 1rem;
  padding: 1.875rem;
  background: #ffffff;
}
.form-select {
  color: rgba(45, 67, 255, 1) !important;
}

.form-check {
  .form-check-input {
    margin-left: 0;
    margin-top: 0;
  }
}

.form-check-input {
  width: 20px !important;
  height: 20px !important;
  border-radius: 5px;
  border: 1px solid #8c9ecc;

  &:checked {
    background-color: rgba(140, 158, 204, 1) !important;
    border-color: rgba(140, 158, 204, 1) !important;
  }
}
