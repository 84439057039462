import BigNumber from 'bignumber.js';

import { DEFAULT_ASSETS_PRECISION } from '../../config';

export const zero = new BigNumber(0);

export const toBigInt = (value: BigNumber) => BigInt(value.dp(0).toFixed());

export const decimalsToNumber = (decimals: number) =>
  new BigNumber(10).pow(decimals);

export type NumberLike = string | number | bigint | BigNumber;

export function parseToCommon(
  originTokenQty: NumberLike,
  tokenDecimals = 0,
  precision?: number
) {
  const replacedQty = (originTokenQty + '').replaceAll(',', '');

  const bn = new BigNumber(replacedQty).shiftedBy(-tokenDecimals);

  return precision
    ? bn.dp(precision, BigNumber.ROUND_DOWN)
    : bn.dp(DEFAULT_ASSETS_PRECISION, BigNumber.ROUND_DOWN);
}

export const toBigNumber = (value: NumberLike) =>
  new BigNumber((value + '').replaceAll(',', ''));

export function getPrecision(value: NumberLike) {
  const replacedQty = (value + '').replaceAll(',', '');

  const [integer, float] = replacedQty.split('.');

  if (!float) return 0;

  const BN = toBigNumber(replacedQty);

  return BN.isNaN() ? 0 : float.length;
}

export const parseToOrigin = (commonQty: NumberLike, tokenDecimals: number) =>
  new BigNumber(commonQty + '').shiftedBy(tokenDecimals);

export function toPrecision(originTokenQty: NumberLike, precision?: number) {
  const bn = new BigNumber(originTokenQty + '');

  return new BigNumber(
    precision ? bn.decimalPlaces(precision).toPrecision() : bn.toPrecision()
  );
}
