@import (reference) '../index.less';

.migration {
  font-size: 14px;
  :global {
    .btn-link {
      text-decoration: none !important;
      display: inline;
      font-size: 14px;
    }
  }
  @media (min-width: 576px) {
    font-size: 16px;
    :global {
      .btn-link {
        font-size: 16px;
      }
    }
  }
}
.mySale {
  position: absolute;
  top: 24px;
  right: 0;
  color: #8c9ecc;
  font-size: 14px;
  :global {
    .form-check {
      margin-top: 5px;
    }
  }
}
.custom-pillnav {
  height: 44px;
  padding: 7px 0.7rem 0 !important;
  flex-wrap: nowrap !important;
  @media (min-width: 576px) {
    padding: 7px 1rem 0 !important;
  }
  :global {
    .nav-link {
      font-size: 14px;
      padding: 0 1.1rem !important;
      height: 30px;
      line-height: 30px;
      color: rgba(88, 98, 122, 0.5) !important;
    }
    .nav-link.active {
      color: #0d1220 !important;
      background-color: rgba(255, 255, 255, 0.7) !important;
      font-weight: 500;
      .inner-shadow();
      border-radius: 6px !important;
    }
  }
}
