import classNames from 'classnames';
import { formToJSON } from 'web-utility';
import { FC, FormEventHandler } from 'react';
import { Form, Button, FormControlProps } from 'react-bootstrap';
import { useTranslation,Trans } from 'react-i18next';

import { IconInput } from './IconInput';
import sessionStore from '../model/Session';

export interface DomainSearchProps
  extends Pick<FormControlProps, 'value' | 'onChange'> {
  auto?: boolean;
}

const search: FormEventHandler<HTMLFormElement> = event => {
  event.preventDefault();

  const { keyword } = formToJSON<{ keyword: string }>(event.currentTarget);

  location.hash = `/search/${encodeURIComponent(keyword.toLowerCase())}`;
};

export const DomainSearch: FC<DomainSearchProps> = ({
  auto,
  ...props
}: DomainSearchProps) => {
  const { isDesktop } = sessionStore;
  const { t } = useTranslation();
  return (
    <Form className="m-auto text-center" onSubmit={search}>
      <IconInput
        {...props}
        className={classNames('my-5 mx-auto', isDesktop ? 'w-75' : '')}
        icon="search"
        placeholder={t("domainSearch.placeholder")}
        type="search"
        name="keyword"
        pattern="[a-zA-Z0-9-.]+"
        required
      >
        <Button
          variant="primary"
          className={classNames('rounded-3', isDesktop ? 'px-4' : 'px-3')}
          size="sm"
          type="submit"
        >
          {t("domainSearch.search")}
        </Button>
      </IconInput>

      {auto && (
        <Button
          variant="outline-primary"
          className="rounded-pill border-0 bg-opacity-10 bg-primary fw-500 px-5 py-2"
          href="#/feel-lucky"
        >
          Feel lucky
        </Button>
      )}
    </Form>
  );
};
