import { IDL } from '@dfinity/candid';
import { Actor, HttpAgent } from '@dfinity/agent';

import { WalletConnectError, WalletConnectErrorCode } from '../../exception';
import { principalToAccountID } from '../../helper';
import { IWalletConnector, WalletAuth, WalletType } from '..';
import { StoicIdentity } from './ic-stoic-identity';
import {
  ICP_FAVORITES_ID,
  ICP_HOST,
  ICP_REGISTRAR_ID,
  ICP_REGISTRY_ID,
  ICP_RESOLVER_ID,
  IC_HOST
} from '../../config';

export class StoicWalletConnector implements IWalletConnector {
  public type = WalletType.StoicWallet;
  public connected = false;

  private icHost: string;
  private whiteList: string[] = [];
  private providerUrl: string;
  private dev: boolean;
  private identity?: any;
  private ic_agent?: HttpAgent;
  private icp_agent?: HttpAgent;

  constructor(
    icHost: string,
    whitelist: string[] = [],
    dev = false,
    providerUrl = 'https://www.stoicwallet.com'
  ) {
    this.icHost = icHost;
    this.whiteList = whitelist;
    this.dev = dev;
    this.providerUrl = providerUrl;
  }

  private getAgent = (canisterId: string) => {
    const ids = [
      ICP_REGISTRAR_ID,
      ICP_REGISTRY_ID,
      ICP_RESOLVER_ID,
      ICP_FAVORITES_ID
    ];

    if (ids.some(id => id === canisterId)) {
      if (!this.icp_agent)
        this.icp_agent = new HttpAgent({
          host: ICP_HOST,
          identity: this.identity
        });
      return this.icp_agent;
    } else {
      if (!this.ic_agent) {
        this.ic_agent = new HttpAgent({
          host: IC_HOST,
          identity: this.identity
        });
        this.ic_agent.fetchRootKey().catch(e => {
          console.error(`fetchRootKey error: ${e}`);
        });
      }
      return this.ic_agent;
    }
  };

  connect = async (): Promise<WalletAuth> => {
    const stoicConn = await StoicIdentity.load();
    try {
      this.identity = stoicConn || (await StoicIdentity.connect());

      if (!this.identity)
        throw new WalletConnectError(
          WalletConnectErrorCode.StoicWalletConnectFailed,
          `StoicWalletConnector.connect: connect failed`
        );
      console.debug('StoicWalletConnector.connect: connected');

      this.connected = true;

      const principalId = this.identity.getPrincipal();
      const accountId = principalToAccountID(principalId);

      sessionStorage.setItem('walletType', 'Stoic');

      return {
        type: WalletType.StoicWallet,
        principal: principalId.toText(),
        accountId
      };
    } catch (error) {
      throw new WalletConnectError(
        WalletConnectErrorCode.StoicWalletConnectFailed,
        `StoicWalletConnector.connect: connect failed ${error}`
      );
    }
  };

  createActor = async <T>(
    canisterId: string,
    idlFactory: IDL.InterfaceFactory
  ) => {
    const agent = this.getAgent(canisterId);

    return Actor.createActor<T>(idlFactory, {
      agent,
      canisterId
    });
  };

  disconnect = () => StoicIdentity.disconnect();
}
