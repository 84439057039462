import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import session from "../../Session";
import en_GB from './en_GB.json';
import id_ID from './id_ID.json';
import zh_CN from './zh_CN.json';
import zh_TW from './zh_TW.json';
import vi_VN from './vi_VN.json';
import ms_MY from './ms_MY.json';
import ko_KR from './ko_KR.json';
import ja_JP from './ja_JP.json';
import en_PH from './en_PH.json';

const resources = {
    en_GB: { translation: en_GB },
    id_ID: { translation: id_ID },
    zh_CN: { translation: zh_CN },
    zh_TW: { translation: zh_TW },
    vi_VN: { translation: vi_VN },
    ms_MY: { translation: ms_MY },
    ko_KR: { translation: ko_KR },
    ja_JP: { translation: ja_JP },
    en_PH: { translation: en_PH },
};
i18n.use(initReactI18next)
    .init({
        resources,
        fallbackLng: session.currentLanguage,
        detection: {
            caches: ['localStorage', 'sessionStorage', 'cookie'],
        },
        interpolation: {
            escapeValue: false
        }
    })

export default i18n