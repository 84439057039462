import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { PureComponent } from 'react';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import { t } from 'i18next';

import * as styles from './index.module.less';
import { IconButton } from '../IconButton';
import { PillNav } from '../PillNav';
import { EmptyCard } from '../EmptyCard';
import { SessionBox } from '../SessionBox';
import { DomainDetail } from '../DomainDetail';
import { RegisterForm } from '../RegisterForm';
import { CustomBadge } from './Badge';

import { parseToCommon } from '../../model/utils/helper';
import { DICP } from '../../model/canister/DICP';
import { NameStatus } from '../../model/canister/Registrar';
import sessionStore from '../../model/Session';
import domainStore, { Domain } from '../../model/Domain';
import resolverStore from '../../model/Resolver';

export type DomainCardProps = Domain;

const CardTypes = ['Register', 'Details'] as const;

type CardType = typeof CardTypes[number];

@observer
export class DomainCard extends PureComponent<DomainCardProps> {
  @observable
  openPanel = false;

  @observable
  currentCard: CardType = 'Register';

  @observable
  currentSeller = '';

  @computed
  get isOwner() {
    const { registrant, controller } = resolverStore.meta || {},
      { principal } = sessionStore.walletAuth || {};
    return registrant + '' === principal || controller + '' === principal;
  }

  async componentDidMount() {
    const { name } = this.props;
    if (name) await resolverStore.getMeta(name);
  }

  renderFavorite() {
    const { name, favorite } = this.props,
      { isDesktop } = sessionStore,
      { liking } = domainStore;

    return (
      <SessionBox>
        <IconButton
          name={favorite ? 'heart-fill' : 'heart'}
          color={favorite ? 'danger' : undefined}
          size={isDesktop ? 0.875 : 0.6875}
          disabled={liking}
          onClick={() =>
            favorite
              ? domainStore.removeFavorite(name)
              : domainStore.addFavorite(name)
          }
        />
      </SessionBox>
    );
  }

  renderPanel() {
    const { name, registered } = this.props,
      { currentCard } = this;

    return (
      <>
        <PillNav
          list={CardTypes as unknown as string[]}
          value={currentCard}
          onSelect={card => (this.currentCard = card as CardType)}
        />
        <div className="px-sm-5">
          {currentCard === 'Register' ? (
            registered ? (
              <EmptyCard variant="files">
                {t("domain.card.registered")}
              </EmptyCard>
            ) : (
              <RegisterForm
                name={name}
                onFinish={() => (location.hash = '/account/names')}
              />
            )
          ) : (
            <DomainDetail name={name} />
          )}
        </div>
      </>
    );
  }

  render() {
    const { name, orderId, price, reserved, registered, transfered } =
        this.props,
      { isDesktop } = sessionStore,
      { openPanel, isOwner } = this;
    const viewButton = () => {
      return (
        <Button
          className={classNames(
            styles['outline-purple'],
            !isDesktop ? 'mt-2' : 'ms-3'
          )}
          size="sm"
          variant="aspurple"
          onClick={() => {
            this.currentCard = 'Details';
            this.openPanel = !openPanel;
          }}
        >
          {t("domain.card.View")}
        </Button>
      );
    };
    return (
      <li>
        <div
          className={classNames(
            'overflow-hidden position-relative shadow rounded-pill bg-white py-3 my-4 d-flex align-items-center',
            isDesktop ? 'px-5' : 'px-4',
            styles['list-height']
          )}
        >
          <div className="flex-fill d-flex align-items-center">
            {this.renderFavorite()}

            <h2 className="fs-5 fw-500 my-0 mx-3 text-break">{name}</h2>

            {price && (
              <small className="text-muted">
                {parseToCommon(price, DICP.tokenInfo?.decimals) + ''} DICP
              </small>
            )}
          </div>

          <CustomBadge
            status={
              orderId
                ? NameStatus.OnSale
                : reserved
                ? NameStatus.Reserved
                : registered
                ? NameStatus.Unavailable
                : NameStatus.Available
            }
          />
          {orderId ? (
            <div
              className={classNames(
                'd-flex',
                isDesktop ? 'flex-row' : 'flex-column'
              )}
            >
              <Button size="sm" href={`#/market/domain/${name}/buy/${orderId}`}>
              {t("domain.card.Buy")}
              </Button>
              {viewButton()}
            </div>
          ) : registered ? (
            isOwner ? (
              <div
                className={classNames(
                  'd-flex',
                  isDesktop ? 'flex-row' : 'flex-column'
                )}
              >
                <Button
                  size="sm"
                  variant="outline-warning"
                  href={`#/domain/${name}/profile`}
                >
                  {t("base.Setting")}
                </Button>
                <Button
                  className={classNames(!isDesktop ? 'mt-2' : 'ms-3')}
                  size="sm"
                  variant="outline-primary"
                  href={`#/market/domain/${name}/sale`}
                >
                  {t("base.Sell")}
                </Button>
              </div>
            ) : (
              viewButton()
            )
          ) : transfered ? (
            <Button
              className="ms-3"
              size="sm"
              href={`#/domain/${name}/transfer`}
            >
              {t("domain.card.Claim")}
            </Button>
          ) : !reserved ? (
            <SessionBox>
              <Button
                size="sm"
                onClick={() => {
                  this.currentCard = 'Register';
                  this.openPanel = !openPanel;
                }}
              >
                {t("base.Register")}
              </Button>
            </SessionBox>
          ) : (
            <Button
              variant="link"
              target="_blank"
              href={`https://lake-reward-33f.notion.site/How-to-claim-your-project-name-8938f1b4d0f04335b6ad78dc52536efe`}
            >
              {t("domain.card.HowToClaim")}?
            </Button>
          )}
        </div>
        {openPanel && this.renderPanel()}
      </li>
    );
  }
}