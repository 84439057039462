.custom-badge {
  position: absolute;
  top: 0;
  left: 0;
  border-bottom-right-radius: 6.25rem;
  border-top-left-radius: 6.25rem;
  padding: 2px 30px 3px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;

  @media (max-width: 576px) {
    font-size: 9px;
    padding: 2px 24px 3px;
    line-height: 14px;
  }
}

.list-height {
  height: 5.625rem;

  @media (max-width: 576px) {
    min-height: 4.25rem;
  }
}

.outline-purple {
  border: 1px solid rgba(122, 45, 255, 1) !important;
  color: rgba(122, 45, 255, 1) !important;
  border-radius: 6px !important;
  font-weight: 500 !important;

  &:hover {
    color: white !important;
    background-color: rgba(122, 45, 255, 1) !important;
  }
}
