import classNames from 'classnames';
import { FC, HTMLAttributes } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import { Color } from 'react-bootstrap/esm/types';
import { Icon, IconProps } from 'idea-react';

export interface IconButtonProps
  extends Omit<IconProps, keyof HTMLAttributes<HTMLSpanElement>>,
    Omit<ButtonProps, 'name' | 'size'> {
  color?: Color;
  round?: boolean;
}

export const IconButton: FC<IconButtonProps> = ({
  className,
  style,
  color = 'dark',
  round,
  name,
  size = 1.25,
  ...props
}) => (
  <Button
    className={classNames(
      'shadow',
      'border-0',
      round && 'rounded-circle',
      'bg-white',
      `text-${color}`,
      'd-inline-flex',
      'justify-content-center',
      'align-items-center',
      className
    )}
    style={{
      width: size * 2 + 'rem',
      height: size * 2 + 'rem',
      ...style
    }}
    {...props}
  >
    <Icon name={name} size={size} />
  </Button>
);
