import { IReactionDisposer, observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { PureComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-class-tools';
import { Button, Form, Image } from 'react-bootstrap';
import { EdgePosition } from 'idea-react';
import { Second } from 'web-utility';
import { debounce } from 'lodash';
import classNames from 'classnames';
import { t } from 'i18next';
import { PillNav } from '../../component/PillNav';
import { IconInput } from '../../component/IconInput';
import { EmptyCard } from '../../component/EmptyCard';
import { MainLayout } from '../../component/MainLayout';
import { DomainList } from '../../component/DomainList';
import { DomainCard } from '../../component/Domain/Card';
import { AddressBox } from '../../component/AddressBox';
import { LoadingFullCon } from '../../component/LoadingFullPage';
import { AssetList } from './Asset/AssetList';
import logo from '../../image/logo.png';

import { DomainSuffix, DomainSuffixs } from '../../model/utils/config';
import sessionStore from '../../model/Session';
import assetStore from '../../model/Asset';
import registrarDomain from '../../model/Domain';
import registryDomain from '../../model/RegistryDomain';
import { DomainOwnership, DomainOwnerships } from '../../model/Resolver';
import * as style from './index.module.less';
import { Trans } from 'react-i18next';

export const AssetTypes = ['names', 'favorites', 'assets'] as const;

export type AssetType = typeof AssetTypes[number];

@observer
class AccountPage extends PureComponent<
RouteComponentProps<{ asset: AssetType }>
> {
  @observable
  assetType?: AssetType;

  @observable
  domainOwnership?: DomainOwnership;

  @observable
  isSelling = false;

  @observable
  keyword?: string;

  private disposers: IReactionDisposer[] = [];

  componentDidMount() {
    this.disposers.push(
      reaction(
        () => this.assetType,
        type => {
          switch (type) {
            case 'names':
              this.domainOwnership = 'registrant';
              break;
            case 'favorites':
              registrarDomain.getFavoriteList();
          }
        }
      ),
      reaction(() => this.domainOwnership, this.switchType)
    );
    this.assetType = this.props.match.params.asset;
  }

  componentDidUpdate(
    prevProps: Readonly<RouteComponentProps<{ asset: AssetType }>>
  ) {
    const { asset } = this.props.match.params;

    if (asset === prevProps.match.params.asset) return;

    this.domainOwnership = void 0;
    this.assetType = asset;
  }

  componentWillUnmount() {
    for (const disposer of this.disposers) disposer();

    registrarDomain.clear();
    registryDomain.clear();
  }

  loadNextOwns(index?: number, changeSuffix = false) {
    const { principal } = sessionStore.walletAuth;

    switch (this.domainOwnership) {
      case 'registrant': {
        if (registrarDomain.downloading) return;

        if (changeSuffix) registrarDomain.switchSuffix(registryDomain.suffix);
        else if (index === 1) registrarDomain.clear();

        if (index || !registrarDomain.noMore)
          return registrarDomain.getList(principal, index);
        break;
      }
      case 'controller': {
        if (registryDomain.downloading) return;

        if (changeSuffix) registryDomain.switchSuffix(registrarDomain.suffix);
        else if (index === 1) registryDomain.clear();

        if (index || !registryDomain.noMore)
          return registryDomain.getList(principal, index);
      }
    }
  }
  loadMore = debounce(
    (edge: EdgePosition) => edge === 'bottom' && this.loadNextOwns(),
    Second
  );

  switchType = () =>
    this.loadNextOwns(1, registrarDomain.suffix !== registryDomain.suffix);

  switchSuffix = (value: string) => {
    const suffix = value.replace(/^\./, '') as DomainSuffix;

    switch (this.domainOwnership) {
      case 'registrant':
        registrarDomain.switchSuffix(suffix);
        break;
      case 'controller':
        registryDomain.switchSuffix(suffix);
    }
    return this.loadNextOwns(1);
  };

  renderHeader() {
    const { principal, accountId } = sessionStore.walletAuth || {},
      { isDesktop } = sessionStore;

    return (
      <>
        {!isDesktop && (
          <div className="mb-3 text-center">
            <Image
              className="rounded-circle outer-shadow me-3 p-2"
              style={{ width: '5rem' }}
              src={logo}
            />
          </div>
        )}
        <header
          className={classNames(
            'round-card d-flex align-items-center overflow-hidden',
            isDesktop ? 'px-5 justify-content-between text-nowrap' : 'px-4'
          )}
        >
          {isDesktop && (
            <div className="text-center">
              <Image
                className="rounded-circle outer-shadow bg-white p-2"
                style={{ width: '5rem', zIndex: '5' }}
                src={logo}
              />
            </div>
          )}
          <ul
            className={classNames(
              'list-inline m-0 small text-muted w-100',
              isDesktop && 'd-flex justify-content-end'
            )}
          >
            <li
              className={classNames(
                'list-inline-item d-inline-flex align-items-center justify-content-between',
                isDesktop ? 'me-4' : 'w-100 mb-3'
              )}
            >
              <span className="me-4">Principal ID</span>
              <div>
                <AddressBox>{principal}</AddressBox>
              </div>
            </li>
            <li
              className={classNames(
                'list-inline-item d-inline-flex align-items-center justify-content-between',
                !isDesktop && 'w-100'
              )}
            >
              <span className="me-4">Account ID</span>
              <div>
                <AddressBox>{accountId}</AddressBox>
              </div>
            </li>
          </ul>
        </header>
      </>
    );
  }

  renderDomains = () => {
    const { domainOwnership, keyword, isSelling } = this,
      { principal } = sessionStore.walletAuth || {};
    const { downloading } = registrarDomain,
      loading = !!downloading || !!registryDomain.downloading || !!assetStore.downloading;
    var { suffix, noMore, list } =
      domainOwnership === 'registrant' ? registrarDomain : registryDomain;

    if (keyword) list = list.filter(({ name }) => name.includes(keyword));

    return (
      <>
        <Form
          className="d-flex align-items-center position-relative"
          onSubmit={event => event.preventDefault()}
        >
          <div className="w-100">
            <IconInput
              className="d-inline-flex w-auto my-4 me-3 py-1 bg-white float-start"
              type="search"
              name="keyword"
              icon="search"
              size="sm"
              placeholder={t("account.placeholder")}
              onChange={({ currentTarget }) =>
                (this.keyword = currentTarget.value.trim())
              }
            />
            <div className="float-start d-flex">
              <PillNav
                className={classNames('shadow me-3', style['custom-pillnav'])}
                list={DomainSuffixs}
                value={`.${suffix}`}
                onSelect={this.switchSuffix}
              />
              <PillNav
                className={classNames(
                  'shadow text-capitalize',
                  style['custom-pillnav']
                )}
                list={DomainOwnerships as unknown as string[]}
                value={domainOwnership}
                onSelect={value =>
                  (this.domainOwnership = value as DomainOwnership)
                }
              />
            </div>
          </div>

          <div className={style.mySale}>
            {domainOwnership === 'registrant' && (
              <div className="shadow rounded-3 bg-white p-2 text-nowrap">
                <Form.Check
                  type="checkbox"
                  id="my-scale"
                  label={t("account.MySale")}
                  onChange={() => (this.isSelling = !isSelling)}
                />
              </div>
            )}
          </div>
        </Form>

        <DomainList
          controls
          owner={principal}
          type={domainOwnership}
          selling={isSelling}
          list={list}
          noMore={noMore}
          loadMore={this.loadMore}
          loading={loading}
        />
      </>
    );
  };

  render() {
    const { assetType } = this,
      { downloading, list } = registrarDomain;
    const loading =
      !!downloading || !!registryDomain.downloading || !!assetStore.downloading;

    return (
      <MainLayout needSession loading={loading}>
        {this.renderHeader()}

        <div className={classNames('mt-4 fs-14 text-break', style.migration)}>
        <Trans
            t={t}
            i18nKey="account.nnsTips"
            components={[
              <Button
                className="border-0 bg-transparent p-0 fs-14"
                variant="link"
                href="#/migration"
              />
            ]}
          />
        </div>
        <PillNav
          className="text-capitalize"
          list={AssetTypes as unknown as string[]}
          value={assetType}
          onSelect={value => (location.hash = `/account/${value}`)}
        />
        {assetType === 'assets' ? (
          <AssetList loading={loading} />
        ) : assetType === 'names' ? (
          this.renderDomains()
        ) : list[0] ? (
          <ul className="list-unstyled">
            {list.map(
              item => item.favorite && <DomainCard key={item.name} {...item} />
            )}
          </ul>
        ) : (
          <div className="position-relative">
            {loading && <div className="h-100 w-100 position-absolute" style={{background:"rgba(255,255,255,0.5)"}}><LoadingFullCon /></div>}
            <EmptyCard variant="box" />
          </div>
        )}
      </MainLayout>
    );
  }
}

export default withRouter(AccountPage);
