import { WalletConnectError, WalletConnectErrorCode } from '../../exception';
import { principalToAccountID } from '../../helper';
import { IWalletConnector, WalletAuth, WalletType } from '..';
import { IDL } from '@dfinity/candid';
import { Principal } from '@dfinity/principal';
import icpbox from './icpbox-sdk/index';

export class ICPBoxConnector implements IWalletConnector {
  public type = WalletType.ICPBox;
  public connected: boolean = false;

  private whiteList: Array<string> = [];
  private icHost: string;
  private dev: boolean;
  constructor(icHost: string, whitelist: string[] = [], dev: boolean = false) {
    if (dev) {
      throw new Error('ICPBoxConnector is not supported in dev mode');
    }
    this.icHost = icHost;
    this.whiteList = whitelist;
    this.dev = dev;
  }
  connect = async (): Promise<WalletAuth> => {
    try {
      this.connected = await icpbox.isConnected();

      console.debug('icpbox:auto authorize');
      const authData: any = await icpbox.authorize({
        canisters: this.whiteList
      });
      console.debug('auth ok: ', authData);
      icpbox.setPublicKey(authData.publicKey);
      const accountId = principalToAccountID(
        Principal.fromText(authData.principal)
      );
      return {
        type: WalletType.ICPBox,
        principal: authData.principal,
        accountId
      };
    } catch (error) {
      console.error('icpbox error', error);
    }
  };

  createActor = async <T>(
    canisterId: string,
    idlFactory: IDL.InterfaceFactory
  ) => {
    if (!this.connected) {
      throw new WalletConnectError(
        WalletConnectErrorCode.ICPBoxConnectFailed,
        `ICPBoxConnector.createActor: check connect failed`
      );
    }

    return icpbox.createActor<T>({
      canisterId,
      interfaceFactory: idlFactory
    });
  };

  disconnect = async () => {
    icpbox.disConnect();
  };
}
