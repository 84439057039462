import { observer } from 'mobx-react';
import { PureComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import { t } from 'i18next';

import { CopyBox } from './CopyBox';
import { EmptyCard } from './EmptyCard';
import { englishDate, formatAddress } from '../model/utils/helper';
import resolverStore from '../model/Resolver';

export interface DomainDetailProps {
  className?: string;
  name: string;
}

@observer
export class DomainDetail extends PureComponent<DomainDetailProps> {
  componentDidMount() {
    const { name } = this.props;

    resolverStore.getMeta(name).catch(console.warn);
  }

  componentWillUnmount() {
    resolverStore.clear();
  }

  renderMeta() {
    const { className } = this.props;
    const {
      registrant,
      controller,
      resolver,
      expiredAt = 0
    } = resolverStore.meta || {};

    return (
      <Row
        className={classNames(
          'round-card px-sm-5 py-4 align-items-center mx-1',
          className
        )}
      >

        <Col className="text-muted my-3" xs={4}>
          {t("base.registrant")}
        </Col>
        <Col as="strong" xs={8} className="d-flex justify-content-end">
          <CopyBox value={registrant + ''}>
            {registrant ? formatAddress(registrant + '') : '--'}
          </CopyBox>
        </Col>
        <Col className="text-muted my-3" xs={4}>
          {t("base.controller")}
        </Col>
        <Col as="strong" xs={8} className="d-flex justify-content-end">
          <CopyBox value={controller + ''}>
            {controller ? formatAddress(controller + '') : '--'}
          </CopyBox>
        </Col>
        <Col className="text-muted my-3" xs={4}>
          {t("base.Expiration")}
        </Col>
        <Col as="strong" xs={8} className="d-flex justify-content-end">
          {expiredAt ? englishDate.format(+(expiredAt + '')) : '--'}
        </Col>
        <Col className="text-muted my-3" xs={4}>
          {t("base.Resolver")}
        </Col>
        <Col as="strong" xs={8} className="d-flex justify-content-end">
          <CopyBox value={resolver + ''}>
            {resolver ? formatAddress(resolver + '') : '--'}
          </CopyBox>
        </Col>
      </Row>
    );
  }

  render() {
    return resolverStore.meta ? (
      this.renderMeta()
    ) : (
      <EmptyCard variant="media">
        This domain name has not been registered yet.
        <br />
        You can switch to '<span className="text-primary">Register</span>' tab
        to register this domain name.
      </EmptyCard>
    );
  }
}
