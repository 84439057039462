import classNames from 'classnames';
import { FC, HTMLAttributes, InputHTMLAttributes } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';

export type RangeData<N extends string> = {
  [key in 'Start' | 'End' as `${N}${key}`]: number;
};

export interface RangeInputProps
  extends Omit<FormControlProps, 'value' | 'onChange'>,
    Omit<
      InputHTMLAttributes<HTMLInputElement>,
      | keyof HTMLAttributes<HTMLInputElement>
      | 'type'
      | 'value'
      | 'size'
      | 'onChange'
    > {
  value?: [number?, number?];
  onChange?: (value: [number?, number?]) => any;
}

export const RangeInput: FC<RangeInputProps> = ({
  className,
  style,
  name,
  value: [start, end] = [],
  onChange,
  ...props
}) => (
  <>
    <Form.Control
      {...props}
      className={classNames('d-inline-block', 'me-2', className)}
      style={{ width: 'auto', ...style }}
      name={`${name}Start`}
      max={end}
      defaultValue={props.min}
      value={start}
      onChange={({ currentTarget: { value } }) =>
        onChange?.([value && +value, end])
      }
    />
    ~
    <Form.Control
      {...props}
      className={classNames('d-inline-block', 'ms-2', className)}
      style={{ width: 'auto', ...style }}
      name={`${name}End`}
      min={start}
      defaultValue={props.max}
      value={end}
      onChange={({ currentTarget: { value } }) =>
        onChange?.([start, value && +value])
      }
    />
  </>
);
