"use strict";
exports.__esModule = true;
exports.init = exports.idlFactory = void 0;
var idlFactory = function (_a) {
    var IDL = _a.IDL;
    var CanisterNames = IDL.Variant({
        'NamingMarketplace': IDL.Null,
        'RegistrarControlGateway': IDL.Null,
        'DICP': IDL.Null,
        'CyclesMinting': IDL.Null,
        'Registrar': IDL.Null,
        'MysteryBox': IDL.Null,
        'Registry': IDL.Null,
        'Ledger': IDL.Null,
        'Favorites': IDL.Null,
        'Resolver': IDL.Null
    });
    var InitArgs = IDL.Record({
        'dev_named_canister_ids': IDL.Vec(IDL.Tuple(CanisterNames, IDL.Principal))
    });
    var ErrorInfo = IDL.Record({ 'code': IDL.Nat32, 'message': IDL.Text });
    var BooleanActorResponse = IDL.Variant({
        'Ok': IDL.Bool,
        'Err': ErrorInfo
    });
    var StateExportData = IDL.Record({ 'state_data': IDL.Vec(IDL.Nat8) });
    var StateExportResponse = IDL.Variant({
        'Ok': StateExportData,
        'Err': ErrorInfo
    });
    var GetFavoritesResponse = IDL.Variant({
        'Ok': IDL.Vec(IDL.Text),
        'Err': ErrorInfo
    });
    var Stats = IDL.Record({
        'user_count': IDL.Nat64,
        'cycles_balance': IDL.Nat64,
        'favorite_count': IDL.Nat64
    });
    var GetStatsResponse = IDL.Variant({ 'Ok': Stats, 'Err': ErrorInfo });
    var HttpRequest = IDL.Record({
        'url': IDL.Text,
        'method': IDL.Text,
        'body': IDL.Vec(IDL.Nat8),
        'headers': IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text))
    });
    var Token = IDL.Record({
        'key': IDL.Text,
        'sha256': IDL.Opt(IDL.Vec(IDL.Nat8)),
        'index': IDL.Nat,
        'content_encoding': IDL.Text
    });
    var CallbackStrategy = IDL.Record({
        'token': Token,
        'callback': IDL.Func([], [], [])
    });
    var StreamingStrategy = IDL.Variant({ 'Callback': CallbackStrategy });
    var HttpResponse = IDL.Record({
        'body': IDL.Vec(IDL.Nat8),
        'headers': IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
        'streaming_strategy': IDL.Opt(StreamingStrategy),
        'status_code': IDL.Nat16
    });
    return IDL.Service({
        'add_favorite': IDL.Func([IDL.Text], [BooleanActorResponse], []),
        'export_state': IDL.Func([], [StateExportResponse], []),
        'get_favorites': IDL.Func([], [GetFavoritesResponse], ['query']),
        'get_stats': IDL.Func([], [GetStatsResponse], ['query']),
        'get_wasm_info': IDL.Func([], [IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text))], ['query']),
        'http_request': IDL.Func([HttpRequest], [HttpResponse], ['query']),
        'load_state': IDL.Func([StateExportData], [BooleanActorResponse], []),
        'remove_favorite': IDL.Func([IDL.Text], [BooleanActorResponse], [])
    });
};
exports.idlFactory = idlFactory;
var init = function (_a) {
    var IDL = _a.IDL;
    var CanisterNames = IDL.Variant({
        'NamingMarketplace': IDL.Null,
        'RegistrarControlGateway': IDL.Null,
        'DICP': IDL.Null,
        'CyclesMinting': IDL.Null,
        'Registrar': IDL.Null,
        'MysteryBox': IDL.Null,
        'Registry': IDL.Null,
        'Ledger': IDL.Null,
        'Favorites': IDL.Null,
        'Resolver': IDL.Null
    });
    var InitArgs = IDL.Record({
        'dev_named_canister_ids': IDL.Vec(IDL.Tuple(CanisterNames, IDL.Principal))
    });
    return [IDL.Opt(InitArgs)];
};
exports.init = init;
