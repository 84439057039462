import { FC } from 'react';

import { CopyBox } from './CopyBox';
import { formatAddress } from '../model/utils/helper';

export const AddressBox: FC<{ className?: string }> = ({
  className = 'text-dark',
  children
}) =>
  children ? (
    <CopyBox className={className} value={children + ''}>
      {formatAddress(children + '')}
    </CopyBox>
  ) : null;
