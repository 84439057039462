import { action, computed, observable } from 'mobx';

import { Language } from './Language';
import { BaseModel, toggle } from './Base';
import { withLogging } from './utils/errorLogger';
import { ICConnector, WalletAuth, WalletType } from './utils/connector';
import { IC_HOST, isLocalEnv, whiteLists } from './utils/config';

const WALLET_AUTH = 'walletAuth';

export enum BlockNetwork {
  Mainnet = '0',
  Testnet = '1'
}

export class SessionModel extends BaseModel {
  @observable
  isDesktop = false;

  @observable
  isLaptop = false;

  @observable
  title = document.title;

  @observable
  path = '';

  @observable
  currentLanguage: Language = 'en_GB';

  @observable
  network: BlockNetwork = BlockNetwork.Mainnet;

  @observable
  private localAuth?: WalletAuth;

  private static connector = new ICConnector(IC_HOST, whiteLists, isLocalEnv());

  constructor() {
    super();
    this.restoreSession();
    window.addEventListener('resize', this.detectWindow);
    window.addEventListener('hashchange', this.detectWindow);
  }

  @computed get walletAuth() {
    return this.localAuth;
  }

  @computed get isConnected() {
    return !!this.localAuth;
  }

  get walletConnector() {
    return SessionModel.connector;
  }

  public detectWindow = () => {
    this.isDesktop = window.innerWidth >= 576;
    this.isLaptop = window.innerWidth < 1440;
    this.path = location.hash.slice(2);
  };

  public turnTo(path: string, title = document.title) {
    this.title = title;
  }

  @toggle('uploading')
  public switchLanguage(lang: Language) {
    this.currentLanguage = lang;
    this.saveToStorage('language', lang);
    window.location.reload();
  }

  public switchNetwork(network: BlockNetwork) {
    this.network = network;
    this.saveToStorage('network', network);
  }

  async autoConnectIcpBoxWallet() {
    const user_agent = navigator.userAgent.toLowerCase();
    if (user_agent.indexOf('icpbox') === -1) return;
    this.connectWallet(WalletType['ICPBox']);
  }

  @toggle('uploading')
  async connectWallet(type: WalletType) {
    this.localAuth = await SessionModel.connector.connect(type);

    sessionStorage.setItem(WALLET_AUTH, JSON.stringify(this.localAuth));
  }

  @withLogging
  disconnectWallet() {
    if (!this.walletAuth)
      return console.debug(`wallet auth is null, it means disconnected`);

    SessionModel.connector.disconnect(this.walletAuth.type);
    sessionStorage.clear();
    this.clearAuth();
  }

  @action
  private restoreSession() {
    this.detectWindow();
    this.currentLanguage = this.loadFromStorage<Language>('language') || 'en_GB';
    this.network = this.loadFromStorage('network') || BlockNetwork.Mainnet;

    const storeVal = sessionStorage.getItem(WALLET_AUTH);

    if (storeVal) this.localAuth = JSON.parse(storeVal);
    else this.autoConnectIcpBoxWallet();
  }

  private clearAuth() {
    sessionStorage.removeItem(WALLET_AUTH);
    location.replace('/');
  }

  private saveToStorage(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  private loadFromStorage<T>(key: string) {
    const value = localStorage.getItem(key);

    return value && (JSON.parse(value) as T);
  }
}

export default new SessionModel();
