{
    "appName":"icNaming",
    "base":{
        "names":"Names",
        "favorites":"Favorites",
        "assets":"Assets",
        "asset":"Asset",
        "Logout":"Logout",
        "Name":"Name",
        "registrant":"registrant",
        "controller":"controller",
        "Register":"Register",
        "Details":"Details",
        "Expiration":"Expiration Date",
        "Resolver":"Resolver",
        "Quota":"Quota",
        "Buy":"Buy",
        "Sell":"Sell",
        "Price":"Price",
        "Amount":"Amount",
        "Item":"Item",
        "Cost":"Cost",
        "balance":"balance",
        "wrap":"Wrap ICP",
        "unwrap":"Unwrap DICP",
        "Cancel":"Cancel",
        "Confirm":"Confirm",
        "Submit":"Submit",
        "connect": "connect",
        "BuyName":"Buy Name",
        "BuyQuota":"Buy Quota",
        "SellName":"Sell Name",
        "SellQuota":"Sell Quota",
        "Setting":"Setting",
        "Set":"Set",
        "transfer":"transfer",
        "chars":"-chars",
        "type":"Type",
        "Fixed":"Fixed",
        "Renew":"Renew",
        "EnterAmount":"Enter amount",
        "EnterPrice":"Enter price",
        "Save":"Save",
        "Delete":"Delete",
        "NotSet":"Not Set",
        "fee":"Fee",
        "Tips":"Tips",
        "Address":"Address",
        "to":"to",
        "from":"from"
    },
    "header": {
        "docs":"Docs",
        "blindBox":"BlindBox",
        "market":"Market"
    },
    "sessionBox":{
        "Select":"Select a wallet",
        "title":"Please select a wallet to connect to this dapp:",
        "TryAgain":"Try again",
        "usedIIBefore":"I used II before?"
    },
    "home": {
        "slogan" : "Decentralized Digital Identities"
    },
    "domainSearch":{
        "search":"Search",
        "placeholder":"Search names or address"
    },
    "domain" : {
        "card" : {
            "Buy":"Buy",
            "View":"View",
            "Sell":"Sell",
            "Claim":"Claim",
            "HowToClaim":"How to claim",
            "registered":"This name is already registered."
        },
        "CustomBadge":{
            "Available": "Available",
            "Unavailable": "Unavailable",
            "Reserved": "Reserved",
            "OnSale": "For sale by owner",
            "ForSaleByOwner":"For sale by owner"
        },
        "sell":{
            "Confirmation":"Confirmation",
            "ThinkAgain":"Think again",
            "ConfirmCancel":"Confirm cancel",
            "AreYouSure":"Are you sure to cancel the sale of the following item?"
        }
    },
    "registerForm":{
        "RegistrationPeriod":"Registration Period",
        "years":"years",
        "year":"Year",
        "RegistrationPrice":"Registration Price",
        "Best":"Best",
        "TotalPrice":"Total price",
        "What":"What's",
        "question":"3-5 chars names can only register through purchasing quotas",
        "getQuota":"Get quota",
        "RenewPeriod":"Renew Period",
        "questionTitle1":"What's quota?",
        "questionTitle2":"How to get quota?",
        "questionTitle3":"Blind box",
        "questionDesc1":"You need to use quota when registering a 3-5-chars name. Names with different chars that can be registered using quotas, and there are different types of quota. For example, 4-chars quota can not register 3-chars names.",
        "questionDesc2":"Buying a blind box will contain a quota and some DNS tokens, and the probability of getting different chars quotas varies. The specific probability chart is displayed on the blind box purchase interface. You can click the following link to purchase the <0>blind box</0>. Quota can also be purchased from the <1>market</1>. Quotas in the market are sold by other users.",
        "questionDesc3":"Blind box is a better way to distribute names.The following link is the purchase page and detailed introduction of blind box:",
        "whatBlindBox":"what's blind box?",
        "whyBlindBox":"why blind box?"
    },
    "market":{
        "Filter":"Filter",
        "Items":"Items",
        "All":"All",
        "Length":"Length",
        "SeeMySale":"See My Sale",
        "Down":"Down",
        "placeholder":"Search names",
        "OwnedBy":"Owned by",
        "PurchaseConfirmation":"Purchase Confirmation",
        "PurchaseTips":"Are you sure to purchase the following item?"
    },
    "account":{
        "Receive":"Receive",
        "nnsTips":"If you have purchased names by linking the II wallet(NNS) before,<0>click me to complete the migration.</0>",
        "Expires":"Expires",
        "ViewSelling":"View selling",
        "MySale" : "My sale",
        "placeholder":"Search names",
        "names":"Names",
        "Frozen":"Frozen",
        "Available":"Available",
        "Action":"Action",
        "feeTips":"DICP wrap fee (0.0001 ICP)is charged by ledger when users transfer ICP. IC Naming does not charge any fees when wrap/unwrap DICP.",
        "SelectAssets":"Select Assets",
        "DestinationAddress":"Destination address",
        "InsufficientBalance":"Insufficient balance",
        "EnterAddressOrName":"Enter Address / Name",
        "EnterAmount":"Enter amount",
        "quotaDialogTitle":"What's quota?",
        "quotaDialogDesc":"You need to use quota when registering a 3-5-chars name. Names with different chars that can be registered using quotas, and there are different types of quota. For example, The 3-chars quota can be used to register names greater than or equal to 3 digits ,but 4-chars quota can not register 3-chars names."
    },
    "nameSet":{
        "menu":{
            "Profile":"Profile",
            "SocialMedia":"Social media",
            "Crypto":"Crypto",
            "Canister":"Canister",
            "Link":"Link",
            "TextRecord":"Text record",
            "Transfer":"Transfer",
            "Renew":"Renew",
            "Resolver":"Resolver",
            "ReverseRecord":"Reverse record"
        },
        "tryItNow":"try it now",
        "EditProfile":"Edit Profile",
        "SetAvatar":"Set a NFT/Gravatar as my avatar",
        "DisplayName":"Display name",
        "Email":"Email",
        "Location":"Location",
        "Description":"Description",
        "reclaim":"Reclaim",
        "noOwnController":"The controller of this name has been transferred. To set up,please reclaim first.",
        "SetYourReverseRecord":"Set your Reverse record",
        "reverseRecordTips":"Do you want other people to get your name through your principal ID? After set up your primary name, others can use the principal ID to check your name.",
        "yourDecentralized":"Access your decentralized website",
        "canisterTips1":"Do you have a decentralized website deployed on IC? if you have, set the canister id of the website here, then you can access it by url",
        "canisterTips2":"is an example of a decentralized website deployed on IC",
        "hasUrlNotice1Title":"Confirm settings",
        "hasUrlNotice":"You have set the URL, if you set the canister id, visiting <bikeUrl>{{bikeUrl}}</bikeUrl> will visit the decentralized site you deployed on IC, instead of the <url>{{url}}</url> you set",
        "yourWebsite":"Link to your web2/web3 website through url:",
        "urlTips1":"Do you have a website now? if you have, set the url of the website here, then you can access it by url",
        "urlNotice":"(Notice: you have set the canister id, it's will access the canister website instead of your url)",
        "hasBeenTransferred":"The registrant of the name has been transferred." 
    },
    "dangerConfirm":{
        "tips":"Are you sure you want to transfer to the following address?",
        "transferRegistrant":{
            "title":"Transfer registrant",
            "note":"Note: Transfer registrant means you are no longer the owner of the name,it cannot be cancelled once the transfer completed."
        },
        "transferController":{
            "title":"Transfer controller",
            "note":"Note: Transfer controller only means transferring control, you are still the owner of the name and can take back control at any time by [Reclaim]."
        },
        "reclaim":{
            "title":"reclaim controller"
        },
        "transferToken":{
            "title":"Transfer the token",
            "note":"Note: It cannot be cancelled once the transfer completed."
        }
    },
    "migration":{
        "desc":"ICNaming removed support for Internet Identity. If you have logged in with Internet Identity and purchased names before, you can complete the migration in this page.",
        "MigrationSteps":"Migration steps",
        "step1":"Connect to Internet Identity and the target wallet you want to migrate to",
        "step2":"Select items to migrate",
        "step3":"Complete the migration steps",
        "Connect":"Connect",
        "ConnectII":"Connect to Internet Identity",
        "ConnectTargetWallet":"Connect the target wallet",
        "connectTips":"Please connect Internet Identity wallet and target wallet first.",
        "SelectItems":"Select items to migrate",
        "Migrate":"Migrate"
    },
    "blindBox":{
        "ConnectWallet":"Connect wallet",
        "YourBalance":"Your balance",
        "RewardTips":"Buying a blind box has a chance of getting the following items",
        "Reward":"Reward",
        "Chance":"Chance",
        "Remark":"Remark",
        "Reasons":"Reasons",
        "ReasonsTips":"Introducing a new way to explore the possibilities of name service, Which carry out our core beliefs: fairness.Not only that, the blind box works on a mechanism of uncertainty, which creates a lot of fun when users get involved. Also, our initial probability design creates a higher chance for users to get more quotas and rare quotas with minimum spending that the price is reasonable in the market."
    },
    "errorInfo":{
        "inputError":"Your input is illegal Value",
        "notFoundAddress":"This crypto address cannot be found."
    },
    "toast":{
        "SaleSuccessfully":"Sale Successfully",
        "CancelledSuccessfully":"Cancelled Successfully",
        "exceedPendingOrders":"Can't exceed the number of pending orders",
        "WrapSuccessful":"Wrap Successful",
        "UnWrapSuccessful":"UnWrap Successful",
        "SaveSuccessful":"Save successful!",
        "SetSuccessfully":"Set Successfully",
        "MigrateSuccess":"Migrate Successfully"
    },
    "cheap":{
        "FindCheap":"Found a cheap quota in the market <0>Save {{cheapPrice}} DICP to register</0>",
        "FindOne":"Found a {{quota}}-chars quota in the market <0>{{cheapPrice}} DICP Buy & register</0>",
        "BuyQuotaToRegTitle":"Buy Quota & Register",
        "RegTitle":"Register",
        "you":"yourself",
        "savings":"Savings of {{cheap}} DICP"
    }
}