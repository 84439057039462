.spinner {
  animation: rotate 1s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -1.5rem 0 0 -1.5rem;
  width: 3rem;
  height: 3rem;
  background: url('../../image/logo.png') no-repeat;
  background-size: contain;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
