import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FormEvent, PureComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-class-tools';
import { Col, Form, Button } from 'react-bootstrap';
import classNames from 'classnames';

import { RangeInput } from '../../../component/RangeInput';
import { SubPageLayout } from '../../../component/SubPageLayout';
import { DomainCard } from '../../../component/Domain/Card';
import * as styles from './index.module.less';
import { REGISTRABLE_NAME_MIN_LENGTH } from '../../../model/utils/config';
import domainStore from '../../../model/Domain';
import sessionStore from '../../../model/Session';

@observer
class FeelLuckyPage extends PureComponent<
  RouteComponentProps<{ keyword: string }>
> {
  @observable
  lengthRange: [number?, number?] = [REGISTRABLE_NAME_MIN_LENGTH, 10];

  componentDidMount() {
    domainStore.getLuckyList(...this.lengthRange);
  }

  componentWillUnmount() {
    domainStore.clear();
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    this.componentDidMount();
  };

  render() {
    const { lengthRange } = this,
      { lucking, maxLuckyLength, list } = domainStore,
      { isDesktop } = sessionStore;

    return (
      <SubPageLayout title="feel lucky" loading={lucking}>
        <Form className="row" onSubmit={this.handleSubmit}>
          <Col xs={8}>
            <Form.Label className={classNames(isDesktop ? 'fs-18' : 'fs-14')}>
              Length range
            </Form.Label>
            <span className="ms-2">
              <RangeInput
                className={`bg-transparent text-primary ${styles.range}`}
                type="number"
                name="length"
                min={3}
                max={maxLuckyLength}
                value={lengthRange}
                onChange={value => (this.lengthRange = value)}
              />
            </span>
          </Col>
          <Col xs={4} className="d-flex align-items-center">
            <Button
              type="submit"
              className={classNames(
                'rounded-pill',
                isDesktop ? 'px-5 fs-16' : 'px-2 fs-12'
              )}
            >
              Feel lucky
            </Button>
          </Col>
        </Form>
        <ul className="list-unstyled">
          {list.map(item => (
            <DomainCard key={item.name} {...item} />
          ))}
        </ul>
      </SubPageLayout>
    );
  }
}

export default withRouter(FeelLuckyPage);
