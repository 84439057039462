import { IDL } from '@dfinity/candid';
import { Actor, HttpAgent, Identity } from '@dfinity/agent';

import { WalletConnectError, WalletConnectErrorCode } from '../../exception';
import { principalToAccountID } from '../../helper';
import { IWalletConnector, WalletAuth, WalletType } from '..';
import {
  ICP_FAVORITES_ID,
  ICP_HOST,
  ICP_REGISTRAR_ID,
  ICP_REGISTRY_ID,
  ICP_RESOLVER_ID,
  IC_HOST
} from '../../config';
import { AuthClient } from '@dfinity/auth-client';

export class InternetIdentityConnector implements IWalletConnector {
  public type = WalletType.InternetIdentity;
  public connected = false;

  private icHost: string;
  private whiteList: string[] = [];
  private dev: boolean;
  private identity?: Identity;
  private ic_agent?: HttpAgent;
  private icp_agent?: HttpAgent;

  constructor(icHost: string, whitelist: string[] = [], dev = false) {
    this.icHost = icHost;
    this.whiteList = whitelist;
    this.dev = dev;
  }

  private getAgent = (canisterId: string) => {
    const ids = [
      ICP_REGISTRAR_ID,
      ICP_REGISTRY_ID,
      ICP_RESOLVER_ID,
      ICP_FAVORITES_ID
    ];

    if (ids.some(id => id === canisterId)) {
      if (!this.icp_agent)
        this.icp_agent = new HttpAgent({
          host: ICP_HOST,
          identity: this.identity
        });
      return this.icp_agent;
    } else {
      if (!this.ic_agent) {
        this.ic_agent = new HttpAgent({
          host: IC_HOST,
          identity: this.identity
        });
        this.ic_agent.fetchRootKey().catch(e => {
          console.error(`fetchRootKey error: ${e}`);
        });
      }
      return this.ic_agent;
    }
  };

  connect = async (): Promise<WalletAuth> => {
    return new Promise(async (resolve, reject) => {
      const getIdentityAndResolve = async (ac: AuthClient) => {
        this.identity = await ac.getIdentity();
        const principal = this.identity.getPrincipal();
        const accountId = principalToAccountID(principal);
        resolve({
          type: WalletType.InternetIdentity,
          principal: principal.toText(),
          accountId
        });
      };
      try {
        const authClient = await AuthClient.create();
        if (await authClient.isAuthenticated()) {
          await getIdentityAndResolve(authClient);
        } else {
          await authClient.login({
            onSuccess: () => {
              return getIdentityAndResolve(authClient);
            },
            onError: async () => {
              reject(
                new WalletConnectError(
                  WalletConnectErrorCode.InternetIdentityConnectFailed,
                  `II connect failed`
                )
              );
            }
          });
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  createActor = async <T>(
    canisterId: string,
    idlFactory: IDL.InterfaceFactory
  ) => {
    const agent = this.getAgent(canisterId);

    return Actor.createActor<T>(idlFactory, {
      agent,
      canisterId
    });
  };

  disconnect = async () => {
    const authClient = await AuthClient.create();
    authClient.logout();
  };
}
