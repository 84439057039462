import * as style from './index.module.less';
export const LoadingFullPage = () => (
  <div
    className="fixed-top h-100 w-100 d-flex justify-content-center align-items-center"
    style={{ background: 'rgba(0, 0, 0, 0.5)' }}
  >
    <div className={style.spinner} />
  </div>
);
export const LoadingFullCon = () => (
  <div
    className="py-4 h-100 w-100 d-flex justify-content-center align-items-center position-relative"
  >
    <div className={style.spinner} />
  </div>
);
