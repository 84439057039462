import { Second, Day } from 'web-utility';
import BigNumber from 'bignumber.js';
import {
  TokenMetadata,
  idlFactory as tokenIDL,
  _SERVICE as TokenActor
} from '@deland-labs/dft_basic_client';

import { withLogging, withCache } from '../utils/errorLogger';
import {
  parseToOrigin,
  principalValidate,
  toBigInt,
  toBigNumber
} from '../utils/helper';
import { IC_HOST, isLocalEnv } from '../utils/config';
import { CanisterError } from '../utils/exception';

import { AuthBase } from '../AuthBase';
import { DICP } from './DICP';

export interface TokenInfo extends TokenMetadata {
  id: string;
}

export interface Balance {
  id: string;
  balance: BigNumber;
}
export interface TransactionRes {
  txId: string;
  blockHeight: bigint;
}

export const tokenLogo = (tokenId: string) => {
  return isLocalEnv()
    ? `${IC_HOST}/logo?canisterId=${tokenId}`
    : `http://${tokenId}.raw.ic0.app/logo`;
};

/**
 * DFT Standard Token
 */
export class DFT extends AuthBase {
  private tokenId: string;

  constructor(tokenId: string) {
    if (!principalValidate(tokenId)) throw new Error('invalid tokenId format');
    super();
    this.tokenId = tokenId;
  }

  @withLogging
  @withCache<DFT>(instance => `token_${instance.tokenId}`, Day / Second)
  async getTokenInfo(): Promise<TokenInfo> {
    const token = await this.createTokenActor(this.tokenId);

    return {
      id: this.tokenId,
      ...(await token.meta())
    };
  }

  /**
   * @param address  wallet address
   */
  @withLogging
  async balanceOf(address: string): Promise<Balance> {
    const token = await this.createTokenActor(this.tokenId);
    const balance = await token.balanceOf(address);

    return {
      id: this.tokenId,
      balance: new BigNumber(balance.toString())
    };
  }

  @withLogging
  async getTokenAllowance(owner: string, spender: string) {
    const token = await this.createTokenActor(this.tokenId);

    return token.allowance(owner, spender);
  }

  @withLogging
  async approveToken(
    spender: string,
    amount: BigNumber,
    tokenDecimals: number
  ) {
    const token = await this.createTokenActor(this.tokenId, false);

    const res = await token.approve(
      [],
      spender,
      toBigInt(parseToOrigin(amount, tokenDecimals)),
      []
    );
    if ('Ok' in res) return true;

    throw new CanisterError(res.Err);
  }

  /**
   * @param receiver the address of receiver
   * @param amount transfer amount
   */
  @withLogging
  async transferToken(
    receiver: string,
    amount: number,
    tokenDecimals = DICP.tokenInfo?.decimals
  ): Promise<TransactionRes> {
    const token = await this.createTokenActor(this.tokenId, false);

    const res = await token.transfer(
      [],
      receiver,
      toBigInt(parseToOrigin(amount, tokenDecimals)),
      []
    );
    if ('Ok' in res) return res.Ok;

    throw new CanisterError(res.Err);
  }

  /**
   * @param amountBN transfer amount
   */
  public static calcTransferFee(amountBN: BigNumber, tokenInfo: TokenInfo) {
    const rateFee = amountBN
      .multipliedBy(tokenInfo.fee.rate)
      .shiftedBy(-tokenInfo.fee.rateDecimals);

    const minFee = toBigNumber(tokenInfo.fee.minimum).shiftedBy(
      -tokenInfo.decimals
    );

    return rateFee.gt(minFee) ? rateFee : minFee;
  }

  /**
   * calc approve fee
   * @param amount
   * @param tokenInfo
   * @returns
   */
  public static calcApproveFee(amount: number, tokenInfo: TokenInfo) {
    const minFee = toBigNumber(tokenInfo.fee.minimum).shiftedBy(
      -tokenInfo.decimals
    );
    return minFee.toNumber();
  }

  /**
   * create token actor
   * @param tokenId  token canister id
   * @param anonymous is anonymous access
   * @returns
   */
  private createTokenActor = (tokenId: string, anonymous: boolean = true) => {
    return this.createActor<TokenActor>(tokenId, tokenIDL, anonymous);
  };
}
