import { Row, Col, Image } from 'react-bootstrap';

import { MainLayout } from '../../component/MainLayout';
import { IconButton } from '../../component/IconButton';
import * as styles from './index.module.less';

const logoSequare = new URL('./img/logo-512.png', import.meta.url) + '',
  logoB = new URL('./img/ICNAMING2_b.png', import.meta.url) + '',
  logoBH = new URL('./img/ICNAMING2_b_h.png', import.meta.url) + '',
  logoZip = new URL('./img/pressKit.zip', import.meta.url) + '';

const PressKit = () => (
  <MainLayout>
    <h1 className={`display-1 mx-auto ${styles.title}`}>ICNaming</h1>
    <p className="text-center">The ICNAMING logo</p>
    <Row xs={1} sm={1} md={3} className="mt-4 g-4">
      {[logoSequare, logoB, logoBH].map(src => (
        <Col
          key={src}
          className={`d-flex flex-column align-items-center justify-content-between ${styles['card']}`}
        >
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: '15.625rem' }}
          >
            <Image src={src} className={styles['custom-img']} />
          </div>
          <div className={`text-center w-100 py-3 ${styles['download']}`}>
            <span className="me-3 text-uppercase">icnaming logo</span>
            <IconButton name="download" href={logoZip} />
          </div>
        </Col>
      ))}
    </Row>
  </MainLayout>
);
export default PressKit;
