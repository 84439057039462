import { observable } from 'mobx';

import { BufferListModel, toggle } from './Base';
import { toBigNumber } from './utils/helper';
import {
  DICP_ID,
  DICP_MINTER_ID,
  ICP_TOKEN_DECIMALS,
  IC_REGISTRAR_ID,
  MARKET_ID
} from './utils/config';

import { DFT } from './canister/DFT';
import { DICP } from './canister/DICP';
import { Registrar } from './canister/Registrar';
import {
  Market,
  MarketOrdersQueryReq,
  OrderDetails,
  OrderStatus
} from './canister/Market';

export interface MarketOrder extends OrderDetails {
  state?: OrderStatus;
}

export class MarketModel extends BufferListModel<MarketOrder> {
  private canisterDICP = new DICP(DICP_ID, DICP_MINTER_ID);
  private registrar = new Registrar(IC_REGISTRAR_ID);
  private market = new Market(MARKET_ID, ICP_TOKEN_DECIMALS);

  @observable
  pageSize = 18;

  protected async loadList(
    filter: MarketOrdersQueryReq,
    pageIndex = this.pageIndex + 1
  ) {
    await this.canisterDICP.initialize();

    const list = await this.market.getMarketOrdersWithPager(
      filter,
      (pageIndex - 1) * this.pageSize,
      this.pageSize
    );
    if (list.length < this.pageSize) this.noMore = true;

    this.pageIndex = pageIndex;

    return (this.list = [...this.list, ...list]);
  }

  @toggle('downloading')
  async getOne(id: string | bigint) {
    const data = await this.market.getOrderDetailsById(BigInt(id));

    await this.canisterDICP.initialize();

    return (this.current = data);
  }

  @toggle('uploading')
  async sellName(name: string, price: number) {
    await this.registrar.approve(name, MARKET_ID);

    return this.market.createNameOrder(name, price);
  }

  @toggle('uploading')
  sellQuota(length: number, price: number, amount: number) {
    return this.market.createQuotaOrder({ LenGte: length }, amount, price);
  }

  @toggle('uploading')
  cancel(id: string | bigint) {
    return this.market.cancelOrder(BigInt(id));
  }

  @toggle('uploading')
  async buy(orderId: string | bigint, quotaAmount = 0) {
    orderId = BigInt(orderId);

    const { price } =
      this.current.orderId === orderId
        ? this.current
        : await this.getOne(orderId);

    await this.canisterDICP.initialize();
    const approveRealAmount = !quotaAmount
      ? toBigNumber(price).shiftedBy(-DICP.tokenInfo.decimals)
      : toBigNumber(price)
          .shiftedBy(-DICP.tokenInfo.decimals)
          .times(quotaAmount);

    const approveFee = DFT.calcTransferFee(approveRealAmount, DICP.tokenInfo);
    const approveTotalAmount = approveRealAmount.plus(approveFee);

    console.debug(
      `approve total ${approveTotalAmount.toNumber()} approve real ${approveRealAmount.toNumber()}, fee ${approveFee.toNumber()}`
    );
    await this.canisterDICP.approve(MARKET_ID, approveTotalAmount.toNumber());
    return quotaAmount
      ? this.market.buyQuota(orderId, quotaAmount)
      : this.market.buyName(orderId);
  }
}

export default new MarketModel();
