import {
    RESOLVER_KEY_ICP_PRINCIPAL,
    RESOLVER_KEY_ICP_ACCOUNT_ID,
    RESOLVER_KEY_BTC,
    RESOLVER_KEY_ETH,
    RESOLVER_KEY_BNB,
    RESOLVER_KEY_XRP,
    RESOLVER_KEY_ADA,
    RESOLVER_KEY_SOL,
    RESOLVER_KEY_DOGE,
    RESOLVER_KEY_DOT,
    RESOLVER_KEY_MATIC,
    RESOLVER_KEY_TRX,
    RESOLVER_KEY_AVAX,
    RESOLVER_KEY_ETC,
    RESOLVER_KEY_LTC,
    RESOLVER_KEY_XLM,
    RESOLVER_KEY_XMR,
    RESOLVER_KEY_ALGO,
    RESOLVER_KEY_BCH,
    RESOLVER_KEY_VET,
    RESOLVER_KEY_XTZ,
    RESOLVER_KEY_AAVE,
    RESOLVER_KEY_BSV,
    RESOLVER_KEY_ZEC,
    RESOLVER_KEY_BTT,
    RESOLVER_KEY_NEO,
    RESOLVER_KEY_DASH,
    RESOLVER_KEY_WAVES,
    RESOLVER_KEY_BTG,
    RESOLVER_KEY_XEM,
    RESOLVER_KEY_DCR,
    RESOLVER_KEY_GNO
} from './utils/config/ResolverKey';

import { ResolverKey } from './canister/Resolver';

export const CryptoNames = [
    'ICP',
    'BTC',
    'ETH',
    'BNB',
    'XRP',
    'ADA',
    'SOL',
    'DOGE',
    'DOT',
    'MATIC',
    'TRX',
    'AVAX',
    'ETC',
    'LTC',
    'XLM',
    'XMR',
    'ALGO',
    'BCH',
    'VET',
    'XTZ',
    'AAVE',
    'BSV',
    'ZEC',
    'BTT',
    'NEO',
    'DASH',
    'WAVES',
    'BTG',
    'XEM',
    'DCR',
    'GNO'
] as const;

export type CryptoName = typeof CryptoNames[number];

export interface Crypto {
    id: string;
    type: 'coin';
    name?: CryptoName | string;
    account?: string;
}

export interface DomainCrypto {
    principalICP?: string;
    accountICP?: string;
    BTC?: string;
    ETH?: string;
    BNB?: string;
    XRP?: string;
    ADA?: string;
    SOL?: string;
    DOGE?: string;
    DOT?: string;
    MATIC?: string;
    TRX?: string;
    AVAX?: string;
    ETC?: string;
    LTC?: string;
    XLM?: string;
    XMR?: string;
    ALGO?: string;
    BCH?: string;
    VET?: string;
    XTZ?: string;
    AAVE?: string;
    BSV?: string;
    ZEC?: string;
    BTT?: string;
    NEO?: string;
    DASH?: string;
    WAVES?: string;
    BTG?: string;
    XEM?: string;
    DCR?: string;
    GNO?: string;
  }

export const CryptoKey: Record<keyof DomainCrypto, ResolverKey> = {
    principalICP: RESOLVER_KEY_ICP_PRINCIPAL,
    accountICP: RESOLVER_KEY_ICP_ACCOUNT_ID,
    BTC: RESOLVER_KEY_BTC,
    ETH: RESOLVER_KEY_ETH,
    BNB: RESOLVER_KEY_BNB,
    XRP: RESOLVER_KEY_XRP,
    ADA: RESOLVER_KEY_ADA,
    SOL: RESOLVER_KEY_SOL,
    DOGE: RESOLVER_KEY_DOGE,
    DOT: RESOLVER_KEY_DOT,
    MATIC: RESOLVER_KEY_MATIC,
    TRX: RESOLVER_KEY_TRX,
    AVAX: RESOLVER_KEY_AVAX,
    ETC: RESOLVER_KEY_ETC,
    LTC: RESOLVER_KEY_LTC,
    XLM: RESOLVER_KEY_XLM,
    XMR: RESOLVER_KEY_XMR,
    ALGO: RESOLVER_KEY_ALGO,
    BCH: RESOLVER_KEY_BCH,
    VET: RESOLVER_KEY_VET,
    XTZ: RESOLVER_KEY_XTZ,
    AAVE: RESOLVER_KEY_AAVE,
    BSV: RESOLVER_KEY_BSV,
    ZEC: RESOLVER_KEY_ZEC,
    BTT: RESOLVER_KEY_BTT,
    NEO: RESOLVER_KEY_NEO,
    DASH: RESOLVER_KEY_DASH,
    WAVES: RESOLVER_KEY_WAVES,
    BTG: RESOLVER_KEY_BTG,
    XEM: RESOLVER_KEY_XEM,
    DCR: RESOLVER_KEY_DCR,
    GNO: RESOLVER_KEY_GNO
};