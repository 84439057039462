import classNames from 'classnames';
import { Nav } from 'react-bootstrap';
import * as style from './index.module.less';
export const footerData = [
  {
    title: 'twitter',
    link: 'https://twitter.com/ic_naming',
    target: '_blank'
  },
  {
    title: 'medium',
    link: 'https://medium.com/@icnaming',
    target: '_blank'
  },
  {
    title: 'github',
    link: 'https://github.com/IC-Naming',
    target: '_blank'
  },
  {
    title: 'discord',
    link: 'https://discord.gg/FJ63ckXn6n',
    target: '_blank'
  },
  {
    title: 'arrow-down',
    link: '#/press-kit',
    target: '_self'
  }
];
export const Footer = () => (
  <div
    className={classNames(
      'd-flex py-3 justify-content-center align-items-center',
      style.footer
    )}
  >
    {footerData.map(({ title, link, target }, idx) => (
      <Nav.Link
        href={link}
        className={style['footer-link']}
        title={title}
        key={idx}
        target={target}
        rel="noreferrer"
      >
        <i className={classNames(style.iconfont, style[`icon-${title}`])} />
      </Nav.Link>
    ))}
  </div>
);
