.no-more {
  color: #8c9ecc;
}
.load-more {
  box-shadow: 4px 6px 16px 0px rgba(188, 194, 217, 0.5),
    -4px -6px 16px 0px rgba(255, 255, 255, 0.7);
  border-radius: 6px;
  padding: 0.5rem 0.6rem;
  .no-more();
}
