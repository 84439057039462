import classNames from 'classnames';
import { FC, useState } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { ScrollBoundary, ScrollBoundaryProps } from 'idea-react';
import { Principal } from '@dfinity/principal';
import { t } from 'i18next';
import { LoadMore } from './LoadMore';
import { EmptyCard } from './EmptyCard';
import { LoadingFullCon } from './LoadingFullPage';
import { Domain } from '../model/Domain';
import { DomainOwnership } from '../model/Resolver';
import sessionStore from '../model/Session';
import { DialogSell } from './DialogSell';
import { DialogBuy } from './DialogBuy';

export interface DomainListProps {
  controls?: boolean;
  owner: string | Principal;
  type: DomainOwnership;
  selling?: boolean;
  list: Domain[];
  noMore?: boolean;
  loadMore?: ScrollBoundaryProps['onTouch'];
  loading: boolean;
}

export const DomainList: FC<DomainListProps> = ({
  controls,
  owner,
  type,
  selling,
  list,
  noMore,
  loadMore,
  loading
}) => {
  list = list.filter(
    ({ registrant, controller, orderId }) =>
      (type === 'registrant'
        ? registrant + '' === owner + ''
        : controller + '' === owner + '') &&
      (!selling || orderId)
  );
  const { isDesktop } = sessionStore;
  const [showDialogSell, setShowDialogSell] = useState(false);
  const [showDialogBuy, setShowDialogBuy] = useState(false);
  const [saleOrderId, setSaleOrderId] = useState('');
  const [sealName, setSealName] = useState('');

  const onSell = (domain: string) => {
    setShowDialogSell(true);
    setSealName(domain);
  }

  const reLoadNames = (name: string, orderId: bigint | null) => {
    console.log('reLoadNames', name, orderId);
    list.forEach(item => {
      if (item.name === name) item.orderId = orderId;
    })
    setShowDialogSell(false);
    setSealName('');
  }

  const viewSelling = (domain: string, orderId: string) => {
    setShowDialogBuy(true);
    setSealName(domain);
    setSaleOrderId(orderId);
  }
  const content = (
    <>
      <ListGroup className="round-card">
        {list.map(({ name, expiredAt, orderId }, index) => (
          <ListGroup.Item
            key={name}
            className={classNames(
              'border-0',
              index && 'border-top',
              'd-flex',
              'align-items-center',
              isDesktop && 'p-4'
            )}
          >
            <div className="flex-fill">
              <h2
                className={classNames(
                  isDesktop ? 'fs-16' : 'fs-14',
                  'text-primary'
                )}
              >
                {name}
              </h2>

              <small
                className={classNames('text-secondary', !isDesktop && 'fs-10')}
              >
                {t("account.Expires")} {new Date(+(expiredAt + '')).toLocaleString()}
              </small>
            </div>
            {controls && (
              <div className="text-nowrap">
                {!orderId ? (
                  <>
                    <Button
                      variant="outline-warning shadow-none"
                      href={`#/domain/${name}/profile`}
                    >
                      {t("base.Setting")}
                    </Button>
                    {!name.endsWith('.icp') && (
                      <Button
                        className="ms-3"
                        variant="outline-primary shadow-none"
                        onClick={() => onSell(name)}
                      >
                        {t("base.Sell")}
                      </Button>
                    )}
                  </>
                ) : (
                  <Button
                    className="shadow-none"
                    variant="link"
                    onClick={() => viewSelling(name, orderId + '')}
                  >
                    {t("account.ViewSelling")}
                  </Button>
                )}
              </div>
            )}
          </ListGroup.Item>
        ))}
      </ListGroup>
      <DialogSell
        show={showDialogSell}
        title={t("base.SellName")}
        name={sealName}
        reLoadNames={(orderId) => reLoadNames(sealName, orderId)}
        onHide={() => setShowDialogSell(false)}
      />
      <DialogBuy
        show={showDialogBuy}
        onHide={() => setShowDialogBuy(false)}
        title={t("base.SellName")}
        name={sealName}
        user={owner + ''}
        reLoadNames={() => reLoadNames(sealName, null)}
        order={saleOrderId}
      />
    </>
  );

  return !list[0] ? (
    <div className="position-relative">
      {loading && <div className="h-100 w-100 position-absolute" style={{ background: "rgba(255,255,255,0.5)" }}><LoadingFullCon /></div>}
      <EmptyCard variant="box" />
    </div>
    ) : loadMore ? (
      <ScrollBoundary
        className="custom-list-wrap"
        bottom={<LoadMore noMore={noMore} />}
        onTouch={loadMore}
      >
        {content}
      </ScrollBoundary>
    ) : (
      content
    );
};
