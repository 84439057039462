import { ICP_TOKEN_ID, ICP_TOKEN_SYMBOL } from './constants';
import { isMainNetEnv, isTestNetEnv } from './env';

export interface Token {
  symbol: string;
  id: string;
}
const mainnetTokens: Token[] = [];
const testnetTokens: Token[] = [
  { symbol: ICP_TOKEN_SYMBOL, id: ICP_TOKEN_ID },
  { symbol: 'TOST', id: 'pmukb-nyaaa-aaaag-qabeq-cai' },
  { symbol: 'TUSDT', id: 'lhoit-hyaaa-aaaan-qaaeq-cai' },
  { symbol: 'TICP', id: '5i47k-cqaaa-aaaak-qaddq-cai' }
];
const localTokens: Token[] = [
  { symbol: 'WUSD', id: 'qhbym-qaaaa-aaaaa-aaafq-cai' },
  { symbol: 'WICP', id: 'qaa6y-5yaaa-aaaaa-aaafa-cai' }
];
// mainnet testnet
export const wellKnowTokens = isMainNetEnv()
  ? mainnetTokens
  : isTestNetEnv()
  ? testnetTokens
  : localTokens;
// is well know token
export const isWellKnownToken = (tokenId: string) => {
  const token = wellKnowTokens.find(t => t.id === tokenId);
  return !!token;
};
