import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FC } from 'react';
import { Icon } from 'idea-react';
import { sleep } from 'web-utility';

import { IconButton } from '../IconButton';
import * as styles from './index.module.less';

type Status = 'loading' | 'success' | 'error' | 'warning' | 'info';

interface Message {
  createdAt: string;
  content: string;
  type: Status;
  countdown?: boolean;
  seconds?: number;
}

class ToastStore {
  @observable
  messages: Message[] = [];
  success(content: string, autoHide = 3, countdown = true) {
    this.notify(content, 'success', autoHide, countdown);
  }
  error(content: string, autoHide = 0) {
    this.notify(content, 'error', autoHide);
  }
  warning(content: string, autoHide = 0) {
    this.notify(content, 'warning', autoHide);
  }
  info(content: string, autoHide = 0) {
    this.notify(content, 'info', autoHide);
  }

  private async notify(
    content: string,
    type: Status,
    autoHide = 3,
    countdown = false
  ) {
    const createdAt = new Date().toJSON();
    const message: Message = { createdAt, content, type, countdown };

    this.messages = [message, ...this.messages];

    if (!autoHide) return;

    await sleep(autoHide);

    this.hide(createdAt);
  }

  hide(createdAt: string) {
    const index = this.messages.findIndex(
        ({ createdAt: time }) => time === createdAt
      ),
      { messages } = this;

    this.messages = [...messages.slice(0, index), ...messages.slice(index + 1)];
  }
}

export const toastStore = new ToastStore();

const IconMap = {
  success: <Icon name="check-circle" className="me-2 text-success" />,
  error: <Icon name="x-circle" className="me-2 text-danger" />,
  warning: <Icon name="exclamation-circle" className="me-2 text-warning" />,
  info: <Icon name="info-circle" className="me-2 text-info" />
};

export const ToastRoot = observer(() => (
  <div className={styles['toast-position']}>
    {toastStore.messages.map(
      ({ createdAt, type, content, countdown, seconds }) => (
        <ToastMessage
          key={createdAt}
          createdAt={createdAt}
          type={type}
          content={content}
          countdown={countdown}
          seconds={seconds}
        />
      )
    )}
  </div>
));

const ToastMessage: FC<Message> = ({
  createdAt,
  type,
  content,
  countdown = false,
  seconds = 3
}) => (
  <div
    className={`text-center position-relative bg-white border-0 outer-shadow ${styles['toast-div']}`}
  >
    {IconMap[type]}

    <span>{content}</span>

    <IconButton
      size={0.9375}
      color="secondary"
      className="position-absolute top-0 start-100 translate-middle rounded-circle"
      name="x"
      onClick={() => toastStore.hide(createdAt)}
    />
    {countdown && (
      <div
        style={{ '--toast-animation-time': seconds + 's' }}
        className={`${styles['loading-line']}`}
      />
    )}
  </div>
);
