.nav-dark{
  background: #0f173d;
  backdrop-filter: blur(20px);
}

.nav-light {
  background: var(--bs-body-bg);
}

.logo {
  height: 2rem;
}

.darkOffCanvas{
  background: #0f173d;
  .btnConnect{
    border: 1px solid #00CFBB !important;
    background: rgba(0,207,187,0.1) !important;
    color: #00CFBB !important;
    border-radius: 6px;
  }
  :global{
    .dropdown-menu{
      background: #0f173d;
    }
    .text-secondary,.dropdown-item{
      color: #fff !important;
    }
    .navbar-collapse .nav-link.active,.dropdown-item:hover{
      color: #00CFBB !important;
      border-color: #00CFBB !important;
    }
  }
}

.avatar {
  width: 50px;
  margin-left: 1rem;
  @media (max-width: 576px) {
    margin-bottom: 1.25rem;
    margin-left: 0;
  }
}

.divider{
  border-color: rgba(75, 75, 99, 0.1) !important;
}

