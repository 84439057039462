import { IC } from '@astrox/sdk-web';
import type { IDL } from '@dfinity/candid';
import { PermissionsType } from '@astrox/connection/lib/esm/types';
import { IWalletConnector, WalletAuth, WalletType } from '..';
import { principalToAccountID } from '../../helper';
import { Principal } from '@dfinity/principal';
import { WalletConnectError, WalletConnectErrorCode } from '../../exception';
import { Actor, HttpAgent } from '@dfinity/agent';
import {
  ICP_FAVORITES_ID,
  ICP_HOST,
  ICP_REGISTRAR_ID,
  ICP_REGISTRY_ID,
  ICP_RESOLVER_ID,
  IC_HOST
} from '../../config';

export class AstroXConnector implements IWalletConnector {
  public type = WalletType.AstroX;
  public connected = false;

  private whiteList: string[] = [];
  private providerUrl = '';
  private ledgerHost: string;
  private ledgerCanisterId: string;
  private icHost: string;
  private dev: boolean;
  private noUnify: boolean;
  private astrox?: IC;
  private initialized = false;
  private initializing = false;
  private ic_agent?: HttpAgent;
  private icp_agent?: HttpAgent;
  constructor(
    icHost: string,
    whitelist: string[] = [],
    dev = false,
    noUnify = false,
    providerUrl = 'https://63k2f-nyaaa-aaaah-aakla-cai.raw.ic0.app',
    ledgerHost = 'https://boundary.ic0.app/',
    ledgerCanisterId = 'ryjl3-tyaaa-aaaaa-aaaba-cai'
  ) {
    this.whiteList = whitelist;
    this.icHost = icHost;
    this.dev = dev;
    this.noUnify = noUnify;
    console.debug(`astrox me: dev is ${dev}`);
    this.providerUrl = providerUrl;
    this.ledgerHost = ledgerHost;
    this.ledgerCanisterId = ledgerCanisterId;
  }

  connect = async (): Promise<WalletAuth> => {
    if (!this.initialized) {
      console.debug('init astrox');
      if (!this.initializing) {
        try {
          this.initializing = true;
          await this.initializeIC();
          this.initialized = true;
        } finally {
          this.initializing = false;
        }
      }
    }
    if (!this.astrox)
      throw new WalletConnectError(
        WalletConnectErrorCode.AstroxConnectFailed,
        'Astrox.connect: IC not initialized'
      );
    await this.astrox.connect({
      useFrame: !(window.innerWidth < 768),
      host: this.icHost,
      signerProviderUrl: `${this.providerUrl}/#signer`,
      walletProviderUrl: `${this.providerUrl}/#transaction`,
      identityProvider: `${this.providerUrl}/#authorize`,
      permissions: [PermissionsType.identity, PermissionsType.wallet],
      ledgerCanisterId: this.ledgerCanisterId,
      ledgerHost: this.ledgerHost,
      delegationTargets: this.whiteList,
      noUnify: this.noUnify
    });
    const principal = this.astrox?.principal.toText();
    this.connected = await this.astrox.isAuthenticated();
    console.log(`Astrox connected: ${this.connected}`);
    if (!principal)
      throw new WalletConnectError(
        WalletConnectErrorCode.AstroxConnectFailed,
        'AstroxConnector.connect: IC principal is undefined'
      );
    return {
      type: WalletType.AstroX,
      principal,
      accountId: principalToAccountID(Principal.fromText(principal))
    };
  };

  private getAgent = (canisterId: string) => {
    const ids = [
      ICP_REGISTRAR_ID,
      ICP_REGISTRY_ID,
      ICP_RESOLVER_ID,
      ICP_FAVORITES_ID
    ];

    if (ids.some(id => id === canisterId)) {
      if (!this.icp_agent)
        this.icp_agent = new HttpAgent({
          host: ICP_HOST,
          identity: this.astrox?.identity as any
        });
      return this.icp_agent;
    } else {
      if (!this.ic_agent) {
        this.ic_agent = new HttpAgent({
          host: IC_HOST,
          identity: this.astrox?.identity as any
        });
        this.ic_agent.fetchRootKey().catch(e => {
          console.error(`fetchRootKey error: ${e}`);
        });
      }
      return this.ic_agent;
    }
  };

  createActor = async <T>(
    canisterId: string,
    idlFactory: IDL.InterfaceFactory
  ) => {
    if (
      !this.connected ||
      !this.astrox ||
      !(await this.astrox.isAuthenticated())
    )
      throw new WalletConnectError(
        WalletConnectErrorCode.AstroxConnectFailed,
        `AstroxConnector.createActor: check connect failed`
      );
    // const agent = this.getAgent(canisterId);

    // return Actor.createActor<T>(idlFactory, {
    //   agent,
    //   canisterId
    // });
    return this.astrox?.createActor<T>(idlFactory as any, canisterId);
  };

  disconnect = () => this.astrox?.disconnect();

  /**
   * initialize astrox
   */
  private initializeIC = async () => {
    const astrox = await IC.create({
      useFrame: !(window.innerWidth < 768),
      signerProviderUrl: `${this.providerUrl}/#signer`,
      walletProviderUrl: `${this.providerUrl}/#transaction`,
      identityProvider: `${this.providerUrl}/#authorize`,
      permissions: [PermissionsType.identity, PermissionsType.wallet],
      ledgerCanisterId: this.ledgerCanisterId,
      ledgerHost: this.ledgerHost,
      host: this.icHost,
      dev: this.dev,
      delegationTargets: this.whiteList,
      noUnify: this.noUnify
    });
    if (window.ic.astrox) {
      console.debug('window.ic.astrox exists');
    }
    this.astrox = astrox;
  };
}
