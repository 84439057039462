export const KEY_LOCALSTORAGE_USE_ASSETS = `__local_user_asset_ids`;
export const WRAP_FEE = 0.0001;
export const ICP_TO_CYCLES_AMPL = 10 ** 12;
export const SUB_ACCOUNT_ZERO = Buffer.alloc(32);
export const DEFAULT_SUB_ACCOUNT_ZERO = [...new Uint8Array(SUB_ACCOUNT_ZERO)];
export const ICP_TOKEN_ID = '__icp';
export const ICP_TOKEN_SYMBOL = 'ICP';
export const ICP_TOKEN_DECIMALS = 8;
export const DNS_TOKEN_DECIMALS = 18;
export const DEFAULT_ASSETS = [ICP_TOKEN_ID, 'DLT'];
export const DEFAULT_ASSETS_PRECISION = 6;

export const REGISTRABLE_NAME_MIN_LENGTH = 3;
export const BUYABLE_NAME_MIN_LENGTH = 6;

export const REGISTRY_RESOLVER_CACHE_KEY = 'registry_resolver';
export const REGISTRY_DETAIL_CACHE_KEY = 'registry_detail';
export const REGISTRAR_DETAIL_CACHE_KEY = 'registrar_details';
export const REGISTRAR_QUOTA_CACHE_KEY = 'registrar_quota';
export const RESOLVER_GET_NAME_STATUS_WRAPPER = 'resolver_getNameStatusWrapper';
