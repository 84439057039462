export default function(param) {
    var IDL = param.IDL;
    var AccountIdentifier = IDL.Text;
    var SubAccount = IDL.Vec(IDL.Nat8);
    var SubAccountDetails = IDL.Record({
        name: IDL.Text,
        sub_account: SubAccount,
        account_identifier: AccountIdentifier
    });
    var HardwareWalletAccountDetails = IDL.Record({
        principal: IDL.Principal,
        name: IDL.Text,
        account_identifier: AccountIdentifier
    });
    var AccountDetails = IDL.Record({
        principal: IDL.Principal,
        account_identifier: AccountIdentifier,
        hardware_wallet_accounts: IDL.Vec(HardwareWalletAccountDetails),
        sub_accounts: IDL.Vec(SubAccountDetails)
    });
    var GetAccountResponse = IDL.Variant({
        Ok: AccountDetails,
        AccountNotFound: IDL.Null
    });
    var CanisterDetails = IDL.Record({
        name: IDL.Text,
        canister_id: IDL.Principal
    });
    var BlockHeight = IDL.Nat64;
    var CanisterId = IDL.Principal;
    var GetTransactionsRequest = IDL.Record({
        page_size: IDL.Nat8,
        offset: IDL.Nat32,
        account_identifier: AccountIdentifier
    });
    var TransactionType = IDL.Variant({
        Burn: IDL.Null,
        Mint: IDL.Null,
        Send: IDL.Null,
        StakeNeuronNotification: IDL.Null,
        TopUpCanister: CanisterId,
        CreateCanister: IDL.Null,
        TopUpNeuron: IDL.Null,
        StakeNeuron: IDL.Null
    });
    var Timestamp = IDL.Record({
        timestamp_nanos: IDL.Nat64
    });
    var ICPTs = IDL.Record({
        e8s: IDL.Nat64
    });
    var Send = IDL.Record({
        to: AccountIdentifier,
        fee: ICPTs,
        amount: ICPTs
    });
    var Receive = IDL.Record({
        fee: ICPTs,
        from: AccountIdentifier,
        amount: ICPTs
    });
    var Transfer = IDL.Variant({
        Burn: IDL.Record({
            amount: ICPTs
        }),
        Mint: IDL.Record({
            amount: ICPTs
        }),
        Send: Send,
        Receive: Receive
    });
    var Transaction = IDL.Record({
        transaction_type: IDL.Opt(TransactionType),
        memo: IDL.Nat64,
        timestamp: Timestamp,
        block_height: BlockHeight,
        transfer: Transfer
    });
    var GetTransactionsResponse = IDL.Record({
        total: IDL.Nat32,
        transactions: IDL.Vec(Transaction)
    });
    return IDL.Service({
        get_account: IDL.Func([], [
            GetAccountResponse
        ]),
        get_canisters: IDL.Func([], [
            IDL.Vec(CanisterDetails)
        ]),
        get_transactions: IDL.Func([
            GetTransactionsRequest
        ], [
            GetTransactionsResponse
        ])
    });
};
export var init = function(param) {
    var IDL = param.IDL;
    return [];
};
