export * from './env';
export * from './wellKnowToken';
export * from './constants';
export * from './canisterIds';
export * from './whiteList';
export * from './blindBox';

const { origin } = location;

export const IC_PORT = process.env.IC_PORT;
export const ICP_HOST = process.env.ICP_HOST;
export const IC_HOST_CFG = process.env.IC_HOST;
export const IC_HOST =
  IC_HOST_CFG ||
  (origin.includes('.gitpod.')
    ? origin.replace(/\/\/\d+/, `//${IC_PORT}`)
    : `http://127.0.0.1:${IC_PORT}`);

console.debug('IC_HOST_CFG', IC_HOST_CFG);
console.debug('IC_HOST', IC_HOST);
console.debug('ICP_HOST', ICP_HOST);

export const APP_NAME = process.env.APP_ENV;

export type DomainSuffix = 'icp' | 'tic' | 'ic';
export const DOMAIN_SUBFIX = process.env.DOMAIN_SUBFIX as DomainSuffix;
export const DomainSuffixs = [`.${DOMAIN_SUBFIX}`, '.icp'];

export const FAUCET_CODE = process.env.FAUCET_CODE;
export const DICP_SUBACCOUNT_FIRST_BYTE = Number(
  process.env.DICP_SUBACCOUNT_FIRST_BYTE || 0x0
);
