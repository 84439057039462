import BigNumber from 'bignumber.js';
import {
  idlFactory as dicpIDL,
  _SERVICE as DICPActor,
  TokenMetadata
} from '@icnaming/dicp_client';
import {
  _SERVICE as DICPMinterActor,
  idlFactory as dicpMinterIDL
} from '@icnaming/dicp_minter_client';

import { withLogging } from '../utils/errorLogger';
import { CanisterError } from '../utils/exception';
import { toBigInt, parseToCommon, parseToOrigin } from '../utils/helper';
import { AuthBase } from '../AuthBase';
import { DFT } from './DFT';

export interface TokenInfo extends TokenMetadata {
  id: string;
}

export interface TransactionRes {
  txId: string;
  blockHeight: bigint;
}

export interface Balance {
  id: string;
  balance: BigNumber;
}

export class DICP extends AuthBase {
  private dicpCanisterId: string;
  private dicpMinterCanisterId: string;
  private static initialized: boolean;
  static tokenInfo: TokenInfo;

  /**
   * please call initialize() before call any other function
   */
  constructor(dicpCanisterId: string, dicpMinterCanisterId: string) {
    super();
    this.dicpCanisterId = dicpCanisterId;
    this.dicpMinterCanisterId = dicpMinterCanisterId;
  }

  /**
   * initialize
   */
  public async initialize() {
    if (DICP.initialized) return;

    const dicp = await this.createDICPActor(this.dicpCanisterId);
    const dft = new DFT(this.dicpCanisterId);

    DICP.tokenInfo = await dft.getTokenInfo();
  }

  @withLogging
  async getTokenInfo() {
    return DICP.tokenInfo;
  }

  /**
   * get dicp balance
   *
   * @param address  wallet address
   */
  @withLogging
  public async getBalance(address: string): Promise<Balance> {
    const token = await this.createDICPActor(this.dicpCanisterId);
    const balance = await token.balanceOf(address);

    return {
      id: this.dicpCanisterId,
      balance: parseToCommon(balance, DICP.tokenInfo.decimals)
    };
  }

  /**
   * check dicp mint status
   */
  @withLogging
  public async checkMintStatus(blockHeight: bigint) {
    const dicpMinter = await this.createDICPMinterActor(
      this.dicpMinterCanisterId
    );
    return dicpMinter.check_mint_status(blockHeight);
  }

  /**
   * check dicp withdraw status
   */
  @withLogging
  public async checkWithdrawStatus(blockHeight: bigint) {
    const dicp = await this.createDICPActor(this.dicpCanisterId);
    return dicp.checkWithdrawStatus(blockHeight);
  }

  /**
   * withdraw DICP to ICP
   */
  @withLogging
  public async withdraw(amount: number): Promise<TransactionRes> {
    const dicp = await this.createDICPActor(this.dicpCanisterId, false);

    const res = await dicp.withdraw(
      [],
      toBigInt(parseToOrigin(amount, DICP.tokenInfo.decimals)),
      []
    );
    if ('Ok' in res) return res.Ok;

    throw new CanisterError(res.Err);
  }

  /*
   * approve
   */
  @withLogging
  public async approve(
    targetCanisterId: string,
    amount: number
  ): Promise<TransactionRes> {
    const dicp = await this.createDICPActor(this.dicpCanisterId, false);

    const approveAmountBN = toBigInt(
      parseToOrigin(amount, DICP.tokenInfo.decimals)
    );
    console.debug(`approve ${amount}, amount BN ${approveAmountBN}`);
    const res = await dicp.approve(
      [],
      targetCanisterId,
      toBigInt(parseToOrigin(amount, DICP.tokenInfo.decimals)),
      []
    );
    if ('Ok' in res) return res.Ok;

    throw new CanisterError(res.Err);
  }

  private createDICPActor(dicpCanisterId: string, anonymous = true) {
    return this.createActor<DICPActor>(dicpCanisterId, dicpIDL, anonymous);
  }

  private createDICPMinterActor(
    dicpMinterCanisterId: string,
    anonymous = true
  ) {
    return this.createActor<DICPMinterActor>(
      dicpMinterCanisterId,
      dicpMinterIDL,
      anonymous
    );
  }
}
