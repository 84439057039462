import { IReactionDisposer, observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { ChangeEvent, PureComponent } from 'react';
import {
  Accordion,
  Image,
  Col,
  Form,
  Row,
  Button,
  Offcanvas,
  FormCheck
} from 'react-bootstrap';
import { EdgePosition, ScrollBoundary, Icon } from 'idea-react';
import classNames from 'classnames';
import { Second } from 'web-utility';
import { debounce } from 'lodash';
import { t } from 'i18next';

import { IconInput } from '../../component/IconInput';
import { RangeInput } from '../../component/RangeInput';
import { LoadMore } from '../../component/LoadMore';
import { MainLayout } from '../../component/MainLayout';
import { MarketCard } from './components/Card';
import { DialogBuy, DialogBuyProps } from '../../component/DialogBuy';
import { LoadingFullCon } from '../../component/LoadingFullPage';
import banner from '../../image/banner@2x.png';
import * as styles from './index.module.less';

import sessionStore from '../../model/Session';
import marketStore from '../../model/Market';
import { MarketOrdersQueryReq } from '../../model/canister/Market';
import { DOMAIN_SUBFIX } from '../../model/utils/config';

const lengthNum = ['All', '3', '4', '5', '6', '7'] as const;


@observer
export class MarketPage extends PureComponent {
  @observable
  filter: MarketOrdersQueryReq = {
    includeName: true,
    includeQuota: true,
    price_min: 0,
    onlyMySale: false
  };

  @observable
  filterOffcanvas = false;

  @observable
  showDialogBuy = false;

  @observable
  dialogBuyOrder: DialogBuyProps;

  private disposers: IReactionDisposer[] = [];

  componentDidMount() {
    this.disposers.push(
      reaction(
        () => this.filter,
        () => this.loadPage(1)
      )
    );
    this.loadPage(1);
  }

  componentWillUnmount() {
    for (const disposer of this.disposers) disposer();
  }

  loadPage(index?: number) {
    const { price_min, price_max } = this.filter;

    if (price_min >= price_max || marketStore.downloading) return;

    if (index === 1) marketStore.clear();

    if (index || !marketStore.noMore) marketStore.getList(this.filter, index);
  }

  loadMore = debounce(
    (edge: EdgePosition) => edge === 'bottom' && this.loadPage(),
    Second
  );

  setFilter(filter: Partial<MarketOrdersQueryReq>) {
    this.filter = { ...this.filter, ...filter };
  }

  handleSearch = debounce(
    value => this.setFilter({ nameFilter: value }),
    Second
  );

  toggleSale = ({ currentTarget }: ChangeEvent<HTMLInputElement>) =>
    this.setFilter({ onlyMySale: currentTarget.checked });

  selectLength = ({
    currentTarget: { value }
  }: ChangeEvent<HTMLSelectElement>) => {
    const lengthFilter = value === 'All' ? undefined : +value;

    this.setFilter({ lengthFilter });
  };

  renderTypeFilter() {
    const { includeName, includeQuota } = this.filter;

    return (
      <>
        <Button
          className="me-2 mb-2"
          style={{ width: '5rem' }}
          variant={
            includeName && includeQuota
              ? 'outline-primary'
              : 'outline-secondary'
          }
          onClick={() =>
            this.setFilter({ includeName: true, includeQuota: true })
          }
        >
          {t("market.All")}
        </Button>
        <Button
          className="me-2 mb-2"
          style={{ width: '5rem' }}
          variant={
            includeName && !includeQuota
              ? 'outline-primary'
              : 'outline-secondary'
          }
          onClick={() =>
            this.setFilter({ includeName: true, includeQuota: false })
          }
        >
          .{DOMAIN_SUBFIX}
        </Button>
        <Button
          className="me-2 mb-2"
          style={{ width: '5rem' }}
          variant={
            !includeName && includeQuota
              ? 'outline-primary'
              : 'outline-secondary'
          }
          onClick={() =>
            this.setFilter({ includeName: false, includeQuota: true })
          }
        >
          Quota
        </Button>
      </>
    );
  }

  renderFilter = () => {
    const { isDesktop } = sessionStore,
      { lengthFilter, price_min, price_max } = this.filter;

    return (
      <div
        className={
          isDesktop
            ? styles['filter-custom-desktop']
            : styles['filter-custom-phone']
        }
      >
        <div className="d-flex justify-content-between align-items-center">
          <span>
            <Icon name="filter" className="me-2 fw-500 text-primary" />
            {t("market.Filter")}
          </span>
          <span>
            {!isDesktop && (
              <span onClick={() => (this.filterOffcanvas = false)}>{t("market.Down")}</span>
            )}
          </span>
        </div>
        <Accordion>
          <Accordion.Item className="border-0" eventKey="Items">
            <Accordion.Header>{t("market.Items")}</Accordion.Header>
            <Accordion.Body bsPrefix={styles['accordion-body-custom']}>
              {this.renderTypeFilter()}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="border-0" eventKey="Prices">
            <Accordion.Header>{t("base.Price")}</Accordion.Header>
            <Accordion.Body bsPrefix={styles['accordion-body-custom']}>
              <RangeInput
                style={{ width: '4rem' }}
                type="number"
                name="price"
                min={0}
                value={[price_min, price_max]}
                onChange={([price_min, price_max]) =>
                  this.setFilter({ price_min, price_max })
                }
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="border-0" eventKey="Length">
            <Accordion.Header>{t("market.Length")}</Accordion.Header>
            <Accordion.Body bsPrefix={styles['accordion-body-custom']}>
              <Form.Select
                aria-label="Length"
                className={styles['form-select']}
                defaultValue={lengthFilter}
                onChange={this.selectLength}
              >
                {lengthNum.map(num => (
                  <option key={num + 'length'} value={num}>
                    {num}
                  </option>
                ))}
              </Form.Select>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    );
  };

  render() {
    const { isDesktop, walletAuth } = sessionStore,
      { downloading, noMore, list } = marketStore;
    const { showDialogBuy, dialogBuyOrder } = this;

    return (
      <MainLayout loading={!!downloading}>
        <div>
          <Image src={banner} fluid />
        </div>
        <Row sm={2}>
          <Col sm={4} className="text-center">
            {isDesktop ? (
              this.renderFilter()
            ) : (
              <Button
                variant="primary"
                className="w-50 my-4"
                onClick={() => (this.filterOffcanvas = true)}
              >
                <Icon name="filter">{t("market.Filter")}</Icon>
              </Button>
            )}

            {!isDesktop && (
              <Offcanvas
                show={this.filterOffcanvas}
                onHide={() => (this.filterOffcanvas = false)}
              >
                {this.renderFilter()}
              </Offcanvas>
            )}
          </Col>
          <Col sm={8}>
            <div
              className={classNames(
                'my-3 d-flex justify-content-between',
                isDesktop ? 'align-items-center' : 'flex-column w-75'
              )}
            >
              <div>
                <IconInput
                  className="text-secondary"
                  icon="search"
                  placeholder={t("market.placeholder")}
                  type="search"
                  name="keyword"
                  onChange={({ currentTarget: { value } }) =>
                    this.handleSearch(value)
                  }
                />
              </div>
              {walletAuth && (
                <Form.Check bsPrefix={styles['form-check']}>
                  <FormCheck.Label className="d-flex align-items-center text-info">
                    <FormCheck.Input
                      className={classNames(
                        styles['form-check-input'],
                        'me-2',
                        isDesktop ? '' : 'my-4'
                      )}
                      type="checkbox"
                      checked={this.filter.onlyMySale}
                      onChange={this.toggleSale}
                    />
                    {t("market.SeeMySale")}
                  </FormCheck.Label>
                </Form.Check>
              )}
            </div>
            <ScrollBoundary
              bottom={<LoadMore noMore={noMore} />}
              onTouch={this.loadMore}
            >
              <Row xs={1} xl={2} xxl={3} className="g-4 custom-list-wrap">
                {
                  !!downloading && <LoadingFullCon />
                }
                {list.map(order => (
                  <Col key={order.orderId + ''}>
                    <MarketCard onBuy={(buyOrder: any) => {
                      this.showDialogBuy = true;
                      this.dialogBuyOrder = buyOrder;
                    }} {...order} />
                  </Col>
                ))}
              </Row>
            </ScrollBoundary>
          </Col>
        </Row>
        <DialogBuy
          show={showDialogBuy}
          isMarket={true}
          reLoadMarket={() => this.loadPage(1)}
          onHide={() => this.showDialogBuy = false}
          {...dialogBuyOrder}
        />
      </MainLayout>
    );
  }
}
