import { FC } from 'react';
import classNames from 'classnames';
import { t } from 'i18next';
import * as styles from './index.module.less';
import { NameStatus } from '../../model/canister/Registrar';

const TextMap = {
    [NameStatus.Available]: "domain.CustomBadge.Available",
    [NameStatus.Unavailable]: "domain.CustomBadge.Unavailable",
    [NameStatus.Reserved]: "domain.CustomBadge.Reserved",
    [NameStatus.OnSale]: "domain.CustomBadge.OnSale"
  },
  ClassMap = {
    [NameStatus.Available]: 'text-success bg-success',
    [NameStatus.Reserved]: 'text-warning bg-warning',
    [NameStatus.Unavailable]: 'text-secondary bg-secondary',
    [NameStatus.OnSale]: 'text-primary bg-primary'
  };

export interface CustomBadgeProps {
  status: NameStatus;
}

export const CustomBadge: FC<CustomBadgeProps> = ({ status }) => (
  <span
    className={classNames(
      styles['custom-badge'],
      'bg-opacity-10',
      ClassMap[status] || 'text-primary bg-primary'
    )}
  >
    {t(TextMap[status]) || t("domain.CustomBadge.ForSaleByOwner")}
  </span>
);
