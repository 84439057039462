export const languages = {
  en_GB: 'English',
  id_ID: 'Bahasa Indonesia',
  ms_MY: 'Bahasa Malaysia',
  en_PH: 'Filipino',
  vi_VN: 'Tiếng Việt',
  ja_JP: '日本語',
  ko_KR: '한국어',
  zh_CN: '简体中文',
  zh_TW: '繁体中文',
};

export type Language = keyof typeof languages;
