import { deleteCache } from '@deland-labs/local-cache';
import { Principal } from '@dfinity/principal';
import {
  ConditionOrderTypeName,
  ConditionOrderTypeQuota,
  GetMarketOrdersQuery,
  GetMyOrdersQuery,
  OrderState,
  OrderType,
  QuotaType,
  UserOrderDto,
  idlFactory as exchangeIDL,
  _SERVICE as MarketActor,
  NameOrQuota
} from '@icnaming/exchange_client';

import { AuthBase } from '../AuthBase';
import {
  REGISTRAR_DETAIL_CACHE_KEY,
  REGISTRAR_QUOTA_CACHE_KEY,
  REGISTRY_DETAIL_CACHE_KEY,
  RESOLVER_GET_NAME_STATUS_WRAPPER
} from '../utils/config';
import { withLogging } from '../utils/errorLogger';
import { CanisterError } from '../utils/exception';
import { toBigInt, parseToOrigin, principalValidate } from '../utils/helper';

export enum OrderStatus {
  InTrading,
  OnSale,
  Sending,
  Cancelled,
  Created,
  InCancelling,
  Completed
}

export enum OrderFilterType {
  Name = 1,
  Quota = 1 << 1
}

export interface OrderDetails {
  user: string;
  status: OrderStatus;
  orderType: OrderType;
  orderId: bigint;
  price: bigint;
  completedAt?: number;
  orderTargetUser?: string;
}

export interface MarketOrdersQueryReq {
  onlyMySale: boolean;
  includeName: boolean;
  includeQuota: boolean;
  nameFilter?: string;
  lengthFilter?: number;
  price_min?: number;
  price_max?: number;
}

export interface MyOrdersQueryReq {
  onlyMySale: boolean;
  includeName: boolean;
  includeQuota: boolean;
  orderStatus: OrderStatus[];
  nameFilter?: string;
  lengthFilter?: number;
}

export class Market extends AuthBase {
  private marketCanisterId: string;
  private dicpDecimals: number;
  constructor(marketCanisterId: string, dicpDecimals: number) {
    super();
    this.marketCanisterId = marketCanisterId;
    this.dicpDecimals = dicpDecimals;
  }

  /**
   * @param orderId market order id
   */
  @withLogging
  public async getOrderDetailsById(orderId: bigint) {
    const exchange = await this.createMarketActor(this.marketCanisterId);
    const res = await exchange.get_order_details({ id: orderId });

    if ('Ok' in res) return this.parseUserOrderDtoToOrderDetails(res.Ok);

    throw new CanisterError(res.Err);
  }

  /**
   * get market orders with pager
   */
  @withLogging
  public async getMarketOrdersWithPager(
    req: MarketOrdersQueryReq,
    offset: number,
    limit: number
  ) {
    const exchange = await this.createMarketActor(
      this.marketCanisterId,
      !req.onlyMySale
    );

    const res = await exchange.get_market_orders(
      this.parseMarketOrdersQueryReqToGetMarketOrdersQuery(req),
      {
        offset: BigInt(offset),
        limit: BigInt(limit)
      }
    );
    if ('Ok' in res)
      return res.Ok.map(order => ({
        ...this.parseUserOrderDtoToOrderDetails(order),
        state: this.parseOrderStateToOrderStatus(order.state)
      }));

    throw new CanisterError(res.Err);
  }

  /**
   * get my trading orders
   */
  @withLogging
  public async getMyTradingOrdersWithPager(
    req: MyOrdersQueryReq,
    offset: number,
    limit: number
  ) {
    const exchange = await this.createMarketActor(this.marketCanisterId, false);

    const query = this.parseMyOrdersQueryReqToGetMyOrdersQuery(req),
      pager = { offset: BigInt(offset), limit: BigInt(limit) };

    const res = await exchange.get_my_orders(query, pager);

    if ('Ok' in res) return res.Ok.map(this.parseUserOrderDtoToOrderDetails);

    throw new CanisterError(res.Err);
  }

  /**
   * get my trading orders count
   */
  @withLogging
  public async getMyTradingOrdersCount(
    userPrincipal: string | Principal,
    filterType: OrderFilterType
  ) {
    const exchange = await this.createMarketActor(this.marketCanisterId, false);

    const filters: NameOrQuota[] = [];

    if ((filterType & OrderFilterType.Name) === OrderFilterType.Name)
      filters.push({ Name: null });
    if ((filterType & OrderFilterType.Quota) === OrderFilterType.Quota)
      filters.push({ Quota: null });

    const res = await exchange.get_user_on_sale_orders_count(
      Principal.fromText(userPrincipal + ''),
      filters
    );
    if ('Ok' in res) return res.Ok;

    throw new CanisterError(res.Err);
  }

  /**
   * sell your name in market
   * @param name name to sale
   * @param price price to sale
   * @param orderTargetUser just set undefined
   * @returns
   */
  @withLogging
  public async createNameOrder(
    name: string,
    price: number,
    orderTargetUser?: string
  ) {
    const exchange = await this.createMarketActor(this.marketCanisterId, false);

    if (orderTargetUser && !principalValidate(orderTargetUser)) {
      throw new Error(`Invalid orderTargetUser format: ${orderTargetUser}`);
    }
    const targetUser = orderTargetUser
      ? {
          TRUE: Principal.fromText(orderTargetUser)
        }
      : { FALSE: null };
    const res = await exchange.create_name_order({
      name,
      price: toBigInt(parseToOrigin(price, this.dicpDecimals)),
      order_target_user: targetUser
    });

    if ('Ok' in res) {
      this.clearNameDetailCache(name);
      return res.Ok.order_id;
    } else throw new CanisterError(res.Err);
  }

  /**
   * sell your quota in market
   * @param type quota type
   * @param count quota count
   * @param price price to sale
   * @param orderTargetUser just set undefined
   * @returns
   */
  public async createQuotaOrder(
    type: QuotaType,
    count: number,
    price: number,
    orderTargetUser?: string
  ) {
    const exchange = await this.createMarketActor(this.marketCanisterId, false);
    if (orderTargetUser && !principalValidate(orderTargetUser)) {
      throw new Error(`Invalid orderTargetUser format: ${orderTargetUser}`);
    }
    const targetUser = orderTargetUser
      ? {
          TRUE: Principal.fromText(orderTargetUser)
        }
      : { FALSE: null };
    const res = await exchange.create_quota_order({
      diff: count,
      quota_type: type,
      price: toBigInt(parseToOrigin(price, this.dicpDecimals)),
      order_target_user: targetUser
    });
    if ('Ok' in res) {
      this.clearQuotaCache(type);
      return res.Ok.order_id;
    } else throw new CanisterError(res.Err);
  }
  /**
   * cancel order
   * @param orderId order id to cancel
   * @returns
   */
  @withLogging
  public async cancelOrder(orderId: bigint) {
    const exchange = await this.createMarketActor(this.marketCanisterId, false);
    const res = await exchange.cancel_order(orderId);

    if ('Ok' in res) {
      await this.clearOrderCache(orderId);
      return res.Ok.order_id.id === orderId;
    }

    throw new CanisterError(res.Err);
  }

  /**
   * buy name
   * approve your dicp before buy
   *
   * @param orderId order id  you want to buy
   */
  public async buyName(orderId: bigint) {
    const exchange = await this.createMarketActor(this.marketCanisterId, false);
    const res = await exchange.buy_name(orderId);

    if ('Ok' in res) {
      this.clearOrderCache(orderId);
      return res.Ok;
    }

    throw new CanisterError(res.Err);
  }

  private clearOrderCache = async (orderId: bigint) => {
    // clear cache
    const order = await this.getOrderDetailsById(orderId)
    if ('Name' in order.orderType) {
      const name = order.orderType.Name;
      await this.clearNameDetailCache(name);
    } else if ('Quota' in order.orderType) {
      const quota = order.orderType.Quota;
      await this.clearQuotaCache(quota.quota_type);
    }
  };

  private clearNameDetailCache = async (name: string) => {
    const cacheKey1 = `${REGISTRAR_DETAIL_CACHE_KEY}_${name}`;
    const cacheKey2 = `${REGISTRY_DETAIL_CACHE_KEY}_${name}`;
    const cacheKey3 = `${RESOLVER_GET_NAME_STATUS_WRAPPER}_${name}`;
    await deleteCache(cacheKey1);
    await deleteCache(cacheKey2);
    await deleteCache(cacheKey3);
  };

  private clearQuotaCache = async (quotaType: QuotaType) => {
    const cacheKey = `${REGISTRAR_QUOTA_CACHE_KEY}_${
      'LenGte' in quotaType ? quotaType.LenGte : quotaType.LenEq
    }`;
    await deleteCache(cacheKey);
  };

  /**
   * buy quota
   * approve your dicp before buy
   *
   * @param orderId order id  you want to buy
   * @param count quota count you want to buy
   */
  public async buyQuota(orderId: bigint, count: number) {
    const exchange = await this.createMarketActor(this.marketCanisterId, false);
    const res = await exchange.buy_quota(orderId, count);

    if ('Ok' in res) {
      this.clearOrderCache(orderId);
      return res.Ok;
    }

    throw new CanisterError(res.Err);
  }

  private parseUserOrderDtoToOrderDetails = ({
    user,
    state,
    order_type,
    order_id,
    price
  }: UserOrderDto): OrderDetails => ({
    user: user.toText(),
    status: this.parseOrderStateToOrderStatus(state),
    orderType: order_type,
    orderId: order_id,
    price
  });

  private parseMarketOrdersQueryReqToGetMarketOrdersQuery(
    req: MarketOrdersQueryReq
  ): GetMarketOrdersQuery {
    let conditionOrderTypeName: ConditionOrderTypeName = { All: null };

    if (req.includeName && (req.nameFilter || req.lengthFilter)) {
      conditionOrderTypeName = {
        Filter: {
          name: req.nameFilter ? [req.nameFilter] : [],
          length: req.lengthFilter ? [req.lengthFilter] : []
        }
      };
    }

    if (!req.includeName) conditionOrderTypeName = { None: null };

    let conditionOrderTypeQuota: ConditionOrderTypeQuota = { All: null };

    if (req.includeQuota && req.lengthFilter) {
      conditionOrderTypeQuota = {
        Filter: {
          length: req.lengthFilter ? [req.lengthFilter] : []
        }
      };
    }

    if (!req.includeQuota || req.nameFilter)
      conditionOrderTypeQuota = { None: null };

    const convertReq: GetMarketOrdersQuery = {
      only_my_sale: req.onlyMySale,
      price_range: [
        {
          max: req.price_max
            ? [toBigInt(parseToOrigin(req.price_max, this.dicpDecimals))]
            : [],
          min: req.price_min
            ? [toBigInt(parseToOrigin(req.price_min, this.dicpDecimals))]
            : []
        }
      ],
      order_type_name: conditionOrderTypeName,
      order_type_quota: conditionOrderTypeQuota
    };
    console.debug('convertReq', convertReq);
    return convertReq;
  }

  private parseMyOrdersQueryReqToGetMyOrdersQuery(
    req: MyOrdersQueryReq
  ): GetMyOrdersQuery {
    let conditionOrderTypeName: ConditionOrderTypeName = { All: null };

    if (req.includeName && (req.nameFilter || req.lengthFilter)) {
      conditionOrderTypeName = {
        Filter: {
          name: req.nameFilter ? [] : [req.nameFilter],
          length: req.lengthFilter ? [] : [req.lengthFilter]
        }
      };
    }

    if (!req.includeName) conditionOrderTypeName = { None: null };

    let conditionOrderTypeQuota: ConditionOrderTypeQuota = { All: null };

    if (req.includeQuota && req.lengthFilter) {
      conditionOrderTypeQuota = {
        Filter: {
          length: req.lengthFilter ? [] : [req.lengthFilter]
        }
      };
    }

    if (!req.includeQuota) conditionOrderTypeQuota = { None: null };

    return {
      only_my_sale: req.onlyMySale,
      order_state: req.orderStatus.map(this.parseOrderStatusToOrderState),
      order_type_name: conditionOrderTypeName,
      order_type_quota: conditionOrderTypeQuota
    };
  }

  private parseOrderStateToOrderStatus(state: OrderState): OrderStatus {
    if ('InTrading' in state) return OrderStatus.InTrading;
    if ('OnSale' in state) return OrderStatus.OnSale;
    if ('Sending' in state) return OrderStatus.Sending;
    if ('Cancelled' in state) return OrderStatus.Cancelled;
    if ('Created' in state) return OrderStatus.Created;
    if ('InCancelling' in state) return OrderStatus.InCancelling;
    if ('Completed' in state) return OrderStatus.Completed;

    throw new Error('Unknown order state');
  }

  private parseOrderStatusToOrderState(status: OrderStatus): OrderState {
    if (status === OrderStatus.InTrading) return { InTrading: null };
    if (status === OrderStatus.OnSale) return { OnSale: null };
    if (status === OrderStatus.Sending) return { Sending: null };
    if (status === OrderStatus.Cancelled) return { Cancelled: null };
    if (status === OrderStatus.Created) return { Created: null };
    if (status === OrderStatus.InCancelling) return { InCancelling: null };
    if (status === OrderStatus.Completed) return { Completed: null };

    throw new Error('Unknown order status');
  }

  private createMarketActor(marketCanisterId: string, anonymous = true) {
    return this.createActor<MarketActor>(
      marketCanisterId,
      exchangeIDL,
      anonymous
    );
  }
}
