import { IReactionDisposer, observable, computed, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { ChangeEvent, PureComponent } from 'react';
import {
  RouteComponentProps,
  StaticContext,
  withRouter
} from 'react-router-class-tools';
import { debounce } from 'lodash';

import { MainLayout } from '../../component/MainLayout';
import { PillNav } from '../../component/PillNav';
import { DomainCard } from '../../component/Domain/Card';
import { DomainSearch } from '../../component/DomainSearch';
import { DomainList } from '../../component/DomainList';

import { REGISTRABLE_NAME_MIN_LENGTH } from '../../model/utils/config';
import { principalValidate } from '../../model/utils/helper';
import domainStore from '../../model/Domain';
import { DomainOwnerships, DomainOwnership } from '../../model/Resolver';
import { LoadingFullCon } from '../../component/LoadingFullPage';

@observer
class SearchPage extends PureComponent<
RouteComponentProps<{ keyword?: string }>
> {
  @observable
  keyword = '';

  @computed
  get nameValid() {
    return this.keyword?.split('.')[0].length >= REGISTRABLE_NAME_MIN_LENGTH;
  }

  @computed
  get topLevel() {
    return this.keyword?.split('.')[1];
  }

  @computed
  get isId() {
    return principalValidate(this.keyword);
  }

  @observable
  domainOwnership?: DomainOwnership;

  private disposers: IReactionDisposer[] = [];

  componentDidMount() {
    this.keyword = this.props.match.params.keyword;

    const { keyword, nameValid, topLevel } = this;

    if (!this.disposers[0])
      this.disposers.push(
        reaction(
          () => this.isId,
          () => domainStore.clear()
        )
      );
    if (!nameValid || (topLevel && topLevel !== 'ic')) return;

    if (this.isId) {
      domainStore.getList(keyword, 1, 500);
    } else {
      domainStore.search(keyword.split('.')[0]);
    }
  }

  componentWillUnmount() {
    for (const disposer of this.disposers) disposer();

    domainStore.clear();
  }

  componentDidUpdate({
    match: { params }
  }: Readonly<RouteComponentProps<{ keyword: string }, StaticContext, {}>>) {
    if (this.props.match.params.keyword !== params.keyword)
      this.componentDidMount();
  }

  changeRoute = debounce((currentTarget: HTMLInputElement) => {
    const { value, validity } = currentTarget;

    currentTarget.setCustomValidity(
      validity.patternMismatch ? 'Name must be alphanumeric or -' : ''
    );

    if (currentTarget.reportValidity()) location.hash = `/search/${value}`;
  }, 500);

  changeKeyword = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    const { value } = currentTarget;
    this.keyword = value.toLowerCase();
    this.changeRoute(currentTarget);
  };

  render() {
    const { keyword, nameValid, topLevel, isId, domainOwnership } = this,
      { downloading, list } = domainStore;

    return (
      <MainLayout loading={!!downloading}>
        <DomainSearch value={keyword} onChange={this.changeKeyword} />

        <h1 className="h5 text-center text-primary my-5">
          Search results for "{keyword}"
        </h1>

      
        <div className="position-relative" style={{minHeight:'5rem'}}>
          <div className='w-100 position-absolute' style={{zIndex:'-1'}}>
            {keyword.length > 2 && <LoadingFullCon />}
          </div>
          
          <div className="position-relative">
            {topLevel && topLevel !== 'ic' ? (
              <div className="round-card text-center text-muted">
                .{topLevel} DNSSEC support coming soon!
              </div>
            ) : isId ? (
              <>
                <PillNav
                  className="text-capitalize"
                  list={DomainOwnerships as unknown as string[]}
                  value={domainOwnership}
                  onSelect={value =>
                    (this.domainOwnership = value as DomainOwnership)
                  }
                />
                <DomainList owner={keyword} type={domainOwnership} list={list} loading={!!downloading} />
              </>
            ) : (
              nameValid && (
                <ul className="list-unstyled">
                  {list.map(item => (
                    <DomainCard key={item.name} {...item} />
                  ))}
                </ul>
              )
            )}
          </div>
        </div>
      </MainLayout>
    );
  }
}

export default withRouter(SearchPage);
