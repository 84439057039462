import { observable, computed } from 'mobx';
import { observer } from 'mobx-react';
import { PureComponent } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { SpinnerButton } from 'idea-react';
import { t } from 'i18next';

import { Dialog } from './Dialog';
import { toastStore } from './Toast';
import { AssetBadge } from './AssetBadge';

import {
    formatAddress,
    parseToCommon
} from '../model/utils/helper';
import { DICP } from '../model/canister/DICP';
import sessionStore from '../model/Session';
import marketStore from '../model/Market';
import assetStore from '../model/Asset';
import domainStore from '../model/Domain';

export type DialogBuyQuotaToRegisterProps = {
    show: boolean;
    onHide: () => void;
    name: string;
    price: bigint;
    quota: number;
    user: string;
    order: string;
    cheap: number;
    constPrice?: number;
}

@observer
export class DialogBuyQuotaToRegister extends PureComponent<DialogBuyQuotaToRegisterProps> {
    @observable
    confirming = false;

    @computed
    get isOwner() {
        const { user } = this.props;
        return user === sessionStore.walletAuth?.principal;
    }

    get fullName() {
        const { quota } = this.props;
        return `${quota}-chars Quota`;
    }

    @computed
    get lowDICP() {
        const { price } = this.props;
        const { DICP: balance } = assetStore.balance;

        return (
            !balance ||
            balance.toNumber() < parseToCommon(price, DICP.tokenInfo?.decimals).toNumber()
        );
    }

    confirm = () => {
        this.confirming = true;
        this.isOwner ? this.register() : this.buyQuota();
    };

    buyQuota = async () => {
        const { order, onHide } = this.props;
        const buyQuotaRes = await marketStore.buy(order, 1);
        if (buyQuotaRes) this.register();
        else {
            toastStore.error('Fail');
            onHide();
        }
    };

    register = async () => {
        const { order, name, quota, onHide } = this.props;
        try {
            if (this.isOwner) await marketStore.cancel(order);
            await domainStore.register(
                { name, years: 1, quotaType: { LenGte: quota } }
            )
            this.confirming = false;
            onHide();
            location.hash = `/account/names`;
        } catch (error) {
            toastStore.error(error)
            onHide();
        }

    }


    renderQuotaMeta() {
        const { price, user } = this.props, { fullName, isOwner } = this;
        return (
            <>
                <Row as="li" className="my-3">
                    <Col className="text-muted">{t("base.Item")}</Col>
                    <Col className="text-end fw-500"><strong>{fullName}</strong></Col>
                </Row>
                <Row as="li" className="my-3">
                    <Col className="text-muted">{t("market.OwnedBy")}</Col>
                    <Col className="text-end fw-500">{isOwner ? t("cheap.you") : ' ' + formatAddress(user)}</Col>
                </Row>

                <Row as="li" className="my-3">
                    <Col className="text-muted">{t("base.Price")}</Col>
                    <Col className="text-end">
                        <AssetBadge type="coin" name="DICP" />
                        <span className="fw-500">
                            {this.isOwner ? 0 : parseToCommon(price, DICP.tokenInfo?.decimals) + ''}
                        </span>
                    </Col>
                </Row>
            </>
        );
    }

    render() {
        const { show, name, cheap, constPrice, onHide } = this.props;
        const { confirming, isOwner } = this;
        const {
            balance: { DICP = '--' }
        } = assetStore;
        return (
            <Dialog title={isOwner ? t("cheap.RegTitle") : t("cheap.BuyQuotaToRegTitle")}
                backdrop={confirming ? 'static' : true}
                closeButton={false}
                show={show}
                centered={true}
                onHide={() => onHide()}
            >
                <div className="pt-4">
                    <Form onSubmit={event => {
                        event.preventDefault();
                        this.confirm();
                    }}>
                        <h1 className="h3 text-break">{name}</h1>
                        {constPrice && <p className="text-muted">{t("cheap.savings", { cheap: isOwner ? constPrice : cheap })}</p>}
                        <ul className="list-unstyled my-4">
                            {this.renderQuotaMeta()}
                        </ul>
                        <Row>
                            <Col xs={{ offset: 2, span: 8 }} className="text-muted my-2 text-center">
                                <div className="d-flex">
                                    <Button className="w-50"
                                        variant="outline-secondary"
                                        disabled={confirming}
                                        onClick={() => onHide()}>
                                        {t("base.Cancel")}
                                    </Button>
                                    <SpinnerButton
                                        className="w-50 ms-3"
                                        variant={
                                            isOwner
                                                ? 'primary'
                                                : this.lowDICP
                                                    ? 'outline-primary'
                                                    : 'primary'
                                        }
                                        {...(isOwner
                                            ? { type: 'submit' }
                                            : this.lowDICP
                                                ? { href: '#/account/assets/wrap/ICP' }
                                                : { type: 'submit' })}
                                        animation="border"
                                        loading={confirming}
                                    >
                                        {isOwner
                                            ? t("base.Submit")
                                            : this.lowDICP
                                                ? t("base.wrap")
                                                : t("base.Submit")}
                                    </SpinnerButton>
                                </div>

                                <div className="mt-3">
                                    DICP <span className="text-muted">{t("base.balance")}</span>
                                    <strong className="ms-2">{DICP + ''}</strong>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Dialog>
        );
    }
}
