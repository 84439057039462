import { Hour, Second } from 'web-utility';
import { deleteCache } from '@deland-labs/local-cache';
import {
  idlFactory as favoritesIDL,
  _SERVICE as FavoritesActor
} from '@icnaming/favorites_client';

import { AuthBase } from '../AuthBase';
import { withCache, withLogging } from '../utils/errorLogger';
import { CanisterError } from '../utils/exception';

const USER_FAVORITES_CACHE_KEY = 'user_favorites';
/**
 * User name favorites
 */
export class Favorites extends AuthBase {
  private favoritesCanisterId: string;

  constructor(favoritesCanisterId: string) {
    super();
    this.favoritesCanisterId = favoritesCanisterId;
  }

  /**
   * get favorites names
   */
  @withLogging
  @withCache<Favorites>(
    instance =>
      `${USER_FAVORITES_CACHE_KEY}_${instance.session.walletAuth.principal}`,
    Hour / Second
  )
  public async getFavorites() {
    const favorites = await this.createFavoritesActor(
      this.favoritesCanisterId,
      false
    );
    const res = await favorites.get_favorites();

    if ('Ok' in res) return res.Ok;

    throw new CanisterError(res.Err);
  }

  /**
   * add name to favorites
   *
   * @param name  name to add to favorites
   */
  @withLogging
  public async addFavorite(name: string) {
    const favorites = await this.createFavoritesActor(
      this.favoritesCanisterId,
      false
    );
    const res = await favorites.add_favorite(name);

    if ('Ok' in res) {
      const cacheKey = `${USER_FAVORITES_CACHE_KEY}_${this.session.walletAuth.principal}`;
      deleteCache(cacheKey);
      return res.Ok;
    }

    throw new CanisterError(res.Err);
  }

  /**
   * remove name from favorites
   */
  @withLogging
  public async removeFavorite(name: string) {
    const favorites = await this.createFavoritesActor(
      this.favoritesCanisterId,
      false
    );
    const res = await favorites.remove_favorite(name);

    if ('Ok' in res) {
      deleteCache(
        `${USER_FAVORITES_CACHE_KEY}_${this.session.walletAuth.principal}`
      );
      return res.Ok;
    }

    throw new CanisterError(res.Err);
  }

  private createFavoritesActor(favoritesCanisterId: string, anonymous = true) {
    return this.createActor<FavoritesActor>(
      favoritesCanisterId,
      favoritesIDL,
      anonymous
    );
  }
}
