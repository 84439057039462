{
    "appName":"icNaming",
    "base":{
        "names":"名稱",
        "favorites":"收藏",
        "assets":"資產",
        "asset":"資產",
        "Logout":"退出",
        "Name":"名稱",
        "registrant":"註冊人",
        "controller":"控制人",
        "Register":"註冊",
        "Details":"詳細",
        "Expiration":"到期時間",
        "Resolver":"解析器",
        "Quota":"額度",
        "Buy":"購買",
        "Sell":"出售",
        "Price":"價格",
        "Amount":"數量",
        "Item":"專案",
        "Cost":"費用",
        "balance":"餘額",
        "wrap":"兌換ICP",
        "unwrap":"兌換DICP",
        "Cancel":"取消",
        "Confirm":"確認",
        "Submit":"提交",
        "connect": "連接錢包",
        "BuyName":"購買名稱",
        "BuyQuota":"購買額度",
        "SellName":"出售名稱",
        "SellQuota":"出售額度",
        "Setting":"設置",
        "Set":"設置",
        "transfer":"轉讓",
        "chars":"位長度",
        "type":"類型",
        "Fixed":"固定",
        "Renew":"續費",
        "EnterAmount":"輸入數量",
        "EnterPrice":"輸入價格",
        "Save":"保存",
        "Delete":"刪除",
        "NotSet":"未設置",
        "fee":"費用",
        "Tips":"提示",
        "Address":"位址",
        "to":"到",
        "from":"從"
    },
    "header": {
        "docs":"文件",
        "blindBox":"盲盒",
        "market":"交易市場"
    },
    "sessionBox":{
        "Select":"選擇一個錢包",
        "title":"請選擇一個錢包來連接到這個dapp：",
        "TryAgain":"再試試",
        "usedIIBefore":"我以前用過II？"
    },
    "home": {
        "slogan" : "去中心化的數位身份"
    },
    "domainSearch":{
        "search":"搜索",
        "placeholder":"搜索名稱或位址"
    },
    "domain" : {
        "card" : {
            "Buy":"購買",
            "View":"詳情",
            "Sell":"出售",
            "Claim":"申請",
            "HowToClaim":"如何申請",
            "registered":"這個名稱已經被註冊了。"
        },
        "CustomBadge":{
            "Available": "可註冊",
            "Unavailable": "不能註冊",
            "Reserved": "保留",
            "OnSale": "使用者出售",
            "ForSaleByOwner":"擁有者出售"
        },
        "sell":{
            "Confirmation":"確認",
            "ThinkAgain":"在想想",
            "ConfirmCancel":"確認取消",
            "AreYouSure":"你確定要取消以下物品的銷售嗎？"
        }
    },
    "registerForm":{
        "RegistrationPeriod":"註冊期限",
        "years":"年",
        "year":"年",
        "RegistrationPrice":"註冊價格",
        "Best":"推薦",
        "TotalPrice":"總價",
        "What":"什麼是",
        "question":"3-5個字元的名稱只能通過額度註冊",
        "getQuota":"獲取額度",
        "RenewPeriod":"續費期限",
        "questionTitle1":"什麼是額度？",
        "questionTitle2":"如何獲得額度？",
        "questionTitle3":"盲盒",
        "questionDesc1":"註冊3-5個字元的名稱時需要使用額度。 不同字元的名稱可以使用額度行註冊，而且有不同類型的額度。 例如，4位字元的額度不能註冊3位字元的名稱。",
        "questionDesc2":"購買盲盒將包含一個額度和一些DNS代幣，獲得不同的字元額度的概率是不同的。 具體概率圖顯示在盲盒購買介面。 你可以點擊下面的連結來購買<0>盲盒</0>。配額也可以從<1>市場</1>購買。市場上的配額是由其他用戶出售的。",
        "questionDesc3":"盲盒是一種更好的分配名稱的方式。 以下連結是盲盒的購買頁面和詳細介紹。",
        "whatBlindBox":"什麼是盲盒?",
        "whyBlindBox":"為什麼是盲盒？"
    },
    "market":{
        "Filter":"篩選",
        "Items":"選項",
        "All":"全部",
        "Length":"長度",
        "SeeMySale":"我出售的",
        "Down":"關閉",
        "placeholder":"搜索名稱",
        "OwnedBy":"擁有者",
        "PurchaseConfirmation":"購買確認",
        "PurchaseTips":"你確定要購買以下物品嗎？"
    },
    "account":{
        "Receive":"接收",
        "nnsTips":"如果你以前通過連結II錢包（NNS）購買過名稱，<0>點擊我，完成遷移</0>",
        "Expires":"到期",
        "ViewSelling":"查看銷售",
        "MySale" : "正在出售",
        "placeholder":"搜索名稱",
        "names":"名稱",
        "Frozen":"凍結",
        "Available":"可用",
        "Action":"操作",
        "feeTips":"當使用者轉移ICP時，DICP轉移費用（0.0001 ICP）由分類賬收取。 ICNaming在兌換DICP時不收取任何費用。",
        "SelectAssets":"選擇資產",
        "DestinationAddress":"目標位址",
        "InsufficientBalance":"餘額不足",
        "EnterAddressOrName":"輸入位址或名稱",
        "EnterAmount":"輸入數量",
        "quotaDialogTitle":"什麼是額度？",
        "quotaDialogDesc":"註冊3-5個字元的名稱時需要使用配額。 不同字元的名稱可以使用配額進行註冊，而且有不同類型的配額。 例如，3位數的配額可以用來註冊大於或等於3位數的名稱，但4位數的配額不能註冊3位數的名稱。"
    },
    "nameSet":{
        "menu":{
            "Profile":"簡介",
            "SocialMedia":"社交媒體",
            "Crypto":"加密貨幣",
            "Canister":"罐",
            "Link":"連結",
            "TextRecord":"文本記錄",
            "Transfer":"轉移",
            "Renew":"續費",
            "Resolver":"解析器",
            "ReverseRecord":"反向解析"
        },
        "tryItNow":"試一試",
        "EditProfile":"編輯個人簡介",
        "SetAvatar":"設置一個NFT/Gravatar作為我的頭像",
        "DisplayName":"顯示名稱",
        "Email":"郵箱",
        "Location":"位置",
        "Description":"簡介",
        "reclaim":"回收",
        "noOwnController":"該名稱的控制人已被轉讓。 要設置，請先收回",
        "SetYourReverseRecord":"設置你的反向記錄",
        "reverseRecordTips":"你想讓其他人通過你的主身份獲得你的名稱嗎？ 在設置了你的主名稱后，其他人可以通過主要ID來查詢你的名稱。",
        "yourDecentralized":"訪問你的去中心化網站",
        "canisterTips1":"如果你有一個部署在IC上的去中心化網站，在這裡設置網站的Canister ID，然後你就可以通過url來訪問它 ",
        "canisterTips2":"是一個部署在IC上的去中心化網站的例子。",
        "hasUrlNotice1Title":"確認設置",
        "hasUrlNotice":"你已經設置了URL，如果你設置了canister id，訪問<bikeUrl>{{bikeUrl}}</bikeUrl>將訪問你在IC上部署的去中心化網站，而不是你設置的<url>{{url}}</url>",
        "yourWebsite":"通過網址連結到你的web2/web3網站：",
        "urlTips1":"你現在有網站嗎？ 如果有，在這裡設置網站的網址，然後你就可以通過網址來訪問它。",
        "urlNotice":"(注意：你已經設置了容器的ID，它將訪問容器的網站而不是你的網址。)",
        "hasBeenTransferred":"該名稱的註冊人已被轉讓。"
    },
    "dangerConfirm":{
        "tips":"你確定要轉移到以下位址嗎？",
        "transferRegistrant":{
            "title":"轉讓註冊人",
            "note":"注意：轉讓註冊人意味著你不再是該名稱的擁有者，一旦完成轉讓就不能取消。"
        },
        "transferController":{
            "title":"轉讓控制人",
            "note":"注意：轉讓控制人只意味著轉讓控制權，你仍然是名稱的擁有者，可以在任何時候通過[回收]拿回控制權"
        },
        "reclaim":{
            "title":"回收控制權"
        },
        "transferToken":{
            "title":"轉移代幣",
            "note":"注意：一旦轉讓完成，就不能取消。"
        }
    },
    "migration":{
        "desc":"ICNaming取消了對II的支援。 如果你以前用II登錄併購買了名稱，你可以在這個頁面完成遷移。",
        "MigrationSteps":"遷移步驟",
        "step1":"連接到II和您想遷移到的目標錢包",
        "step2":"選擇要遷移的專案",
        "step3":"完成遷移步驟",
        "Connect":"連接錢包",
        "ConnectII":"連接II錢包",
        "ConnectTargetWallet":"連接目標錢包",
        "connectTips":"請先連接II錢包和目標錢包",
        "SelectItems":"選擇要遷移的專案",
        "Migrate":"遷移"
    },
    "blindBox":{
        "ConnectWallet":"連接錢包",
        "YourBalance":"您的餘額",
        "RewardTips":"購買盲盒有機會獲得以下物品",
        "Reward":"獎勵",
        "Chance":"概率",
        "Remark":"備註",
        "Reasons":"原因",
        "ReasonsTips":"引入一種新的方式來探索名稱服務的可能性，這也貫徹了我們的核心信念：公平。 不僅如此，盲盒的工作機制是不確定的，當用戶參與進來時，會產生很多樂趣。 此外，我們最初的概率設計為用戶創造了更高的機會，讓他們以最低的消費獲得更多的配額和稀有的額度，在市場上價格是合理的。"
    },
    "errorInfo":{
        "inputError":"你的輸入有誤",
        "notFoundAddress":"沒有找到這個加密位址"
    },
    "toast":{
        "SaleSuccessfully":"出售成功",
        "CancelledSuccessfully":"取消成功",
        "exceedPendingOrders":"不能超過待處理訂單的數量",
        "WrapSuccessful":"兌換成功",
        "UnWrapSuccessful":"兌換成功",
        "SaveSuccessful":"保存成功！",
        "SetSuccessfully":"設置成功",
        "MigrateSuccess":"遷移成功"
    },
    "cheap":{
        "FindCheap":"在市場上發現一個便宜的額度，<0>可節約 {{cheapPrice}} DICP 進行註冊</0>",
        "FindOne":"在市場上發現一個{{quota}}-chars的額度，<0>{{cheapPrice}} DICP 購買並註冊</0>",
        "BuyQuotaToRegTitle":"購買額度並註冊",
        "RegTitle":"註冊",
        "you":"是你自己",
        "savings":"節約 {{cheap}} DICP"
    }
}