import { auto } from 'browser-unhandled-rejection';
import { serviceWorkerUpdate } from 'web-utility';
import { render } from 'react-dom';

import { PageFrame } from './page';
import { ToastRoot, toastStore } from './component/Toast';

auto();

self.addEventListener('unhandledrejection', ({ reason }) => {
  const { message } = reason as Error;

  if (message) toastStore.error(message);
});

const { serviceWorker } = window.navigator,
  { NODE_ENV = 'development' } = process.env;

if (NODE_ENV !== 'development')
  serviceWorker
    ?.register('sw.js')
    .then(serviceWorkerUpdate)
    .then(worker => {
      if (window.confirm('New version of this Web App detected, update now?'))
        worker.postMessage({ type: 'SKIP_WAITING' });
    });

serviceWorker?.addEventListener('controllerchange', () =>
  window.location.reload()
);

render(
  <>
    <PageFrame />
    <ToastRoot />
  </>,
  document.querySelector('#app')
);
