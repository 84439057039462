import { Buffer } from 'buffer';
import { findCurrency, validate } from 'multicoin-address-validator';
import { Principal } from '@dfinity/principal';

import { calculateCrc32 } from '../converter';

export function principalValidate(pid = '') {
  if (!pid) return false;
  try {
    Principal.fromText(pid);
    return true;
  } catch {
    return false;
  }
}

export const validICPAccountId = (accountId: string) => {
  const bytes = Buffer.from(accountId, 'hex');
  const crcSum = bytes.slice(0, 4);
  const hash = bytes.slice(4, bytes.length);
  const checksum = calculateCrc32(hash);
  return crcSum.equals(checksum);
};

export const validCanisterId = (address: string) =>
  principalValidate(address) && address.length < 28;

export const isValidAddress = (address: string, coin: string) => {
  if (address === '') return true;
  if (findCurrency(coin)) return validate(address, coin);

  switch (coin.toUpperCase()) {
    case 'ICP':
      return principalValidate(address);
    default:
      return false;
  }
};

export const isEmail = (email?: string) =>
  /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(email || '');


export const isUrl = (url?: string) =>
  /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/.test(url || '')