import { observer } from 'mobx-react';
import { PureComponent } from 'react';
import { Navigate } from 'react-router-dom';
import { Container, Dropdown } from 'react-bootstrap';
import classNames from 'classnames';
import { IconButton } from '../IconButton';
import * as style from './index.module.less';

import { footerData } from '../../page/BlindBox/footer';
import { Language, languages } from '../../model/Language';
import session from '../../model/Session';

import { AppHeader } from '../AppHeader';

export interface MainLayoutProps {
  needSession?: boolean;
  loading?: boolean;
}

@observer
export class MainLayout extends PureComponent<MainLayoutProps> {
  render() {
    const { needSession, children } = this.props,
      { walletAuth, currentLanguage } = session;

    if (needSession && !walletAuth) return <Navigate to="/" />;

    return (
      <>

        <AppHeader theme="light" />

        <Container className={`mt-5 ${style.main}`}>{children}</Container>

        <footer
          className={classNames(
            'd-flex justify-content-center align-items-center my-5',
            style['footer']
          )}
        >
          {footerData.map(({ title, link, target }) => (
            <IconButton
              className={classNames(style['icon-button'])}
              key={link}
              round
              name={title}
              href={link}
              target={target}
              rel={target === '_blank' ? 'noreferrer' : undefined}
            />
          ))}
        </footer>
        <Dropdown className="d-flex justify-content-center align-items-center mb-5">
          <Dropdown.Toggle className="shadow-none bg-transparent border-0 text-dark" id="language" size="sm">
            {languages[currentLanguage]}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {
              Object.keys(languages).map((item: Language) =>
                <Dropdown.Item key={item} onClick={() => session.switchLanguage(item)}>{languages[item]}</Dropdown.Item>
              )
            }
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }
}
